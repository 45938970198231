<template>
  <div class="cra-file-upload">
    <v-file-input
      v-if="isEditable"
      v-model="inputFiles"
      multiple
      clearable
      placeholder="Select your files"
      :show-size="1000"
      accept=".jpg, .jpeg, .png, .pdf, .ppt, .excel, .doc, .xls, .xlsx"
      :label="label"
      prepend-icon="mdi-upload"
      @change="changeInput"
    >
    </v-file-input>
    <div class="mt-4">
      <div v-for="file in selectedFiles" :key="file.id" class="cra-chip-flat mb-3">
        <span class="cra-chip-flat-link p-tiny mr-1" @click="openFile(file)">{{ file.name }}</span>
        <v-icon v-if="isEditable" class="cra-chip-flat-icon" dense @click="openApprovalDialog(file)"
          >mdi-close-circle</v-icon
        >
      </div>
    </div>
    <v-dialog v-model="isApprovalDialogOn" max-width="700">
      <v-card class="py-2">
        <v-card-title>
          <h5>
            <v-avatar size="30" class="mr-2 cra-avatar">
              <v-icon class="cra-btn-close" small>mdi-exclamation-thick</v-icon></v-avatar
            >Delete document
          </h5>
          <v-spacer></v-spacer>
          <v-icon class="cra-btn-close" large @click="closeAndCancel">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-container fluid class="px-0">
            <v-row>
              <v-col cols="12">
                <p>Please confirm, that you want to delete the follwing document:</p>
                <p>{{ fileToDelete.name }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <base-button
            mode="secondary"
            icon="mdi-chevron-right"
            :icon-color="'primary'"
            @click="closeAndCancel"
            >Cancel</base-button
          >
          <v-spacer></v-spacer>
          <base-button mode="primary" icon="mdi-check-bold" @click="closeAndAccept"
            >Confirm</base-button
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import BaseButton from './BaseButton';

export default {
  name: 'BaseFileInput',
  components: {
    BaseButton,
  },
  emits: ['add-files', 'remove-files', 'open-file'],
  props: {
    files: {
      type: Array,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      selectedFiles: [],
      inputFiles: [],
      isApprovalDialogOn: false,
      fileToDelete: Object,
    };
  },
  watch: {
    files() {
      this.setFiles();
    },
  },
  mounted: function () {
    this.setFiles();
  },
  methods: {
    setFiles() {
      this.files ? (this.selectedFiles = this.files) : (this.selectedFiles = []);
    },
    openApprovalDialog(file) {
      this.isApprovalDialogOn = true;
      this.fileToDelete = file;
    },
    removeFile() {
      const file = this.fileToDelete;
      const files = [...this.selectedFiles];
      this.selectedFiles = files.filter((f) => f !== file);
      this.inputFiles = files.filter((f) => f !== file);
      this.$emit('remove-files', file);
    },
    closeAndAccept() {
      this.isApprovalDialogOn = false;
      this.removeFile();
    },
    closeAndCancel() {
      this.isApprovalDialogOn = false;
    },
    changeInput() {
      this.selectedFiles = [...this.selectedFiles, ...this.inputFiles];
      this.$emit('add-files', this.inputFiles);
    },
    openFile(file) {
      if (file.id) {
        this.$emit('open-file', file);
      }
    },
  },
};
</script>
