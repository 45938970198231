<template>
  <div>
    <v-container fluid class="px-0">
      <v-row>
        <v-col cols="12" md="8">
          <slot />
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="approveItems.length" fluid class="px-0">
      <v-row v-for="item in approveItems" :key="item.title" align="center">
        <v-col cols="12" md="6">
          <base-button
            mode="secondary"
            :icon="'mdi-close'"
            :class="'cra-btn-secondary-colored'"
            :icon-size="'20px'"
            @click="openDialog()"
            >Deny approval</base-button
          >
        </v-col>
        <v-col cols="12" md="6">
          <v-row align="center" justify="end">
            <v-col cols="6" class="text-end">
              <strong v-if="item.altTitle">{{ item.altTitle }}</strong>
              <strong v-else>{{ item.title }}</strong>
            </v-col>
            <v-col cols="6" class="text-end">
              <base-button
                v-if="hasApproved(item.title)"
                mode="primary"
                class="cra-btn-checked"
                :icon="'mdi-check'"
                >Approved</base-button
              >
              <base-button
                v-else
                mode="primary"
                :icon="'mdi-arrow-right'"
                :icon-size="'20px'"
                @click="approve(item.href)"
                >Approve account</base-button
              ></v-col
            >
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <approval-deny-dialog
      v-if="isDialogOn"
      @cancel="cancelDialog"
      @accept="deny"
    ></approval-deny-dialog>
  </div>
</template>
<script>
import BaseButton from '../../ui/BaseButton';
import PermissionService from '../../../services/PermisssionService';
//import removeSpaces from '../../../helpers/removeSpaces';
import ApprovalDenyDialog from './ApprovalDenyDialog';
import { mapGetters } from 'vuex';
export default {
  name: 'AccountApproval',
  components: { ApprovalDenyDialog, BaseButton },
  data() {
    return {
      isDialogOn: false,
      approveItems: [],
      possibleApproverTitles: {
        admin: 'Admin',
        customerSuccessDirector: 'Customer Success Director',
        customerSuccessLead: 'Customer Success Lead',
        proxy: 'Proxy',
      },
    };
  },
  computed: {
    ...mapGetters({
      getAccountToEdit: 'accounts/getAccountToEdit',
      getAccountToEditSavingState: 'accounts/getAccountToEditSavingState',
      getAccountApprovers: 'accounts/getAccountApprovers',
      getLoggedInUser: 'users/getLoggedInUser',
    }),
  },

  created() {
    this.PermissionService = PermissionService;
    const approvalList = [];
    if (this.getAccountApprovers.length) {
      this.getAccountApprovers.forEach((item) => {
        if (
          item.title === 'As ' + this.possibleApproverTitles.admin &&
          PermissionService.isAdmin()
        ) {
          approvalList.push(item);
        }
        if (
          item.title === 'As ' + this.possibleApproverTitles.customerSuccessDirector &&
          PermissionService.hasUserRole(this.possibleApproverTitles.customerSuccessDirector)
        ) {
          approvalList.push(item);
        }
        if (
          item.title === 'As ' + this.possibleApproverTitles.customerSuccessLead &&
          PermissionService.hasCoreTeamRole(this.possibleApproverTitles.customerSuccessLead)
        ) {
          approvalList.push(item);
        }
        if (item.title === 'As ' + this.possibleApproverTitles.proxy) {
          const altTitle =
            item.title +
            ' for ' +
            this.getLoggedInUser.isProxy[0].firstName +
            ' ' +
            this.getLoggedInUser.isProxy[0].lastName;
          const editItem = { ...item, altTitle };
          approvalList.push(editItem);
        }
      });
    }
    this.approveItems = approvalList;
  },
  methods: {
    hasApproved(title) {
      const titlePossibleApprover = title.replace('As ', '').replaceAll(' ', '');
      return this.getAccountToEdit.accountApprovers
        ? Object.keys(this.getAccountToEdit.accountApprovers).some(
            (key) => key === titlePossibleApprover
          )
        : false;
    },
    async approve(link) {
      this.$store.commit('notifications/setBusy', true);
      await this.$store.dispatch('accounts/approveAccount', link).then(() => this.$nextTick());
      this.$store.commit('notifications/setSnackbar', {
        type: this.getAccountToEditSavingState.type,
        message: this.getAccountToEditSavingState.message,
      });
      this.$store.commit('notifications/setBusy', false);
    },
    async deny() {
      this.$store.commit('notifications/setBusy', true);
      await this.$store.dispatch('accounts/denyAccount').then(() => this.$nextTick());
      this.$store.commit('notifications/setSnackbar', {
        type: this.getAccountToEditSavingState.type,
        message: this.getAccountToEditSavingState.message,
      });
      this.$store.commit('notifications/setBusy', false);
      this.isDialogOn = false;
    },
    openDialog() {
      this.isDialogOn = true;
    },
    cancelDialog() {
      this.isDialogOn = false;
    },
  },
};
</script>
