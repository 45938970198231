import mutations from './mutations';
import getters from './getters';

export const getInitialSnackbarState = function () {
  return {
    type: '',
    message: '',
    timeout: 8000,
    visible: false,
  };
};

export const getInitialNotificationState = function () {
  return {
    snackbar: { ...getInitialSnackbarState() },
    isBusy: false,
  };
};

export default {
  namespaced: true,
  state() {
    return { ...getInitialNotificationState() };
  },
  mutations,
  getters,
};
