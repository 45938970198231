<template>
  <v-app :class="appClasses">
    <notifications />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
import Notifications from './components/notifications/Notifications';

export default {
  components: { Notifications },
  computed: {
    appClasses() {
      if (this.$route.name === 'Home') {
        return ['shs-app', 'cra-home'];
      } else {
        return ['shs-app'];
      }
    },
  },
};
</script>

<style lang="scss">
@import 'scss/main';
</style>
