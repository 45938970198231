export default {
  getComments(state) {
    return state.comments.sort(function (a, b) {
      return new Date(b.created) - new Date(a.created);
    });
  },

  getCommentInCreation(state) {
    return state.commentInCreation;
  },

  getCommentInCreationSavingState(state) {
    return state.commentInCreation.savingState;
  },
};
