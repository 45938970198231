<template>
  <v-container fluid class="pl-6 pr-6 cra-action-item-container">
    <div class="cra-expand-panel-title" @click="expand = !expand">
      <h4 class="cra-expand-panel-headline">
        Open action items ({{ Object.keys(itemList).length }})
      </h4>
      <v-icon v-if="expand" x-large>mdi-chevron-up</v-icon>
      <v-icon v-else x-large>mdi-chevron-down</v-icon>
    </div>

    <v-expand-transition>
      <div v-show="expand">
        <v-row justify="center" class="mt-4 mx-1">
          <v-expansion-panels accordion>
            <action-item-add
              v-if="isCreateAllowed && !isReadOnly"
              :key="Object.keys(itemList).length"
              :account-id="accountId"
              :user-list="userList"
              @save-success="setNewActionItem"
            ></action-item-add>
            <v-container v-if="!isCreateAllowed" fluid class="px-0 pb-6">
              <p>Action items can be created as soon as the account has been approved</p>
            </v-container>
            <action-items-filter
              v-if="Object.keys(itemList).length"
              item-state="Open"
              @change-filter="filterItems()"
            ></action-items-filter>
            <v-container v-if="filteredItems.length" fluid class="px-0 py-0">
              <action-item
                v-for="item in filteredItems"
                :ref="item.id"
                :key="item.id"
                :number="item.number"
                :account-id="accountId"
                :action-item-id="item.id"
                :user-list="userList"
                :state="'open'"
                :read-only="isReadOnly"
                @change-state="updateList"
              ></action-item>
            </v-container>
            <p v-if="!filteredItems.length && Object.keys(itemList).length">
              No items. Change filter.
            </p>
          </v-expansion-panels>
        </v-row>
      </div>
    </v-expand-transition>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import ActionItemAdd from './ActionItemAdd';
import ActionItem from './ActionItem';
import getInitials from '../../../helpers/getInitials';
import { AccountStates } from '../../../store/modules/accounts';
import ActionItemsFilter from './ActionItemsFilter';
export default {
  name: 'ActionItems',
  components: { ActionItemsFilter, ActionItem, ActionItemAdd },
  emits: ['change-item-list'],
  props: {
    accountId: {
      type: String,
      required: true,
    },
    itemList: {
      type: Object,
      required: true,
    },
    isCreateAllowed: {
      type: Boolean,
      required: false,
      default: true,
    },
    accountStateList: {
      type: Array,
      required: false,
      default: Object,
    },
    isReadOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      expand: true,
      AccountStates,
      filteredItems: [],
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      getActiveUsers: 'users/allActiveUsers',
      getActiveFilterCategories: 'actionItems/getActiveFilterCategories',
      getActionItemCategoryTitle: 'actionItems/getActionItemCategoryTitle',
      getActionItemCategorySlug: 'actionItems/getActionItemCategorySlug',
    }),
    userList() {
      let userList = [];
      Object.keys(this.getActiveUsers).forEach((key) => {
        const user = this.getActiveUsers[key];
        const newUser = {
          name: user.firstName + ' ' + user.lastName,
          id: user.id,
          initials: getInitials(user.firstName, user.lastName),
        };
        userList.push(newUser);
      });
      return userList;
    },
  },
  watch: {
    itemList() {
      this.setItems();
    },
  },
  created() {
    this.AccountStates = AccountStates;
    if (this.accountStateList.includes(this.AccountStates.pendingApproval)) {
      this.expand = false;
    }
    this.setItems();
  },
  mounted() {
    if (this.$route.hash) {
      const hashId = this.$route.hash.slice(1, this.$route.hash.length);
      this.$refs[hashId][0].$el.scrollIntoView();
    }
  },
  methods: {
    setItems() {
      this.items = [];
      Object.keys(this.itemList).forEach((key) => {
        const obj = this.itemList[key];
        this.items.push(obj);
      });
      this.filterItems();
    },
    setNewActionItem() {
      this.$emit('change-item-list');
      this.$store.commit('actionItems/addActionItemInCreation');
    },
    setResponsibleUsers() {
      this.userList = [];
      Object.keys(this.getCoreTeamAccountToEdit).forEach((key) => {
        const coreTeamMember = this.getCoreTeamAccountToEdit[key];
        const newUser = {
          name: coreTeamMember.firstName + ' ' + coreTeamMember.lastName,
          id: coreTeamMember.id,
          initials: getInitials(coreTeamMember.firstName, coreTeamMember.lastName),
        };
        this.userList.push(newUser);
      });
    },
    updateList() {
      this.$emit('change-item-list');
    },
    filterItems() {
      this.filteredItems = [];
      if (this.getActiveFilterCategories.length === 0) {
        this.filteredItems = this.items;
        return true;
      }
      this.items.forEach((item) => {
        this.getActiveFilterCategories.forEach((filter) => {
          if (
            item.category &&
            item.category.includes(this.getActionItemCategorySlug(filter)) &&
            !this.filteredItems.includes(item)
          ) {
            this.filteredItems.push(item);
          }
        });
      });
    },
  },
};
</script>
