var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-modal',{on:{"close":_vm.close},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("Edit user")])])],1)]},proxy:true}])},[_c('v-form',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"pl-4 pr-4 pb-3"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"filled":"","label":"First name","hide-details":"","dense":"","disabled":_vm.PermissionService.isDisabled(['isAdmin', 'isCurrentUser']) ||
              !_vm.isActiveUser ||
              !!_vm.PermissionService.hasReadOnlyRestrict()},on:{"change":function (value) { return _vm.updateField({ fieldName: 'firstName', value: value }); }},model:{value:(_vm.fieldsToValidate.firstName.value),callback:function ($$v) {_vm.$set(_vm.fieldsToValidate.firstName, "value", $$v)},expression:"fieldsToValidate.firstName.value"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"filled":"","label":"Last name","hide-details":"","dense":"","disabled":_vm.PermissionService.isDisabled(['isAdmin', 'isCurrentUser']) ||
              !_vm.isActiveUser ||
              !!_vm.PermissionService.hasReadOnlyRestrict()},on:{"change":function (value) { return _vm.updateField({ fieldName: 'lastName', value: value }); }},model:{value:(_vm.fieldsToValidate.lastName.value),callback:function ($$v) {_vm.$set(_vm.fieldsToValidate.lastName, "value", $$v)},expression:"fieldsToValidate.lastName.value"}})],1)],1),_c('v-row',{staticClass:"pl-4 pr-4 pb-3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"filled":"","label":"Org code","hide-details":"","dense":"","disabled":_vm.PermissionService.isDisabled(['isAdmin', 'isCurrentUser']) ||
              !_vm.isActiveUser ||
              !!_vm.PermissionService.hasReadOnlyRestrict()},on:{"change":function (value) { return _vm.updateField({ fieldName: 'orgCode', value: value }); }},model:{value:(_vm.fieldsToValidate.orgCode.value),callback:function ($$v) {_vm.$set(_vm.fieldsToValidate.orgCode, "value", $$v)},expression:"fieldsToValidate.orgCode.value"}})],1)],1),_c('v-row',{staticClass:"pl-4 pr-4 pb-3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"filled":"","label":"E-mail","hide-details":"","dense":"","disabled":true},model:{value:(_vm.fieldsToValidate.email.value),callback:function ($$v) {_vm.$set(_vm.fieldsToValidate.email, "value", $$v)},expression:"fieldsToValidate.email.value"}})],1)],1),_c('v-row',{staticClass:"pl-4 pr-4 pb-5"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{staticClass:"cra-select",attrs:{"items":_vm.activeUserRoles,"label":"Roles","multiple":"","filled":"","dense":"","hide-details":"","disabled":_vm.PermissionService.isDisabled(['isAdmin']) ||
              !_vm.isActiveUser ||
              !!_vm.PermissionService.hasReadOnlyRestrict()},on:{"change":function (value) { return _vm.updateField({ fieldName: 'roles', value: value }); }},model:{value:(_vm.fieldsToValidate.roles.value),callback:function ($$v) {_vm.$set(_vm.fieldsToValidate.roles, "value", $$v)},expression:"fieldsToValidate.roles.value"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{staticClass:"cra-select",attrs:{"value":_vm.fieldsToValidate.zones.value,"items":_vm.getZones,"label":"Zones","multiple":"","filled":"","dense":"","hide-details":"","disabled":_vm.PermissionService.isDisabled(['isAdmin']) ||
              !_vm.isActiveUser ||
              !!_vm.PermissionService.hasReadOnlyRestrict()},on:{"change":function (value) { return _vm.updateField({ fieldName: 'zones', value: value }); }}})],1)],1)],1),_c('v-divider'),(_vm.showProxy())?_c('proxy'):_vm._e(),(_vm.showProxy())?_c('v-divider'):_vm._e(),_c('v-container',{staticClass:"pt-5",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pl-4 pr-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_vm._v("Advanced rights")])])],1),_c('v-row',{staticClass:"pl-4 pr-4 pb-0"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-sheet',[_c('base-switch',{attrs:{"is-active":_vm.fieldsToValidate.hasAdminRights.value,"label":'Admin rights',"disabled":_vm.PermissionService.isDisabled(['isAdmin']) ||
                !_vm.isActiveUser ||
                _vm.fieldsToValidate.readOnly.value ||
                !!_vm.PermissionService.hasReadOnlyRestrict()},on:{"change-field":function (value) { return _vm.updateField({ fieldName: 'hasAdminRights', value: value }); }}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-sheet',[_c('base-switch',{attrs:{"is-active":_vm.fieldsToValidate.hasReportAccess.value,"label":'Report access',"disabled":_vm.PermissionService.isDisabled(['isAdmin']) ||
                !_vm.isActiveUser ||
                !!_vm.PermissionService.hasReadOnlyRestrict()},on:{"change-field":function (value) { return _vm.updateField({ fieldName: 'hasReportAccess', value: value }); }}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-sheet',[_c('base-switch',{attrs:{"is-active":_vm.fieldsToValidate.readOnly.value,"label":'Read only',"disabled":_vm.PermissionService.isDisabled(['isAdmin']) ||
                !_vm.isActiveUser ||
                _vm.fieldsToValidate.hasAdminRights.value ||
                !!_vm.PermissionService.hasReadOnlyRestrict() ||
                _vm.userToEditHasRoles()},on:{"change-field":function (value) { return _vm.updateField({ fieldName: 'readOnly', value: value }); }}})],1)],1)],1)],1),(!_vm.PermissionService.hasReadOnlyRestrict())?_c('v-divider'):_vm._e(),(!_vm.PermissionService.hasReadOnlyRestrict())?_c('v-container',{staticClass:"py-8",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pl-4 pr-4",attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-button',{staticClass:"cra-button mr-6",attrs:{"mode":"secondary"},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")]),(_vm.isActiveUser)?_c('base-confirm-button',{class:'cra-btn-secondary-colored',attrs:{"mode":"secondary","icon":"mdi-chevron-right","icon-size":'22px',"button-default-text":'Archive user',"button-confirm-text":'Confirm archive'},on:{"confirm":_vm.archiveUser}}):_vm._e()],1),_c('v-col',{staticClass:"text-end",attrs:{"cols":"12","md":"6"}},[(_vm.isActiveUser)?_c('base-button',{staticClass:"cra-button",attrs:{"mode":"primary","icon":"mdi-chevron-right","disabled":_vm.PermissionService.isDisabled(['isAdmin', 'isCurrentUser'])},on:{"click":_vm.submitForm}},[_vm._v(" Save ")]):_c('base-button',{staticClass:"cra-button",attrs:{"mode":"primary","icon":"mdi-restore","disabled":_vm.PermissionService.isDisabled(['isAdmin'])},on:{"click":_vm.activateUser}},[_vm._v(" Restore to active ")])],1)],1)],1):_vm._e(),_c('v-divider'),(_vm.isHistoryItems)?_c('user-history',{attrs:{"item-list":_vm.historyItems}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }