import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const getInitialCoreTeam = () => [
  'Customer Success Lead',
  'Zone Technical Director',
  'Account Executive',
  'Technical Application Manager',
  'Area Vice President',
  'Zone Service Director',
  'Regional Vice President',
  'Regional Service Manager',
];

export default {
  namespaced: true,
  state() {
    return {
      lastFetch: null,
      userRoles: [],
      coreTeamRoles: getInitialCoreTeam(),
    };
  },
  mutations,
  actions,
  getters,
};
