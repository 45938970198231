export default {
  accounts(state) {
    return state.accounts;
  },
  inOverview(state) {
    return state.accounts.filter((acc) => acc.type === 'Active' || acc.type === 'Submitted');
  },
  watchlist(state) {
    return state.accounts.filter((acc) => acc.type === 'Watchlist');
  },
  archive(state) {
    return state.accounts.filter((acc) => acc.type === 'Archived');
  },
  getFilteredAccounts(state, _, __, rootGetters) {
    const accountTypes = rootGetters['filters/getActiveAccountTypes'];
    const zones = rootGetters['filters/getActiveZones'];
    const customerTemperatures = rootGetters['filters/getActiveCustomerTemperatures'];
    const riskLevels = rootGetters['filters/getActiveRiskLevels'];
    const myAccounts = rootGetters['filters/getActiveMyAccounts'];
    const search = rootGetters['filters/getSearchTerm'];
    return state.accounts
      .filter((acc) => accountTypes.includes(acc.type))
      .filter((acc) => {
        if (zones.length) {
          return zones.includes(acc.zone);
        }
        return true;
      })
      .filter((acc) => {
        if (customerTemperatures.length) {
          return customerTemperatures.includes(acc.customerTemperature);
        }
        return true;
      })
      .filter((acc) => {
        if (riskLevels.length) {
          return riskLevels.includes(acc.riskLevel);
        }
        return true;
      })
      .filter((acc) => {
        const loggedInUserId = rootGetters['users/getLoggedInUser'].id;
        if (myAccounts) {
          return acc.responsibleMember.find((member) => member.id === loggedInUserId);
        }
        return true;
      })
      .filter((acc) => {
        if (search.length) {
          return acc.name.toLowerCase().indexOf(search.toLowerCase()) >= 0;
        }
        return true;
      })
      .sort(function (a, b) {
        return new Date(b.lastUpdate) - new Date(a.lastUpdate);
      });
  },
  hasAccounts(state) {
    return state.accounts && state.accounts.length > 0;
  },
  shouldUpdate(state) {
    const lastFetch = state.lastFetch;
    if (!lastFetch) {
      return true;
    }
    const currentTimestamp = new Date().getTime();
    return (currentTimestamp - lastFetch) / 1000 > 60;
  },
  getAccountInCreation(state) {
    return state.accountInCreation;
  },
  getAccountInCreationSavingState(state) {
    return state.accountInCreation.savingState;
  },
  getContractInCreation(state) {
    return state.accountInCreation.contract;
  },
  getCoreTeamInCreation(state) {
    return { ...state.accountInCreation.coreTeam };
  },
  getAccountToEdit(state) {
    return state.accountToEdit;
  },
  getAccountToEditStates(state) {
    return state.accountToEdit.states;
  },
  getAccountToEditType(state) {
    return state.accountToEdit.type;
  },
  getAccountToEditSavingState(state) {
    return state.accountToEdit.savingState;
  },
  getContractToEdit(state) {
    return state.accountToEdit.contract;
  },
  getAccountCustomerParameters(state) {
    return state.accountToEdit.accountCustomerParameters;
  },
  getAccountFiles(state) {
    return state.accountToEdit.files;
  },
  getCoreTeamAccountToEdit(state) {
    return { ...state.accountToEdit.coreTeam };
  },
  getNumberOfItemsAccountToEdit(state) {
    return state.accountToEdit.openActionItems + state.accountToEdit.resolvedActionItems;
  },
  getRevenueToEdit(state) {
    return state.accountToEdit.revenue;
  },
  getAccountCustomerParameterApprovers(state) {
    return state.accountToEdit._links.approveCustomerParameterChange;
  },
  getAccountApprovers(state) {
    return state.accountToEdit._links.approveAccount;
  },
};
