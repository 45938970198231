<template>
  <v-dialog v-model="isModalOn" max-width="700" v-on="$listeners">
    <v-card class="py-2">
      <v-card-title>
        <h5>
          <v-avatar size="30" class="mr-2 cra-avatar">
            <v-icon class="cra-btn-close" small>mdi-exclamation-thick</v-icon></v-avatar
          >Deny Approval
        </h5>
        <v-spacer></v-spacer>
        <v-icon class="cra-btn-close" large @click="closeAndCancel">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-container fluid class="px-0">
          <v-row>
            <v-col cols="12">
              <p>
                By clicking on the “Deny Approval” button, the new account will not be added to the
                app OR change of risk / temperature level will not take place. The core team members
                will receive an automated notification about the account or change being declined.
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                :value="getCommentInCreation && getCommentInCreation.comment"
                hide-details
                label="Message"
                filled
                @change="(value) => updateComment({ fieldName: 'comment', value })"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <base-button
          mode="secondary"
          icon="mdi-chevron-right"
          :icon-color="'primary'"
          @click="closeAndCancel"
          >Cancel</base-button
        >
        <v-spacer></v-spacer>
        <base-button
          mode="primary"
          :disabled="!isValid"
          icon="mdi-check-bold"
          @click="closeAndAccept"
          >Deny Approval</base-button
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseButton from '../../ui/BaseButton';
import ValidationService from '../../../services/ValidationService';
import { mapGetters } from 'vuex';
export default {
  name: 'ApprovalDenyDialog',
  components: {
    BaseButton,
  },
  data() {
    return {
      modal: true,
      commentToValidate: {
        comment: {
          validators: [ValidationService.hasContent],
        },
      },
    };
  },
  emits: ['cancel', 'accept'],
  computed: {
    ...mapGetters({
      getCommentInCreation: 'accountComments/getCommentInCreation',
      getCommentInCreationSavingState: 'accountComments/getCommentInCreationSavingState',
    }),
    isModalOn: {
      get() {
        return this.modal;
      },
      set() {
        this.$emit('cancel');
      },
    },
    isValid() {
      return ValidationService.checkFormValidity(this.commentToValidate, this.getCommentInCreation);
    },
  },
  methods: {
    updateComment(data) {
      this.$store.commit('accountComments/patchCommentInCreation', {
        [data.fieldName]: data.value,
      });
    },
    closeAndCancel() {
      this.$emit('cancel');
    },
    closeAndAccept() {
      this.$emit('accept');
    },
  },
};
</script>
<style lang="scss">
.cra-btn-close {
  cursor: pointer;
}
</style>
