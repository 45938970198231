<template>
  <v-card class="cra-user-card" v-on="$listeners">
    <v-card-text>
      <v-container fluid class="py-0">
        <v-row>
          <v-col cols="3">
            <v-avatar class="cra-avatar">
              <span>{{ initials }}</span>
            </v-avatar>
          </v-col>
          <v-col cols="9">
            <h6 class="h6-bold">{{ firstName }} {{ lastName }}</h6>
            <p class="p-tiny mb-2">
              <strong>Zone: </strong>
              <span v-for="(zone, index) in zones" :key="zone"
                >{{ zone }}<span v-if="index < zones.length - 1">, </span>
              </span>
            </p>
            <p class="p-tiny mb-0">
              <strong>Role: </strong>
              <span v-for="(role, index) in roles" :key="role"
                >{{ role }}<span v-if="index < roles.length - 1">, </span>
              </span>
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <div v-if="isProxy" class="cra-proxy-marker">
      <v-icon color="white" large>$vuetify.icons.userMultiple</v-icon>
    </div>
  </v-card>
</template>
<script>
import getInitials from '../../helpers/getInitials';
export default {
  name: 'UserCard',
  props: {
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
    zones: {
      type: Array,
      required: false,
      default: null,
    },
    roles: {
      type: Array,
      required: false,
      default: null,
    },
    isProxy: {
      type: Boolean,
      required: false,
      default: null,
    },
  },
  computed: {
    initials() {
      return getInitials(this.firstName, this.lastName);
    },
  },
};
</script>
