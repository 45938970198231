<template>
  <div :class="[className, getBiggestPieChart()]">
    <div class="cover"></div>
    <div
      v-for="(piece, index) in piePieces"
      :key="piece.type"
      :class="['piece', piece.type]"
      :style="getPiechartPiecePosition(index, piece.type)"
    >
      <div class="piece-inner" :style="getPiechartPieceRotation(piece.value)"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PieChart',
  props: {
    piePieces: {
      type: Array,
      required: true,
    },
    className: {
      type: String,
      required: true,
    },
  },
  methods: {
    getBiggestPieChart() {
      if (this.piePieces.length) {
        // return classname from object with biggest value (fill the circle)
        return this.piePieces.reduce((max, obj) => (max.value > obj.value ? max : obj)).type;
      }
    },
    getPiechartPiecePosition(counter) {
      let degrees = 0;
      if (counter > 0) {
        for (let i = 0; i < counter; i++) {
          degrees += this.getDegrees(this.piePieces[i].value);
        }
      }
      return 'transform: rotate(' + degrees + 'deg)';
    },
    getPiechartPieceRotation(value) {
      return 'transform: rotate(' + this.getDegrees(value) + 'deg)';
    },
    getDegrees(value) {
      let allValues = 0;
      this.piePieces.forEach((piece) => {
        allValues += piece.value;
      });
      const x = (value / allValues) * 100;
      return Math.round((x * 360) / 100);
    },
  },
};
</script>
