<template>
  <v-container fluid class="pt-2 pl-6 pr-6">
    <div class="cra-expand-panel-title" @click="expand = !expand">
      <h4 class="cra-expand-panel-headline">History</h4>
      <v-icon v-if="expand" x-large>mdi-chevron-up</v-icon>
      <v-icon v-else x-large>mdi-chevron-down</v-icon>
    </div>
    <v-expand-transition>
      <div v-show="expand">
        <v-timeline align-top dense>
          <v-timeline-item v-for="(item, index) in itemList" :key="index" color="black" small>
            <v-row class="pt-1">
              <v-col cols="8">
                <p>{{ item.description }}</p>
              </v-col>
              <v-col cols="4">
                <p>
                  <strong>{{ getFormattedDate(item.timestamp) }}</strong>
                </p>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </div>
    </v-expand-transition>
  </v-container>
</template>
<script>
export default {
  name: 'UserHistory',
  props: {
    itemList: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      expand: false,
    };
  },
  methods: {
    getFormattedDate(date) {
      return new Date(date).toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      }); // display date w/o time
    },
  },
};
</script>
