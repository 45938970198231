import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const getInitialFilteredInsightState = () => ({
  accountsOverview: {
    accountsActive: 0,
    accountsArchived: 0,
    accountsWatchlist: 0,
  },
  riskLevel: {
    low: 0,
    high: 0,
    medium: 0,
  },
  customerTemperature: {
    frustrated: 0,
    neutral: 0,
    satisfied: 0,
  },
  recentActivities: {
    accountsAdded: 0,
    accountsRemoved: 0,
    accountsUpdated: 0,
  },
  riskDevelopment: {
    favorably: 0,
    unchanged: 0,
    unfavorably: 0,
  },
});

export default {
  namespaced: true,
  state() {
    return {
      filteredInsights: getInitialFilteredInsightState(),
      loadedInsights: null,
    };
  },
  mutations,
  actions,
  getters,
};
