<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="nudgeRight"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="formattedDate"
        :label="label"
        :append-icon="appendIcon"
        readonly
        :filled="filled"
        hide-details
        v-bind="attrs"
        :disabled="disabled"
        :class="markerClass"
        v-on="on"
      />
    </template>
    <v-date-picker v-model="selectedDate" no-title :class="markerClass" @input="menu = false" />
  </v-menu>
</template>
<script>
import getDateAsString from '../../helpers/getDateAsString';
export default {
  name: 'BaseDatePicker',
  props: {
    date: {
      type: Date,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    filled: {
      type: Boolean,
      required: false,
      default: true,
    },
    appendIcon: {
      type: String,
      required: false,
      default: 'mdi-calendar',
    },
    markerClass: {
      type: String,
      required: false,
      default: 'test',
    },
    nudgeRight: {
      type: String,
      required: false,
      default: '0',
    },
  },
  data: () => ({
    selectedDate: null,
    menu: false,
  }),
  emits: ['change-field'],
  computed: {
    formattedDate() {
      return this.formatDate(this.selectedDate);
    },
  },
  watch: {
    selectedDate() {
      if (!this.date) {
        this.changeDate();
      }
      if (this.date && this.date.toISOString().substr(0, 10) !== this.selectedDate) {
        this.changeDate();
      }
    },
    date() {
      this.setSelectedDate();
    },
  },
  mounted() {
    this.setSelectedDate();
  },
  methods: {
    setSelectedDate() {
      if (!this.date) {
        return (this.selectedDate = null);
      }
      this.selectedDate = this.date.toISOString().substr(0, 10); // picker needs date yyyy-mm-dd
    },
    formatDate(date) {
      if (
        !date ||
        new Date(date).toISOString().substr(0, 10) === new Date(null).toISOString().substr(0, 10)
      ) {
        return '';
      }
      if (!date) return null;
      return getDateAsString(date);
    },
    changeDate() {
      this.$emit('change-field', this.selectedDate === null ? null : new Date(this.selectedDate));
    },
  },
};
</script>
