<template>
  <v-expansion-panel class="mb-16 cra-action-item">
    <v-expansion-panel-header
      ref="expansion-header"
      class="py-0 cra-action-item-header"
      :disabled="disabled"
      @click="setPanelState"
      @keyup.space.prevent
    >
      <div
        :class="
          getIsActionItemInCreationEditing
            ? 'cra-action-item-header-grid'
            : 'cra-action-item-add-header-closed-grid'
        "
      >
        <div class="cra-action-item-number">
          <h2 v-if="getIsActionItemInCreationEditing" class="cra-action-number my-0">
            {{ getNumberOfItemsAccountToEdit + 1 }}
          </h2>
          <v-icon v-else class="cra-action-number" large>mdi-plus-thick</v-icon>
        </div>
        <div class="cra-action-item-description">
          <div v-if="!getIsActionItemInCreationEditing">
            <h6 v-if="!getActionItemInCreation.description.length" class="my-0">
              Create a new action item
            </h6>
            <h6 v-else>
              {{ getActionItemInCreation.description }}
            </h6>
          </div>
          <div v-else @click.stop="handleFieldClick('input-title')">
            <v-textarea
              ref="input-title"
              dense
              :value="getActionItemInCreation && getActionItemInCreation.description"
              hide-details
              auto-grow
              rows="1"
              class="h6"
              @click.stop=""
              @click.native.stop
              @change="(value) => updateActionItem({ fieldName: 'description', value })"
            />
          </div>
        </div>
        <div v-if="getIsActionItemInCreationEditing" class="cra-action-item-category">
          <chips-select
            :title="'Categories'"
            :selected="getActionItemInCreation && getActionItemInCreation.category"
            :items="getActionItemCategoriesForSelect"
            @click.stop=""
            @click.native.stop
            @change-selected="(value) => updateActionItem({ fieldName: 'category', value })"
          ></chips-select>
        </div>
        <div v-if="getIsActionItemInCreationEditing" class="cra-action-item-issues">
          <chips-select
            :title="'Item issues'"
            :selected="getActionItemInCreation && getActionItemInCreation.issues"
            :items="getIssues"
            @click.stop=""
            @click.native.stop
            @change-selected="(value) => updateActionItem({ fieldName: 'issues', value })"
          ></chips-select>
        </div>
        <div v-if="getIsActionItemInCreationEditing" class="cra-action-item-labels">
          <chips-select
            :title="'Lab products'"
            :selected="getActionItemInCreation && getActionItemInCreation.labProducts"
            :items="getLabProducts"
            @click.stop=""
            @click.native.stop
            @change-selected="(value) => updateActionItem({ fieldName: 'labProducts', value })"
          ></chips-select>
        </div>
        <div v-if="getIsActionItemInCreationEditing" class="cra-action-item-responsible">
          <p class="p-tiny mb-3">
            <strong>Responsible</strong>
          </p>
          <user-select
            :items="userList"
            :role-name="'Responsible'"
            :narrow="true"
            :preselected="null"
            @click.stop=""
            @click.native.stop
            @change-user="
              (data) => updateActionItem({ fieldName: 'responsibleUserId', value: data.id })
            "
          />
        </div>
        <div v-if="getIsActionItemInCreationEditing" class="cra-action-item-date">
          <base-date-picker
            :date="getActionItemInCreation && getActionItemInCreation.dueDate"
            label="Due date"
            :filled="false"
            :append-icon="''"
            :nudge-right="'-100'"
            @click.stop=""
            @click.native.stop
            @change-field="(date) => updateActionItem({ fieldName: 'dueDate', value: date })"
          />
        </div>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content ref="expansion-content">
      <div class="cra-action-item-content-grid">
        <div class="cra-action-item-number"></div>
        <div class="cra-action-item-upload">
          <base-file-input
            :label="'Document upload'"
            :files="getActionItemInCreation && getActionItemInCreation.files"
            @add-files="(value) => updateActionItem({ fieldName: 'files', value })"
            @remove-files="(value) => removeFiles({ fieldName: 'files', value })"
          ></base-file-input>
        </div>
        <div class="cra-action-item-planned-impact">
          <v-currency-field
            label="Planned cost $"
            :value="getActionItemInCreation && getActionItemInCreation.plannedImpact"
            hide-details
            @change="(value) => updateCurrencyField({ fieldName: 'plannedImpact', value })"
          />
        </div>
        <div class="cra-action-item-actual-impact">
          <v-currency-field
            label="Actual cost $"
            :value="getActionItemInCreation && getActionItemInCreation.actualImpact"
            hide-details
            disabled
            @change="(value) => updateCurrencyField({ fieldName: 'actualImpact', value })"
          />
        </div>
      </div>
      <v-divider class="pb-8" />
      <v-container fluid class="pb-8 px-0">
        <v-row align="center" justify="space-between">
          <v-col cols="12" md="6"> </v-col>
          <v-col cols="12" md="3" style="text-align: right">
            <base-button
              mode="primary"
              :disabled="!isValid"
              class="cra-button"
              icon="mdi-chevron-right"
              @click="submitForm"
            >
              Submit action item
            </base-button>
          </v-col>
        </v-row>
      </v-container>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import { mapGetters } from 'vuex';
import ChipsSelect from '../../ui/ChipsSelect';
import BaseDatePicker from '../../ui/BaseDatePicker';
import UserSelect from '../../ui/UserSelect';
import BaseButton from '../../ui/BaseButton';
import BaseFileInput from '../../ui/BaseFileInput';
import ValidationService from '../../../services/ValidationService';

export default {
  name: 'ActionItemAdd',
  components: {
    ChipsSelect,
    BaseDatePicker,
    UserSelect,
    BaseButton,
    BaseFileInput,
  },
  emits: ['save-success'],
  props: {
    accountId: {
      type: String,
      required: true,
    },
    userList: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      fieldsToValidate: {
        description: { validators: [ValidationService.hasContent] },
        issues: { validators: [ValidationService.hasContent] },
        labProducts: { validators: [ValidationService.hasContent] },
        dueDate: { validators: [ValidationService.hasContent] },
        responsibleUserId: { validators: [ValidationService.hasContent] },
      },
    };
  },
  computed: {
    ...mapGetters({
      getIssues: 'issues/issues',
      getLabProducts: 'labProducts/labProducts',
      getActionItemInCreation: 'actionItems/getActionItemInCreation',
      getActionItemInCreationSavingState: 'actionItems/getActionItemInCreationSavingState',
      getNumberOfItemsAccountToEdit: 'accounts/getNumberOfItemsAccountToEdit',
      getIsActionItemInCreationEditing: 'actionItems/getIsActionItemInCreationEditing',
      getActionItemCategoriesForSelect: 'actionItems/getActionItemCategoriesForSelect',
      getActionItemSelectedCategoriesForSelect:
        'actionItems/getActionItemSelectedCategoriesForSelect',
    }),
    isValid() {
      const itemValid = ValidationService.checkFormValidity(
        this.fieldsToValidate,
        this.getActionItemInCreation
      );
      return itemValid;
    },
  },
  methods: {
    setPanelState() {
      this.$store.commit('actionItems/toggleActionItemInCreationIsEditing');
      this.newItemCounter = 0;

      if (this.getIsActionItemInCreationEditing) {
        this.$nextTick(() => {
          this.newItemCounter = this.getNumberOfItemsAccountToEdit + 1;
          this.$refs['input-title'].focus();
        });
      }
    },
    updateActionItem(data) {
      this.$store.commit('actionItems/patchActionItemInCreation', { [data.fieldName]: data.value });
    },
    updateCurrencyField(data) {
      if (isNaN(data.value)) {
        return false;
      }
      this.updateActionItem(data);
    },
    handleFieldClick(field) {
      this.$nextTick(() => {
        this.$refs[field][0].focus();
      });
    },
    async submitForm() {
      this.$store.commit('notifications/setBusy', true);
      await this.$store
        .dispatch('actionItems/createActionItem', { accountId: this.accountId })
        .then(() => this.$nextTick());

      if (!this.getActionItemInCreationSavingState.isSaving) {
        const message =
          this.getActionItemInCreationSavingState.itemId.length > 0 &&
          this.getActionItemInCreationSavingState.type === 'error'
            ? 'Item saved! ' + this.getActionItemInCreationSavingState.message
            : this.getActionItemInCreationSavingState.message;
        this.$refs['expansion-header'].$el.click();
        this.$store.commit('notifications/setSnackbar', {
          type: this.getActionItemInCreationSavingState.type,
          message: message,
        });
        this.$emit('save-success');
      }
      this.$store.commit('notifications/setBusy', false);
    },
    removeFiles(data) {
      this.$store.commit('actionItems/removeFileActionItemInCreation', {
        [data.fieldName]: data.value,
      });
    },
  },
};
</script>
