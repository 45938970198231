<template>
  <v-container fluid class="px-0 pb-6">
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <h5>Filter existing action items</h5>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-chip
              v-for="item in getFilterCategories(itemState)"
              :key="item"
              class="mb-2 mr-2"
              :dark="isActiveFilter('categories', item)"
              :close="isActiveFilter('categories', item)"
              @click="setFilterTag({ fieldName: 'categories', value: item })"
              @click:close="removeFilterTag({ fieldName: 'categories', value: item })"
              >{{ item }}</v-chip
            >
            <span
              v-if="isAnyActiveFilter('categories')"
              class="cra-btn-clear-filter"
              @click="removeAllFilterTags('categories')"
              >Clear filter</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import capitalizeFirstLetter from '../../../helpers/capitalizeFirstLetter';

export default {
  name: 'ActionItemsFilter',
  emits: ['change-filter'],
  props: {
    itemState: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getFilterCategories: 'actionItems/getFilterCategories',
      getActiveFilterCategories: 'actionItems/getActiveFilterCategories',
    }),
  },
  methods: {
    isActiveFilter(filter, value) {
      const activeFilterGetter = 'getActiveFilter' + capitalizeFirstLetter(filter);
      const activeFilter = this[activeFilterGetter];
      return activeFilter.includes(value);
    },
    setFilterTag(data) {
      this.$store.commit('actionItems/setFilterTag', data);
      this.$emit('change-filter');
    },
    removeFilterTag(data) {
      this.$store.commit('actionItems/removeFilterTag', data);
      this.$emit('change-filter');
    },
    removeAllFilterTags(filterType) {
      this.$store.commit('actionItems/removeAllFilterTags', filterType);
      this.$emit('change-filter');
    },
    isAnyActiveFilter(filterType) {
      const activeFilterGetter = 'getActiveFilter' + capitalizeFirstLetter(filterType);
      return this[activeFilterGetter].length;
    },
  },
};
</script>
