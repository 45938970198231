<template>
  <base-card :id="id" v-on="$listeners">
    <v-card-text v-if="isChipsIsOn" class="pb-0">
      <span v-for="(item, index) in states" :key="item + '_' + index">
        <v-chip
          v-if="item.length"
          small
          class="cra-chip mr-2 mb-2"
          :color="chipBgColor(item)"
          :text-color="chipTextColor(item)"
          >{{ item }}</v-chip
        >
      </span>
    </v-card-text>
    <v-card-title>
      <h5 class="cra-text-wrap h5-bold">{{ title }}</h5>
    </v-card-title>
    <v-card-text :class="states.includes(AccountStates.pendingApproval) ? 'cra-card-trans' : ''">
      <div v-if="sumOfRevenue !== 0" class="cra-card-revenue-wrap">
        <v-currency-field
          v-model="sumOfRevenue"
          class="cra-card-revenue"
          readonly
          hide-details
          prefix="$"
        />
      </div>
      <p v-else class="cra-card-revenue">Remaining Contractual Obligation n/a</p>
      <v-container fluid class="py-0">
        <v-row>
          <v-col cols="6" class="pl-0"
            ><p v-if="soldTo.length" class="p-tiny">Sold {{ soldTo }}</p>
          </v-col>
          <v-col cols="6" class="pr-0"
            ><p v-if="shipTo.length" class="p-tiny">Ship {{ shipTo }}</p>
          </v-col>
        </v-row>
      </v-container>
      <v-divider class="pb-4" />
      <v-container fluid class="py-0">
        <v-row>
          <v-col cols="6" class="pl-0"
            ><p class="cra-card-risk-level" :class="riskLevelItemClass">{{ riskLevel }}</p></v-col
          >
          <v-col cols="6" class="pr-0"
            ><p class="cra-card-customer-temperature" :class="customerTemperatureItemClass">
              {{ customerTemperature }}
            </p></v-col
          >
        </v-row>
      </v-container>
      <v-divider class="pb-4" />
      <div class="cra-card-list mb-4">
        <div class="cra-card-list-item mb-4">
          <v-icon class="cra-card-list-icon">$vuetify.icons.table</v-icon>{{ openActionItems }} open
          action items
        </div>
        <div class="cra-card-list-item mb-4">
          <v-icon class="cra-card-list-icon">$vuetify.icons.user</v-icon>CSL:
          {{ customerSuccessLead.firstName }}
          {{ customerSuccessLead.lastName }}
        </div>
        <div class="cra-card-list-item mb-4">
          <v-icon class="cra-card-list-icon">$vuetify.icons.map</v-icon>{{ zone }}
        </div>
        <div v-if="issues" class="cra-card-chip-list">
          <v-chip v-for="item in issues" :key="item">{{ item }}</v-chip>
        </div>
      </div>
      <v-divider class="pb-4" />
      <div class="cra-card-list-item p-tiny">
        <v-icon class="cra-card-list-icon">$vuetify.icons.checkmarkWarning</v-icon>Last Update
        {{ lastUpdate }}
      </div>
    </v-card-text>
  </base-card>
</template>
<script>
import BaseCard from '../ui/BaseCard';
import removeSpacesLowerCase from '../../helpers/removeSpacesLowerCase';
import { AccountStates } from '../../store/modules/accounts';
import isExpired from '../../helpers/isExpired';
export default {
  name: 'AccountCard',
  components: { BaseCard },
  props: {
    title: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    zone: {
      type: String,
      required: true,
    },
    states: {
      type: Array,
      required: true,
    },
    customerSuccessLead: {
      type: Object,
      required: true,
    },
    customerTemperature: {
      type: String,
      required: true,
    },
    riskLevel: {
      type: String,
      required: true,
    },
    lastUpdate: {
      type: String,
      required: true,
    },
    sumOfRevenue: {
      type: Number,
      required: false,
      default: 0,
    },
    shipTo: {
      type: String,
      required: false,
      default: '',
    },
    soldTo: {
      type: String,
      required: false,
      default: '',
    },
    openActionItems: {
      type: Number,
      required: false,
      default: 0,
    },
    issues: {
      type: Array,
      required: false,
      default: () => [],
    },
    rfpDueDate: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      AccountStates,
    };
  },
  computed: {
    isChipsIsOn() {
      if (this.states.length) {
        if (this.states.length === 1 && this.states.includes('')) {
          return false;
        }
        return !(this.states.includes(this.AccountStates.rfpDue) && isExpired(this.rfpDueDate));
      } else {
        return false;
      }
    },
    riskLevelItemClass() {
      return removeSpacesLowerCase(this.riskLevel);
    },
    customerTemperatureItemClass() {
      return removeSpacesLowerCase(this.customerTemperature);
    },
  },
  created() {
    this.AccountStates = AccountStates;
  },
  methods: {
    chipBgColor(value) {
      return 'bg_' + removeSpacesLowerCase(value);
    },
    chipTextColor(value) {
      return 'txt_' + removeSpacesLowerCase(value);
    },
  },
};
</script>
<style scoped lang="scss">
@import 'src/scss/globals';

.cra-card-list-item {
  position: relative;
  padding-left: 30px;
  font-size: 1rem; //16px;
  line-height: 1.563;
  color: $gray-tint-1;

  .cra-card-list-icon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.cra-card-chip-list {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  .v-chip {
    display: block;
    width: auto;
    overflow: visible;
    margin-right: 0.5rem;
  }
}
</style>
