export default {
  getComments(state) {
    return state.comments.sort(function (a, b) {
      return new Date(b.created) - new Date(a.created);
    });
  },

  getCommentInCreation(state) {
    return state.commentInCreation;
  },

  getCommentInCreationSavingState(state) {
    return state.commentInCreation.savingState;
  },

  getFilterLabProducts(state) {
    let filter = [];
    state.comments.forEach((comment) => {
      comment.labProducts.forEach((prod) => {
        if (!filter.includes(prod)) {
          filter.push(prod);
        }
      });
    });
    return filter;
  },

  getFilterAccountIssues(state) {
    let filter = [];
    state.comments.forEach((comment) => {
      comment.accountIssues.forEach((issue) => {
        if (!filter.includes(issue)) {
          filter.push(issue);
        }
      });
    });
    return filter;
  },

  getActiveFilterLabProducts(state) {
    return state.filters.labProducts;
  },

  getActiveFilterAccountIssues(state) {
    return state.filters.accountIssues;
  },
};
