<template>
  <v-dialog v-model="isModalOn" max-width="700" v-on="$listeners">
    <v-card class="py-2">
      <v-card-title>
        <h5>
          <v-avatar size="30" class="mr-2 cra-avatar">
            <v-icon class="cra-btn-close" small>mdi-exclamation-thick</v-icon></v-avatar
          >Change account status
        </h5>
        <v-spacer></v-spacer>
        <v-icon class="cra-btn-close" large @click="closeAndCancel">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-container fluid class="px-0">
          <v-row>
            <v-col cols="12">
              <p>
                {{ introText }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <base-date-picker
                v-if="dateIsOn"
                :date="
                  getAccountToEdit.changingStateFields && getAccountToEdit.changingStateFields.date
                "
                label="Pick a date"
                :append-icon="''"
                @change-field="(date) => updateField({ fieldName: 'date', value: date })"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                :value="getCommentInCreation && getCommentInCreation.comment"
                hide-details
                label="Add Comment"
                filled
                @change="(value) => updateComment({ fieldName: 'comment', value })"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <base-button
          mode="secondary"
          icon="mdi-chevron-right"
          :icon-color="'primary'"
          @click="closeAndCancel"
          >Cancel</base-button
        >
        <v-spacer></v-spacer>
        <base-button
          mode="primary"
          :disabled="!isValid"
          icon="mdi-check-bold"
          @click="closeAndAccept"
          >Accept</base-button
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseButton from '../../ui/BaseButton';
import ValidationService from '../../../services/ValidationService';
import BaseDatePicker from '../../ui/BaseDatePicker';
import { mapGetters } from 'vuex';

export default {
  name: 'AccountChangeStateDialog',
  components: {
    BaseButton,
    BaseDatePicker,
  },
  emits: ['cancel', 'accept'],
  props: {
    commentIsMandantory: {
      type: Boolean,
      required: true,
    },
    dateIsOn: {
      type: Boolean,
      required: true,
    },
    introText: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      modal: true,
      fieldsToValidate: {
        date: {
          validators: [],
        },
      },
      commentToValidate: {
        comment: {
          validators: [],
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      getCommentInCreation: 'accountComments/getCommentInCreation',
      getCommentInCreationSavingState: 'accountComments/getCommentInCreationSavingState',
      getAccountToEdit: 'accounts/getAccountToEdit',
    }),
    isModalOn: {
      get() {
        return this.modal;
      },
      set() {
        this.$emit('cancel');
      },
    },
    isValid() {
      const commentValid = ValidationService.checkFormValidity(
        this.commentToValidate,
        this.getCommentInCreation
      );
      const formValid = ValidationService.checkFormValidity(
        this.fieldsToValidate,
        this.getAccountToEdit.changingStateFields
      );
      return commentValid && formValid;
    },
  },
  created() {
    this.setFormFieldValidators();
  },
  methods: {
    setFormFieldValidators() {
      if (this.commentIsMandantory) {
        this.commentToValidate.comment.validators = [ValidationService.hasContent];
      }
      if (this.dateIsOn) {
        this.fieldsToValidate.date.validators = [ValidationService.hasContent];
      }
    },
    updateField(data) {
      this.$store.commit('accounts/patchChangingStateFields', {
        [data.fieldName]: data.value,
      });
    },
    updateComment(data) {
      this.$store.commit('accountComments/patchCommentInCreation', {
        [data.fieldName]: data.value,
      });
    },
    closeAndCancel() {
      this.$store.commit('accounts/resetChangingStateFields');
      this.$emit('cancel');
    },
    closeAndAccept() {
      this.$emit('accept');
    },
  },
};
</script>
