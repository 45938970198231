import { getInitialAccountCommentInCreationState } from './index';

export default {
  setComments(state, payload) {
    state.comments = payload;
  },
  addCommentInCreation(state) {
    state.commentInCreation = { ...getInitialAccountCommentInCreationState() };
  },
  patchCommentInCreation: function (state, payload) {
    state.commentInCreation = { ...state.commentInCreation, ...payload };
  },
  setCommentInCreationSavingState(state, payload) {
    state.commentInCreation.savingState = { ...payload };
  },
  setFilterTag(state, payload) {
    const { fieldName, value } = payload;
    Object.keys(state.filters).forEach((key) => {
      if (key === fieldName) {
        state.filters[key] = [...state.filters[key], value];
      }
    });
  },
  removeFilterTag(state, payload) {
    const { fieldName, value } = payload;
    Object.keys(state.filters).forEach((key) => {
      if (key === fieldName) {
        const i = state.filters[key].indexOf(value);
        state.filters[key].splice(i, 1);
      }
    });
  },
  removeAllFilterTags(state, filterType) {
    Object.keys(state.filters).forEach((key) => {
      if (key === filterType) {
        state.filters[key] = [];
      }
    });
  },
};
