import { getInitialActionItemCommentInCreationState } from './index';

export default {
  setComments(state, payload) {
    state.comments = payload;
  },
  addCommentInCreation(state) {
    state.commentInCreation = { ...getInitialActionItemCommentInCreationState() };
  },
  patchCommentInCreation: function (state, payload) {
    state.commentInCreation = { ...state.commentInCreation, ...payload };
  },

  setCommentInCreationSavingState(state, payload) {
    state.commentInCreation.savingState = { ...payload };
  },
};
