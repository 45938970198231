<template>
  <v-card v-on="$listeners">
    <slot />
  </v-card>
</template>
<script>
export default {
  name: 'BaseCard',
};
</script>
<style scoped lang="scss">
@import 'src/scss/globals';
</style>
