<template>
  <div>
    <v-text-field
      v-if="isEditable"
      v-model="linkField"
      label="Add link"
      prepend-icon="mdi-link"
      @change="(value) => changeInput(value)"
    ></v-text-field>
    <div class="mt-4 cra-link-list">
      <div v-for="link in links" :key="link" class="cra-chip-flat mb-3">
        <span class="cra-chip-flat-link p-tiny mr-1" @click="openLink(link)">
          <span class="text-truncate d-inline-block">{{ link }}</span>
        </span>
        <v-icon v-if="isEditable" class="cra-chip-flat-icon" dense @click="deleteLink(link)"
          >mdi-close-circle</v-icon
        >
      </div>
    </div>
  </div>
</template>
<script>
import ValidationService from '../../services/ValidationService';

export default {
  name: 'AdditionalLinkField',
  emits: ['change-link-list'],
  props: {
    links: {
      type: Array,
      required: false,
      default: null,
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      inputLinks: [],
      linkField: '',
    };
  },
  watch: {
    links() {
      this.setLinks();
    },
  },
  mounted: function () {
    this.setLinks();
  },
  methods: {
    setLinks() {
      if (this.links) {
        this.inputLinks = this.links;
      } else {
        this.inputLinks = [];
      }
    },
    changeInput(value) {
      if (
        ValidationService.isLink(value) &&
        !ValidationService.isAlreadySetInArray(this.inputLinks, value)
      ) {
        this.inputLinks.push(value);
        this.linkField = '';
        this.$emit('change-link-list', this.inputLinks);
      } else if (!ValidationService.isLink(value)) {
        this.$store.commit('notifications/setSnackbar', {
          type: 'error',
          message: 'This is not a valid url',
        });
      } else if (ValidationService.isAlreadySetInArray(this.inputLinks, value)) {
        this.$store.commit('notifications/setSnackbar', {
          type: 'error',
          message: 'This link is set already',
        });
      }
    },
    openLink(link) {
      window.open(link, '_blank');
    },
    deleteLink(link) {
      const linkArray = this.inputLinks;
      this.inputLinks = linkArray.filter(function (el) {
        return el != link;
      });
      this.$emit('change-link-list', this.inputLinks);
    },
  },
};
</script>
<style lang="scss">
.cra-link-list {
  .text-truncate {
    max-width: 90%;
    &:hover {
      text-decoration: underline;
    }
  }
  .cra-chip-flat-icon {
    margin-top: -12px;
  }
}
</style>
