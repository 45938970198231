export default (first, last) => {
  if (first && last) {
    return first + ' ' + last;
  }
  if (first) {
    return first;
  }
  if (last) {
    return last;
  }
  return null;
};
