<template>
  <v-toolbar dark class="shs-dark-toolbar" height="76" width="100vw">
    <div
      v-if="isMobile"
      class="cra-filterbar-activator d-flex justify-space-between align-center col-12"
    >
      <h4>Search & Filter</h4>
      <base-button
        mode="primary"
        :icon="!filterbarOpen ? 'mdi-filter' : 'mdi-close'"
        only-icon
        @click="toggleFilterbar"
      />
    </div>
    <div
      class="cra-filterbar"
      :class="[isMobile ? 'cra-filterbar-mobile' : '', !filterbarOpen ? 'hidden' : '']"
    >
      <accounts-filter v-if="isAccountsFilter" :is-mobile="isMobile" />
      <users-filter v-if="isUsersFilter" :is-mobile="isMobile"></users-filter>
      <insights-filter v-if="isInsightsFilter" :is-mobile="isMobile"></insights-filter>
    </div>
  </v-toolbar>
</template>
<script>
import AccountsFilter from '../accounts/AccountsFilter';
import BaseButton from '../ui/BaseButton';
import UsersFilter from '../users/UsersFilter';
import InsightsFilter from '../insights/InsightsFilter';

export default {
  name: 'TheFilterbar',
  components: { InsightsFilter, UsersFilter, BaseButton, AccountsFilter },
  data() {
    return {
      filterbarOpen: false,
    };
  },
  computed: {
    isMobile() {
      return !this.$vuetify.breakpoint.mdAndUp;
    },
    isAccountsFilter() {
      return (
        this.$route.name === 'accounts' ||
        this.$route.name === 'watchlist' ||
        this.$route.name === 'archive'
      );
    },
    isUsersFilter() {
      return this.$route.name === 'users';
    },
    isInsightsFilter() {
      return this.$route.name === 'insights';
    },
  },
  methods: {
    toggleFilterbar() {
      this.filterbarOpen = !this.filterbarOpen;
    },
  },
};
</script>
<style scoped lang="scss">
@import 'src/scss/globals';

.shs-dark-toolbar {
  position: relative;
  z-index: +1;
  &.v-toolbar {
    background: $black;
  }
}

.cra-filterbar {
  width: 100%;

  &-mobile {
    position: absolute;
    left: 0;
    top: 76px;
    background: $black;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    &.hidden {
      display: none;
    }
  }

  &-activator {
    h4 {
      color: $white;
    }
  }
}
</style>
