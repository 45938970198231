<template>
  <v-container fluid class="pl-6 pr-6">
    <div class="cra-expand-panel-title" @click="expand = !expand">
      <h4 class="cra-expand-panel-headline">Comments</h4>
      <v-icon v-if="expand" x-large>mdi-chevron-up</v-icon>
      <v-icon v-else x-large>mdi-chevron-down</v-icon>
    </div>
    <v-expand-transition>
      <div v-show="expand">
        <account-comment-add v-if="isCreateAllowed" :account-id="accountId"></account-comment-add>
        <v-divider v-if="getFilterAccountIssues.length" class="mt-6"></v-divider>
        <v-container v-if="getFilterAccountIssues.length" fluid>
          <v-row class="pt-4">
            <v-col cols="12" class="pl-0">
              <v-row>
                <v-col cols="12">
                  <h5>Filter existing comments</h5>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <p class="p-tiny"><strong>Account issues</strong></p>
                  <v-chip
                    v-for="item in getFilterAccountIssues"
                    :key="item"
                    class="mb-2 mr-2"
                    :dark="isActiveFilter('accountIssues', item)"
                    :close="isActiveFilter('accountIssues', item)"
                    @click="setFilterTag({ fieldName: 'accountIssues', value: item })"
                    @click:close="removeFilterTag({ fieldName: 'accountIssues', value: item })"
                    >{{ item }}</v-chip
                  >
                  <span
                    v-if="isAnyActiveFilter('accountIssues')"
                    class="cra-btn-clear-filter"
                    @click="removeAllFilterTags('accountIssues')"
                    >Clear filter</span
                  >
                </v-col>
                <v-col cols="12" md="6">
                  <p class="p-tiny"><strong>Lab products</strong></p>
                  <v-chip
                    v-for="item in getFilterLabProducts"
                    :key="item"
                    class="mb-2 mr-2"
                    :dark="isActiveFilter('labProducts', item)"
                    :close="isActiveFilter('labProducts', item)"
                    @click="setFilterTag({ fieldName: 'labProducts', value: item })"
                    @click:close="removeFilterTag({ fieldName: 'labProducts', value: item })"
                    >{{ item }}</v-chip
                  >
                  <span
                    v-if="isAnyActiveFilter('labProducts')"
                    class="cra-btn-clear-filter"
                    @click="removeAllFilterTags('labProducts')"
                    >Clear filter</span
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <v-divider v-if="comments.length" class="mt-6"></v-divider>
        <v-container v-if="comments.length" fluid>
          <v-row class="pt-4 pb-0">
            <v-col cols="12" class="pl-0">
              <v-data-table
                :items="filteredComments"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                hide-default-footer
                hide-default-header
                @page-count="pageCount = $event"
              >
                <template #item="{ item }">
                  <div class="mb-3 d-flex">
                    <div class="col-3 col-sm-2 col-md-1 pt-0">
                      <v-avatar class="cra-avatar mr-2"
                        ><span>{{ getUserInitials(item.createdBy) }}</span></v-avatar
                      >
                    </div>
                    <div class="col-9 col-sm-10 col-md-11 pt-0">
                      <h6 class="mb-2">
                        {{ getCreatedDate(item.created) }},
                        {{ getUserFullName(item.createdBy) }}
                      </h6>
                      <p>{{ item.comment }}</p>
                      <v-chip
                        v-for="issItem in item.accountIssues"
                        :key="issItem"
                        class="mb-2 mr-2"
                        >{{ issItem }}</v-chip
                      >
                      <v-chip
                        v-for="labItem in item.labProducts"
                        :key="labItem"
                        class="mb-2 mr-2"
                        >{{ labItem }}</v-chip
                      >
                    </div>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row class="px-0">
            <v-col cols="12" class="pl-0 text-end">
              <v-pagination
                v-if="pageCount > 1"
                v-model="page"
                class="cra-pagination-comments"
                :length="pageCount"
                circle
              ></v-pagination></v-col
          ></v-row>
        </v-container>
      </div>
    </v-expand-transition>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import AccountCommentAdd from './AccountCommentAdd';
import getInitials from '../../../helpers/getInitials';
import capitalizeFirstLetter from '../../../helpers/capitalizeFirstLetter';
import { AccountStates } from '../../../store/modules/accounts';

export default {
  name: 'AccountComments',
  components: { AccountCommentAdd },
  props: {
    accountId: {
      type: String,
      required: true,
    },
    commentList: {
      type: Object,
      required: true,
    },
    isCreateAllowed: {
      type: Boolean,
      required: false,
      default: true,
    },
    accountStateList: {
      type: Array,
      required: false,
      default: Object,
    },
  },
  data() {
    return {
      search: '',
      expand: true,
      comments: [],
      filteredComments: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      AccountStates,
    };
  },
  computed: {
    ...mapGetters({
      getUserById: 'users/userById',
      getFilterLabProducts: 'accountComments/getFilterLabProducts',
      getFilterAccountIssues: 'accountComments/getFilterAccountIssues',
      getActiveFilterLabProducts: 'accountComments/getActiveFilterLabProducts',
      getActiveFilterAccountIssues: 'accountComments/getActiveFilterAccountIssues',
    }),
  },
  watch: {
    commentList() {
      this.setComments();
    },
  },
  created() {
    this.setComments();
    this.AccountStates = AccountStates;
    if (this.accountStateList.includes(this.AccountStates.pendingApproval)) {
      this.expand = false;
    }
  },
  methods: {
    setComments() {
      this.comments = [];
      Object.keys(this.commentList).forEach((key) => {
        const obj = this.commentList[key];
        this.comments.push(obj);
      });
      this.filterComments();
    },
    filterComments() {
      this.filteredComments = [];
      if (
        this.getActiveFilterLabProducts.length === 0 &&
        this.getActiveFilterAccountIssues.length === 0
      ) {
        this.filteredComments = this.comments;
        return true;
      }
      this.comments.forEach((comment) => {
        this.getActiveFilterAccountIssues.forEach((filter) => {
          if (comment.accountIssues.includes(filter) && !this.filteredComments.includes(comment)) {
            this.filteredComments.push(comment);
          }
        });
        this.getActiveFilterLabProducts.forEach((filter) => {
          if (comment.labProducts.includes(filter) && !this.filteredComments.includes(comment)) {
            this.filteredComments.push(comment);
          }
        });
      });
    },
    getUserInitials(id) {
      if (this.getUserById(id)) {
        return getInitials(this.getUserById(id).firstName, this.getUserById(id).lastName);
      } else {
        return '';
      }
    },
    getUserFullName(id) {
      if (this.getUserById(id)) {
        return this.getUserById(id).firstName + ' ' + this.getUserById(id).lastName;
      } else {
        return '';
      }
    },
    getCreatedDate(date) {
      return new Date(date).toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      }); // display date w/o time
    },
    setFilterTag(data) {
      this.$store.commit('accountComments/setFilterTag', data);
      this.filterComments();
    },
    removeFilterTag(data) {
      this.$store.commit('accountComments/removeFilterTag', data);
      this.filterComments();
    },
    removeAllFilterTags(filterType) {
      this.$store.commit('accountComments/removeAllFilterTags', filterType);
      this.filterComments();
    },
    isActiveFilter(filter, value) {
      const activeFilterGetter = 'getActiveFilter' + capitalizeFirstLetter(filter);
      const activeFilter = this[activeFilterGetter];
      return activeFilter.includes(value);
    },
    isAnyActiveFilter(filterType) {
      const activeFilterGetter = 'getActiveFilter' + capitalizeFirstLetter(filterType);
      return this[activeFilterGetter].length;
    },
  },
};
</script>
