export default {
  getActionItems(state) {
    return state.actionItems;
  },
  getOpenActionItems(state) {
    return state.actionItems
      .filter((item) => item.state === 'Open')
      .sort((a, b) => a.number - b.number);
  },
  getResolvedActionItems(state) {
    return state.actionItems
      .filter((item) => item.state === 'Resolved')
      .sort((a, b) => a.number - b.number);
  },
  getActionItemInCreation(state) {
    return state.actionItemInCreation;
  },
  getActionItemInCreationSavingState(state) {
    return state.actionItemInCreation.savingState;
  },
  getIsActionItemInCreationEditing(state) {
    return state.actionItemInCreation.isEditing;
  },
  getActionItem: (state) => (id) => {
    return state.actionItems.find((item) => item.id === id);
  },
  getActionItemsEdits(state) {
    return state.actionItemEdits;
  },
  getActionItemToEdit(state) {
    return state.actionItemToEdit;
  },
  getActionItemFiles: (state) => (id) => {
    return state.actionItems.find((item) => item.id === id).files;
  },
  getFinancialImpact(state) {
    let impact = 0;
    Object.keys(state.actionItems).forEach((key) => {
      impact += Number(state.actionItems[key].actualImpact);
    });
    return impact;
  },
  getActionItemSelectedCategoriesForSelect: (state) => (catSlugsArray) => {
    let cats = [];
    if (!catSlugsArray || !catSlugsArray.length) {
      return cats;
    }
    catSlugsArray.forEach((cat) => {
      state.allFilters.categories.find((item) => {
        if (cat === item.slug) {
          cats.push(item.title);
        }
      });
    });
    return cats;
  },
  getActionItemCategoriesForSelect(state) {
    let cats = [];
    state.allFilters.categories.forEach((item) => {
      cats.push(item.title);
    });
    return cats;
  },
  getActionItemCategoryTitle: (state) => (catSlugsArray) => {
    return state.allFilters.categories.find((item) => catSlugsArray.includes(item.slug)).title;
  },
  getActionItemCategorySlug: (state) => (catTitle) => {
    return state.allFilters.categories.find((item) => catTitle === item.title).slug;
  },
  getFilterCategories: (state, getters) => (itemState) => {
    let filter = [];
    state.actionItems.forEach((item) => {
      if (item.state === itemState && item.category !== null) {
        item.category.forEach((cat) => {
          if (!filter.includes(getters.getActionItemCategoryTitle(cat))) {
            filter.push(getters.getActionItemCategoryTitle(cat));
          }
        });
      }
    });
    return filter;
  },
  getActiveFilterCategories(state) {
    return state.activeFilters.categories;
  },
};
