import ApiService from '../../../services/ApiService';
import removeSpaces from '../../../helpers/removeSpaces';

export default {
  async loadInsights(context) {
    const resp = await ApiService.fetchInsights('');
    context.commit('setInsights', { AllZones: resp.data });
    const zones = context.rootGetters['zones/zones'];
    zones.forEach((zone) => {
      context.dispatch('loadZoneInsights', removeSpaces(zone));
    });
    context.commit('setFilteredInsights', context.rootGetters['filters/getActiveZones']);
  },
  async loadZoneInsights(context, zone) {
    const resp = await ApiService.fetchInsights('?zone=' + zone);
    context.commit('setInsights', { [zone]: resp.data });
  },
};
