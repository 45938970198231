import ApiService from '../../../services/ApiService';

export default {
  async loadAccountComments(context, payload) {
    const response = await ApiService.fetchAccountComments(payload.id);
    const commentResponseList = response.data;
    const comments = [];

    for (const key in commentResponseList) {
      const comment = {
        comment: commentResponseList[key].comment,
        accountIssues: commentResponseList[key].accountIssues,
        created: commentResponseList[key].created,
        createdBy: commentResponseList[key].createdBy,
        labProducts: commentResponseList[key].labProducts,
      };
      comments.push(comment);
    }
    context.commit('setComments', comments);
    context.commit('addCommentInCreation');
  },

  async createAccountComment(context, payload) {
    const commentToSave = { ...context.state.commentInCreation };

    delete commentToSave.tags;
    delete commentToSave.savingState;

    context.commit('setCommentInCreationSavingState', {
      isSaving: true,
      type: '',
      message: '',
    });
    try {
      await ApiService.createAccountComment(payload.accountId, commentToSave);
      context.commit('setCommentInCreationSavingState', {
        isSaving: false,
        type: 'success',
        message: 'Comment saved',
      });
      await context.dispatch('loadAccountComments', { id: payload.accountId });
    } catch (e) {
      context.commit('setCommentInCreationSavingState', {
        isSaving: false,
        type: 'error',
        message: 'Comment save failed! ' + e.toString(),
      });
      console.log('error creating comment', e);
    }
  },
};
