<template>
  <v-btn
    :color="mode"
    rounded
    :outlined="mode !== 'primary'"
    :icon="onlyIcon"
    depressed
    large
    :disabled="disabled"
    class="cra-btn"
    :class="classes"
    v-on="$listeners"
    @click="confirm"
  >
    <v-icon v-if="icon" :left="!onlyIcon" :center="onlyIcon" :size="iconSize" :color="iconColor">
      {{ icon }}
    </v-icon>
    {{ buttonText }}
  </v-btn>
</template>
<script>
export default {
  name: 'BaseConfirmButton',
  emits: ['confirm'],
  props: {
    mode: {
      type: String,
      required: false,
      default: null,
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
    iconSize: {
      type: String,
      required: false,
      default: '25px',
    },
    iconColor: {
      type: String,
      required: false,
      default: 'white',
    },
    onlyIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttonDefaultText: {
      type: String,
      required: true,
    },
    buttonConfirmText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      clicks: 0,
      buttonText: '',
      classes: [],
    };
  },
  created() {
    this.buttonText = this.buttonDefaultText;
  },
  methods: {
    confirm() {
      this.clicks++;
      if (this.clicks === 1) {
        this.buttonText = this.buttonConfirmText;
        this.classes = ['cra-btn-confirm'];
      }
      if (this.clicks === 2) {
        this.$emit('confirm');
        this.buttonText = this.buttonDefaultText;
        this.classes = [];
        this.clicks = 0;
      }
    },
  },
};
</script>
