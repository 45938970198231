import axios from 'axios';
import { getMSALAccessToken } from '../plugins/auth';

const config = {
  withCredentials: true,
  responseType: 'json',
};

const configFilesUpload = {
  withCredentials: true,
  headers: {
    'content-type': 'multipart/form-data',
  },
};

const configFilesDownload = {
  withCredentials: true,
  headers: {
    'content-type': 'application/octet-stream',
  },
  responseType: 'blob',
};

axios.interceptors.request.use(
  async (requestConfig) => {
    const accessToken = await getMSALAccessToken();
    requestConfig.headers.Authorization = `Bearer ${accessToken}`; // eslint-disable-line no-param-reassign
    return requestConfig;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 ||
        (error.response.status === 403 && error.response.data.code !== 'E403-002'))
    ) {
      if (localStorage.getItem('currentUser')) {
        localStorage.removeItem('currentUser');
      }
      // eslint-disable-next-line no-restricted-globals
      location.href = '/?message=Noaccess';
    }
    return Promise.reject(error);
  }
);

const ApiService = {
  approveAccount(link) {
    return axios.patch(link).catch((e) => {
      console.log(e);
    });
  },
  denyAccount(id, payload) {
    return axios.patch('/accounts/' + id + '/deny', payload).catch((e) => {
      console.log(e);
    });
  },
  approveAccountCustomer(link) {
    return axios.patch(link).catch((e) => {
      console.log(e);
    });
  },
  denyAccountCustomer(id, payload) {
    return axios.patch('/accounts/' + id + '/customer/reject', payload).catch((e) => {
      console.log(e);
    });
  },
  fetchAllAccounts() {
    return axios.get('/accounts', config);
  },
  fetchAccountDetails(id) {
    return axios.get('/accounts/' + id, config);
  },
  fetchActionItems(id) {
    return axios.get('/accounts/' + id + '/items', config);
  },
  fetchAccountComments(id) {
    return axios.get('/accounts/' + id + '/comments', config);
  },
  fetchAccountHistory(id) {
    return axios.get('/accounts/' + id + '/history', config);
  },
  fetchActionItemComments(id) {
    return axios.get('/items/' + id + '/comments', config);
  },
  fetchAllUsers() {
    return axios.get('/users', config);
  },
  fetchUserDetails(id) {
    return axios.get('/users/' + id, config);
  },
  fetchLoggedInUser() {
    return axios.get('/users/me', config);
  },
  fetchUserHistory(id) {
    return axios.get('/users/' + id + '/history', config);
  },
  fetchZones() {
    return axios.get('/accounts/zones', config);
  },
  fetchRiskLevels() {
    return axios.get('/accounts/risklevel', config);
  },
  fetchCustomerTemperatures() {
    return axios.get('/accounts/customertemperature', config);
  },
  fetchIssues() {
    return axios.get('/accounts/issues', config);
  },
  fetchLabProducts() {
    return axios.get('/accounts/labproducts', config);
  },
  fetchUserRoles() {
    return axios.get('/users/roles', config);
  },
  fetchAccountApprovers(id) {
    return axios.get('/accounts/' + id + '/approvers', config);
  },
  fetchAccountCustomerApprovers(id) {
    return axios.get('/accounts/' + id + '/customer/approvers', config);
  },
  createAccount(account) {
    return axios.post('/accounts', account).catch((error) => {
      return Promise.reject(error.response.data.parameters);
    });
  },
  createUser(user) {
    return axios.post('/users', user).catch((error) => {
      return Promise.reject(error.response.data.parameters);
    });
  },
  createActionItem(accountId, actionItem) {
    return axios.post('/accounts/' + accountId + '/items', actionItem);
  },
  createAccountComment(accountId, comment) {
    return axios.post('/accounts/' + accountId + '/comments', comment);
  },
  createActionItemComment(actionItemId, comment) {
    return axios.post('/items/' + actionItemId + '/comments', comment);
  },
  saveAccountField(accountId, field) {
    return axios.put('/accounts/' + accountId, field);
  },
  saveAccountCustomerParameters(accountId, data) {
    return axios.put('/accounts/' + accountId + '/customer', data).catch((error) => {
      return error.response.data;
    });
  },
  saveUser(userId, field) {
    return axios.put('/users/' + userId, field);
  },
  archiveUser(userId) {
    return axios.patch('/users/' + userId + '/archived');
  },
  activateUser(userId) {
    return axios.patch('/users/' + userId + '/active');
  },
  uploadAccountFile(accountId, file) {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post('/accounts/' + accountId + '/files', formData, configFilesUpload);
  },
  deleteAccountFile(accountId, fileId) {
    return axios.delete('/accounts/' + accountId + '/files/' + fileId, config);
  },
  downloadAccountFile(accountId, fileId) {
    return axios.get('/accounts/' + accountId + '/files/' + fileId, configFilesDownload);
  },
  saveActionItemField(accountId, actionItemId, field) {
    return axios.put('/accounts/' + accountId + '/items/' + actionItemId, field);
  },
  markActionItemAsResolved(accountId, actionItemId) {
    return axios.patch('/accounts/' + accountId + '/items/' + actionItemId + '/state/resolved');
  },
  uploadActionItemFile(itemId, file) {
    const formData = new FormData();
    formData.append('file', file);
    return axios
      .post('/items/' + itemId + '/files', formData, configFilesUpload)
      .catch(function (err) {
        return Promise.reject(err.response.data.description);
      });
  },
  deleteActionItemFile(itemId, fileId) {
    return axios.delete('/items/' + itemId + '/files/' + fileId, config);
  },
  downloadActionItemFile(itemId, fileId) {
    return axios.get('/items/' + itemId + '/files/' + fileId, configFilesDownload);
  },
  patchAccountType(id, type) {
    return axios.patch('/accounts/' + id + '/type/' + type);
  },
  patchAccountState(id, state, data) {
    return axios.patch('/accounts/' + id + '/state/' + state, data);
  },
  downloadReport() {
    return axios.get('/report/excel', configFilesDownload).then((response) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      const contentDisposition = response.headers['content-disposition'];
      let excelFileName = 'nam-at-risk-accounts-app.xlsx';
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        const fileName = fileNameMatch[1];
        excelFileName = fileName;
      }
      link.setAttribute('download', excelFileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  },
  fetchInsights(queryParam) {
    return axios.get('/dashboard' + queryParam, config);
  },
  saveProxy(userId, payload) {
    return axios.post('/users/' + userId + '/proxy', payload);
  },
  deleteProxy(userId, proxyRuleId) {
    return axios.delete('/users/' + userId + '/proxy/' + proxyRuleId);
  },
};

export default ApiService;
