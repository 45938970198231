<template>
  <div>
    <the-header :is-menu="false" />
    <v-container v-if="!noAccess" fluid class="pt-2 cra-login">
      <v-row justify="space-between" align="center" class="cra-max-content">
        <v-col cols="12" md="7"
          ><h1 class="mb-6 h1-display">NAM at Risk Accounts App</h1>
          <div class="mr-6">
            <p class="mb-6">
              The NAM at Risk Accounts App is an internal tool for Siemens Healthineers employees.
              The app is a digital tool designed to manage the scope of customer relationships from
              at risk of business termination towards customer satisfaction and retention.
            </p>
            <h2 class="mb-3">Go to application</h2>
            <p class="mb-8">Access to the application requires a separate activation.</p>
            <base-button mode="primary" icon="mdi-chevron-right" @click="login">
              Login to application
            </base-button>
            <span class="mx-4">or</span>
            <base-button mode="secondary" @click="sendMailRequestAccess"
              >Request access</base-button
            >
            <h2 class="mt-12 mb-3">Any questions?</h2>
            <p>
              For general questions & feedback feel free to contact
              <a href="#contact_qf" @click="scrollPageTo('contact_qf')">Kristina Dudaeva</a> .<br />
              For any technical questions and support contact
              <a href="#contact_qs" @click="scrollPageTo('contact_qs')">Samuel Kuhn</a>.
            </p>
          </div></v-col
        >
        <v-col cols="12" md="5" class="pb-0" align-self="end">
          <v-img
            v-if="$vuetify.breakpoint.mdAndUp"
            src="@/assets/img-login.svg"
            style="margin-bottom: -1px"
            :max-height="900"
            :min-height="650"
            cover
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else fluid class="pt-2 cra-login">
      <v-row justify="space-between" align="center" class="cra-max-content">
        <v-col cols="12" md="7"
          ><h1 class="mb-6">Sorry, no access</h1>
          <div>
            <p class="mb-8">
              You currently don't have access to the NAM at Risk Accounts App with the currently
              logged in user.
            </p>
            <base-button mode="primary" icon="mdi-chevron-right" @click="sendMailRequestAccess"
              >Request access now</base-button
            >
            <h2 class="mt-12 mb-3">Any questions?</h2>
            <p>
              For general questions & feedback feel free to contact
              <a href="#contact_qf" @click="scrollPageTo('contact_qf')">Kristina Dudaeva</a> .<br />
              For any technical questions and support contact
              <a href="#contact_qs" @click="scrollPageTo('contact_qs')">Samuel Kuhn</a>.
            </p>
          </div></v-col
        >
        <v-col cols="12" md="5" class="pb-0">
          <v-img
            v-if="$vuetify.breakpoint.mdAndUp"
            src="@/assets/img-no-access.svg"
            style="margin-bottom: -21px"
            :max-height="900"
            :min-height="650"
            cover
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="cra-contact pt-12 pb-16 pb-md-10 mb-16">
      <v-row class="cra-max-content">
        <v-col cols="12">
          <h2>Contact</h2>
        </v-col>
      </v-row>
      <v-row class="cra-max-content">
        <v-col cols="12" md="5" class="pr-md-16">
          <p>
            Our contact persons are there for you. Please contact Kristina Dudaeva for all general
            issues - if you encounter technical problems, contact Samuel Kuhn.
          </p></v-col
        >
        <v-col cols="12" md="7">
          <v-row>
            <v-col cols="12" md="6"
              ><p ref="contact_qf" class="mb-1"><strong>Kristina Dudaeva </strong></p>
              <p class="mb-1"><strong>SHS AM NAM FI DX</strong></p>
              <p class="mb-0">Mobil: +1 (914) 398 9029</p>
              <a @click="sendMail('kristina.dudaeva@siemens-healthineers.com')"
                >kristina.dudaeva@siemens-healthineers.com</a
              ></v-col
            >
            <v-col cols="12" md="6" class="pb-16 pb-md-0">
              <p ref="contact_qs" class="mb-1"><strong>Samuel Kuhn</strong></p>
              <p class="mb-1"><strong>GBS B&S DE SOL GMS MSC MSO</strong></p>
              <p class="mb-0">Mobile: +49 173 308 787 5</p>
              <a @click="sendMail('samuel.kuhn@siemens.com')">samuel.kuhn@siemens.com</a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <the-footer />
  </div>
</template>
<script>
import TheHeader from '../components/layout/TheHeader';
import TheFooter from '../components/layout/TheFooter';
import BaseButton from '../components/ui/BaseButton';

export default {
  name: 'LoginPage',
  components: { BaseButton, TheFooter, TheHeader },
  data() {
    return {
      noAccess: false,
    };
  },
  mounted() {
    if (this.$route.query.message === 'Noaccess') {
      this.noAccess = true;
    }
  },
  methods: {
    sendMailRequestAccess() {
      window.location =
        'mailto:kristina.dudaeva@siemens-healthineers.com?subject=Request for access to NAM at Risk Accounts App';
    },
    login() {
      this.$router.push({ path: '/accounts' });
    },
    scrollPageTo(refName) {
      const element = this.$refs[refName];
      const top = element.getBoundingClientRect().y;
      window.scrollTo(0, top);
    },
    sendMail(mail) {
      window.location = 'mailto:' + mail;
    },
  },
};
</script>
<style scoped lang="scss">
@import 'src/scss/globals';
.cra-login {
  background: $gray-tint-8;
}

.cra-contact {
  border-top: 1px solid $gray-tint-2;
  background: $white;
}
</style>
