<template>
  <div>
    <div class="cra-fixed-header">
      <the-header />
      <the-filterbar />
    </div>
    <v-container fluid class="mt-12 cra-max-content cra-content">
      <v-row class="mb-0" justify="space-between" align="center">
        <v-col cols="12" md="9">
          <h2 class="h2-display">Archive ({{ filteredAccounts.length }})</h2>
        </v-col>
      </v-row>

      <v-row v-if="filteredAccounts.length" v-masonry class="mb-16 pb-8" transition-duration="0.3s">
        <v-col
          v-for="account in filteredAccounts"
          :key="account.id"
          v-masonry-tile
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          {{ account.issues }}
          <account-card
            :id="account.id"
            :title="account.name"
            :zone="account.zone"
            :states="account.states"
            :customer-success-lead="
              account.customerSuccessLead
                ? account.customerSuccessLead
                : { id: '2434675', lastName: 'set in dev', firstName: 'Not' }
            "
            :customer-temperature="account.customerTemperature"
            :risk-level="account.riskLevel"
            :last-update="account.lastUpdate"
            :sum-of-revenue="account.sumOfRevenue"
            :open-action-items="account.openActionItems"
            :sold-to="account.contract.soldTo"
            :ship-to="account.contract.shipTo"
            :issues="account.accountIssues"
            @click="showAccountDetail(account.id)"
          />
        </v-col>
      </v-row>
      <v-row v-else class="mb-16 pb-8">
        <v-col cols="12" sm="6" md="4" lg="3"
          ><v-card class="cra-card-add" min-height="400" outlined rounded>
            <v-card-text></v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <router-view />
    <the-footer />
  </div>
</template>
<script>
import TheHeader from '../../components/layout/TheHeader';
import TheFooter from '../../components/layout/TheFooter';
import TheFilterbar from '../../components/layout/TheFilterbar';
import AccountCard from '../../components/accounts/AccountCard';
import { mapGetters } from 'vuex';

export default {
  name: 'AccountArchive',
  components: { AccountCard, TheFilterbar, TheFooter, TheHeader },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      filteredAccounts: 'accounts/getFilteredAccounts',
      hasAccounts: 'accounts/hasAccounts',
    }),
  },
  watch: {
    filteredAccounts() {
      if (typeof this.$redrawVueMasonry === 'function') {
        this.$redrawVueMasonry();
      }
    },
  },
  created() {
    this.loadAccountRessources();
  },
  beforeRouteUpdate(_, __, next) {
    if (typeof this.$redrawVueMasonry === 'function') {
      this.$redrawVueMasonry();
    }
    next();
  },
  methods: {
    showAccountDetail(id) {
      this.$router.push({ path: '/archive/' + id });
    },
    loadAccountRessources() {
      try {
        this.$store.dispatch('zones/loadZones');
      } catch (error) {
        this.error = error.message || 'Some wrong?';
        console.log('Error: ' + this.error);
      }
      try {
        this.$store.dispatch('riskLevels/loadRiskLevels');
      } catch (error) {
        this.error = error.message || 'Some wrong?';
        console.log('Error: ' + this.error);
      }
      try {
        this.$store.dispatch('customerTemperatures/loadCustomerTemperatures');
      } catch (error) {
        this.error = error.message || 'Some wrong?';
        console.log('Error: ' + this.error);
      }
      try {
        this.$store.dispatch('issues/loadIssues');
      } catch (error) {
        this.error = error.message || 'Some wrong?';
        console.log('Error: ' + this.error);
      }
      try {
        this.$store.dispatch('labProducts/loadLabProducts');
      } catch (error) {
        this.error = error.message || 'Some wrong?';
        console.log('Error: ' + this.error);
      }
      try {
        this.$store.dispatch('userRoles/loadUserRoles');
      } catch (error) {
        this.error = error.message || 'Some wrong?';
        console.log('Error: ' + this.error);
      }
    },
  },
};
</script>
