<template>
  <v-container fluid>
    <v-row class="px-0">
      <v-col cols="12" class="pl-0">
        <h5>Add comment</h5>
        <v-textarea
          :value="getCommentInCreation && getCommentInCreation.comment"
          hide-details
          auto-grow
          rows="1"
          label="Start typing"
          @change="(value) => updateField({ fieldName: 'comment', value })"
        />
      </v-col>
    </v-row>
    <v-row class="px-0">
      <v-col cols="12" class="pl-0">
        <v-row>
          <v-col cols="12" md="6">
            <chips-select
              :title="'Account issues'"
              :selected="getCommentInCreation && getCommentInCreation.accountIssues"
              :items="getIssues"
              @change-selected="(value) => updateField({ fieldName: 'accountIssues', value })"
            >
            </chips-select>
          </v-col>
          <v-col cols="12" md="4">
            <chips-select
              :title="'Lab products'"
              :selected="getCommentInCreation && getCommentInCreation.labProducts"
              :items="getLabProducts"
              @change-selected="(value) => updateField({ fieldName: 'labProducts', value })"
            >
            </chips-select>
          </v-col>
          <v-col cols="12" md="2" class="text-right" align-self="end">
            <base-button
              mode="primary"
              :disabled="!isValid"
              class="cra-button"
              icon="mdi-chevron-right"
              @click="submitForm"
            >
              Add comment
            </base-button>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ValidationService from '../../../services/ValidationService';
import { mapGetters } from 'vuex';
import BaseButton from '../../ui/BaseButton';
import ChipsSelect from '../../ui/ChipsSelect';

export default {
  name: 'AccountCommentAdd',
  components: {
    BaseButton,
    ChipsSelect,
  },
  props: {
    accountId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fieldsToValidate: {
        comment: { validators: [ValidationService.hasContent] },
        tags: { value: Array, validators: [ValidationService.hasContent] },
      },
    };
  },
  computed: {
    ...mapGetters({
      getCommentInCreation: 'accountComments/getCommentInCreation',
      getIssues: 'issues/issues',
      getLabProducts: 'labProducts/labProducts',
      getCommentInCreationSavingState: 'accountComments/getCommentInCreationSavingState',
    }),
    isValid() {
      const commentValid = ValidationService.checkFormValidity(
        this.fieldsToValidate,
        this.getCommentInCreation
      );
      return commentValid;
    },
  },
  methods: {
    updateTags() {
      this.fieldsToValidate.tags.value = [
        ...this.getCommentInCreation.accountIssues,
        ...this.getCommentInCreation.labProducts,
      ];

      this.$store.commit('accountComments/patchCommentInCreation', {
        tags: this.fieldsToValidate.tags.value,
      });
    },
    updateField(data) {
      this.$store.commit('accountComments/patchCommentInCreation', {
        [data.fieldName]: data.value,
      });
      if (data.fieldName === 'accountIssues' || data.fieldName === 'labProducts') {
        this.updateTags();
      }
    },
    async submitForm() {
      this.$store.commit('notifications/setBusy', true);
      await this.$store.dispatch('accountComments/createAccountComment', {
        accountId: this.accountId,
      });
      await this.$nextTick();
      if (
        !this.getCommentInCreationSavingState.isSaving &&
        this.getCommentInCreationSavingState.type === 'success'
      ) {
        this.$emit('save-success');
      }
      this.$store.commit('notifications/setBusy', false);
    },
  },
};
</script>
