<template>
  <base-modal @close="close">
    <template v-slot:header>
      <v-row v-if="isChipIsOn">
        <v-col cols="12" class="pb-0">
          <div class="cra-chip-list">
            <span v-for="(item, index) in getAccountToEditStates" :key="item + '_' + index">
              <v-chip
                v-if="item.length"
                small
                class="cra-chip mr-2 mb-2"
                :color="chipBgColor(item)"
                :text-color="chipTextColor(item)"
              >
                {{ item }}
              </v-chip>
            </span>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="$vuetify.breakpoint.mdAndUp" class="py-0" align="end">
        <v-col cols="12" md="9" class="pt-0">
          <v-text-field
            v-model="fieldsToValidate.name.value"
            :disabled="isInArchive || !!PermissionService.hasReadOnlyRestrict()"
            hide-details
            class="h3"
            height="50"
            @change="(value) => updateField({ fieldName: 'name', value })"
          />
        </v-col>
        <v-col cols="12" md="3" class="pt-0">
          <v-text-field
            v-model="fieldsToValidate.city.value"
            hide-details
            :disabled="isInArchive || !!PermissionService.hasReadOnlyRestrict()"
            class="h5"
            height="40"
            @change="(value) => updateField({ fieldName: 'city', value })"
          />
        </v-col>
      </v-row>
    </template>
    <v-form>
      <v-container fluid class="pt-0 px-6">
        <v-row v-if="!$vuetify.breakpoint.mdAndUp" class="py-0 px-0" align="end">
          <v-col cols="12" md="8">
            <v-text-field
              v-model="fieldsToValidate.name.value"
              :disabled="isInArchive || !!PermissionService.hasReadOnlyRestrict()"
              hide-details
              class="h3"
              height="50"
              @change="(value) => updateField({ fieldName: 'name', value })"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="fieldsToValidate.city.value"
              hide-details
              :disabled="isInArchive || !!PermissionService.hasReadOnlyRestrict()"
              class="h5"
              height="40"
              @change="(value) => updateField({ fieldName: 'city', value })"
            />
          </v-col>
        </v-row>
        <v-row
          v-if="isAccountApprovalComponent || isCustomerApprovalComponent"
          class="px-0 pb-3"
          align="end"
        >
          <v-col cols="12">
            <account-approval v-if="isAccountApprovalComponent">
              <h5 class="mb-8">
                <v-avatar size="30" class="mr-2 cra-avatar">
                  <v-icon class="cra-btn-close" small>mdi-exclamation-thick</v-icon></v-avatar
                >Initial Approval Required
              </h5>
              <p class="p-tiny">
                Dear App User, this account has been submitted for approval. Please be patient until
                the Customer Success Lead (CSL) and the Customer Success Director (CSD) approve the
                account. Automated reminder notifications will be send out 24 and 48 hours after the
                submission for approval.
              </p>
            </account-approval>
            <account-customer-approval v-if="isCustomerApprovalComponent">
              <h5 class="mb-8">
                <v-avatar size="30" class="mr-2 cra-avatar">
                  <v-icon class="cra-btn-close" small>mdi-exclamation-thick</v-icon></v-avatar
                >Change Request: Change of risk level / customer temperature
              </h5>
              <p class="p-tiny">
                A change has been submitted for approval. Please be patient until the Customer
                Success Lead (CSL) and the Customer Success Director (CSD) approve the change.
                Automated reminder notifications will be send out 24 and 48 hours after the
                submission for approval.
              </p>
            </account-customer-approval>
          </v-col>
        </v-row>
        <v-row class="px-0 pb-0" align="end">
          <v-col cols="12" md="4">
            <styled-select
              :label="'Risk level'"
              :items="getRiskLevels"
              :selected="customerParameters.riskLevel.value"
              :item-class="'cra-select-item-risk-level'"
              :select-class="'cra-select-risk-level'"
              :disabled="
                customerParameters.riskLevel.disabled || !!PermissionService.hasReadOnlyRestrict()
              "
              @change-selected="
                (value) => updateCustomerParameters({ fieldName: 'riskLevel', value })
              "
            >
            </styled-select>
          </v-col>
          <v-col cols="12" md="4">
            <styled-select
              :label="'Customer temperature'"
              :items="getCustomerTemperatures"
              :selected="customerParameters.customerTemperature.value"
              :item-class="'cra-select-item-customer-temperature'"
              :select-class="'cra-select-customer-temperature'"
              :disabled="
                customerParameters.customerTemperature.disabled ||
                !!PermissionService.hasReadOnlyRestrict()
              "
              @change-selected="
                (value) => updateCustomerParameters({ fieldName: 'customerTemperature', value })
              "
            >
            </styled-select>
          </v-col>
          <v-col cols="12" md="2">
            <v-select
              v-model="fieldsToValidate.zone.value"
              :items="getZones"
              label="Zone"
              :disabled="isInArchive || !!PermissionService.hasReadOnlyRestrict()"
              filled
              dense
              hide-details
              @change="(value) => updateZone({ fieldName: 'zone', value })"
            />
          </v-col>
          <v-col cols="12" md="2"
            ><p class="p-tiny my-0">Risk account since</p>
            <p class="p-lead my-0">{{ riskAccountSince }}</p></v-col
          >
        </v-row>
        <v-row v-if="isPendingChange" class="pl-4 pr-4 pr-md-16 pb-0 mt-0">
          <v-col cols="12" md="8">
            <v-alert dense outlined type="error" icon="mdi-alert-octagon-outline" class="cra-alert">
              {{ alertText.CustomerParametersChanged }}
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
      <v-divider class="mt-8" />
      <action-items
        v-if="isActionItems"
        :account-id="account.id"
        :account-state-list="account.states"
        :item-list="openActionItems"
        :is-create-allowed="isActionItemCreateAllowed"
        :is-read-only="!!PermissionService.hasReadOnlyRestrict()"
        @change-item-list="loadActionItems"
      >
      </action-items>
      <v-divider />
      <account-description
        :account-id="account.id"
        :contract-data="account.contract"
        :is-editable="!isInArchive && !PermissionService.hasReadOnlyRestrict()"
      ></account-description>
      <v-divider />
      <account-comments
        v-if="isComments"
        :account-id="account.id"
        :comment-list="comments"
        :account-state-list="account.states"
        :is-create-allowed="isCommentCreateAllowed"
      >
      </account-comments>
      <v-divider />
      <core-team
        :zone="currentZone"
        :defined-members="coreTeam"
        :is-editable="!isInArchive && !PermissionService.hasReadOnlyRestrict()"
        @select-team-member="updateCoreTeam"
      ></core-team>
      <v-divider />
      <action-items-resolved
        v-if="isActionItems"
        :account-id="account.id"
        :item-list="resolvedActionItems"
      ></action-items-resolved>
      <v-divider />
      <account-history v-if="isHistoryItems" :item-list="historyItems"></account-history>
      <v-divider />
      <account-management
        v-if="!PermissionService.hasReadOnlyRestrict()"
        :account-id="account.id"
      ></account-management>
      <approval-hint-dialog
        v-if="isApprovalHintDialogOn"
        :selected-risk-level="customerParameters.riskLevel"
        :selected-customer-temperature="customerParameters.customerTemperature"
        @cancel="cancelApprovalHintDialog"
        @accept="saveApprovalDialog"
      ></approval-hint-dialog>
      <base-confirm-dialog
        v-if="isConfirmZoneDialogOn"
        @cancel="cancelConfirmZoneDialog"
        @accept="saveZone"
      >
        <p>
          Please be aware: This will effect core team assignments. You will have to re-assign all
          team members. Are you sure to continue?
        </p>
      </base-confirm-dialog>
    </v-form>
  </base-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import removeSpacesLowerCase from '../../../helpers/removeSpacesLowerCase';
import isExpired from '../../../helpers/isExpired';
import ActionItems from '../actionItems/ActionItemsOpen';
import AccountDescription from './AccountDescription';
import AccountComments from '../accountComments/AccountComments';
import CoreTeam from './CoreTeam';
import AccountHistory from './AccountHistory';
import ValidationService from '../../../services/ValidationService';
import ApprovalHintDialog from './ApprovalHintDialog';
import StyledSelect from '../../ui/StyledSelect';
import { AccountStates, AccountTypes } from '../../../store/modules/accounts';
import ActionItemsResolved from '../actionItems/ActionItemsResolved';
import AccountApproval from './AccountApproval';
import AccountCustomerApproval from './AccountCustomerApproval';
import AccountManagement from './AccountManagement';
import BaseConfirmDialog from '../../ui/BaseConfirmDialog';
import PermissionService from '../../../services/PermisssionService';
import BaseModal from '../../ui/BaseModal';

export default {
  name: 'AccountEdit',
  components: {
    BaseConfirmDialog,
    AccountManagement,
    AccountApproval,
    ActionItemsResolved,
    StyledSelect,
    AccountHistory,
    CoreTeam,
    AccountComments,
    AccountDescription,
    ActionItems,
    ApprovalHintDialog,
    AccountCustomerApproval,
    BaseModal,
  },
  emits: ['close'],
  data() {
    return {
      account: Object,
      fieldsToValidate: {
        name: { value: '', validators: [ValidationService.hasContent] },
        city: { value: '', validators: [ValidationService.hasContent] },
        zone: { value: '', validators: [ValidationService.hasContent] },
      },
      isApprovalHintDialogOn: false,
      customerParameters: {
        riskLevel: { value: '', disabled: false },
        customerTemperature: { value: '', disabled: false },
      },
      alertText: {
        CustomerParametersChanged:
          'A change of the risk level / customer temperature is currently pending. No further adjustment in this field can be made, until the change is approved.',
      },
      isActionItems: false,
      openActionItems: Object,
      resolvedActionItems: Object,
      comments: Object,
      isPendingChange: false,
      isOnWatchlist: false,
      isInArchive: false,
      isComments: false,
      isConfirmZoneDialogOn: false,
      coreTeam: Object,
      currentZone: '',
      isActionItemCreateAllowed: true,
      isCommentCreateAllowed: true,
      isHistoryItems: false,
      historyItems: Object,
      PermissionService,
    };
  },
  computed: {
    ...mapGetters({
      getZones: 'zones/zones',
      getRiskLevels: 'riskLevels/riskLevels',
      getCustomerTemperatures: 'customerTemperatures/customerTemperatures',
      getIssues: 'issues/issues',
      getAccountToEdit: 'accounts/getAccountToEdit',
      getAccountToEditStates: 'accounts/getAccountToEditStates',
      getAccountToEditType: 'accounts/getAccountToEditType',
      getAccountCustomerParameters: 'accounts/getAccountCustomerParameters',
      getOpenActionItems: 'actionItems/getOpenActionItems',
      getResolvedActionItems: 'actionItems/getResolvedActionItems',
      getComments: 'accountComments/getComments',
      getLoggedInUser: 'users/getLoggedInUser',
      getHistoryItems: 'accountHistory/getHistoryItems',
      getAccountToEditSavingState: 'accounts/getAccountToEditSavingState',
    }),
    riskAccountSince() {
      return new Date(this.account.riskAccountSince).toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    },
    isChipIsOn() {
      if (this.getAccountToEdit.states.length) {
        if (
          this.getAccountToEdit.states.length === 1 &&
          this.getAccountToEdit.states.includes('')
        ) {
          return false;
        }
        return !(
          this.getAccountToEdit.states.includes(AccountStates.rfpDue) &&
          isExpired(this.getAccountToEdit.rfpDueDate)
        );
      } else {
        return false;
      }
    },
    isAccountApprovalComponent() {
      return this.getAccountToEdit.states.includes(AccountStates.pendingApproval);
    },
    isCustomerApprovalComponent() {
      return this.getAccountToEdit.states.includes(AccountStates.pendingChange);
    },
  },
  watch: {
    getComments() {
      this.comments = { ...this.getComments };
    },
    getAccountToEditStates() {
      this.setFieldStates();
      this.setCustomerParametersFormFields();
    },
    getAccountToEditType() {
      this.setFieldStates();
      this.setCustomerParametersFormFields();
    },
    getAccountToEdit() {
      this.loadHistoryItems();
    },
  },
  created() {
    this.setFieldStates();
    this.loadFormFields();
    this.loadActionItems();
    this.loadComments();
    this.loadHistoryItems();
    this.setCurrentZone();
    this.setCoreTeam();
  },
  methods: {
    chipBgColor(value) {
      return 'bg_' + removeSpacesLowerCase(value);
    },
    chipTextColor(value) {
      return 'txt_' + removeSpacesLowerCase(value);
    },
    setFieldStates() {
      this.isPendingChange = this.getAccountToEdit.states.includes(AccountStates.pendingChange);
      this.isPendingApproval = this.getAccountToEdit.states.includes(AccountStates.pendingApproval);
      this.isOnWatchlist = this.getAccountToEdit.type === AccountTypes.watchlist;
      this.isInArchive = this.getAccountToEdit.type === AccountTypes.archived;
      if (this.isInArchive || this.isPendingChange || this.isPendingApproval) {
        this.isActionItemCreateAllowed = false;
      } else {
        this.isActionItemCreateAllowed = true;
      }
    },
    setCurrentZone() {
      this.currentZone = this.getAccountToEdit.zone;
    },
    setCoreTeam() {
      this.coreTeam = this.account.coreTeam;
    },
    loadActionItems() {
      this.$store.commit('notifications/setBusy', true);
      this.$store
        .dispatch('actionItems/loadActionItems', { id: this.account.id })
        .then(() => {
          this.openActionItems = { ...this.getOpenActionItems };
          this.resolvedActionItems = { ...this.getResolvedActionItems };
          this.isActionItems = true;
          this.$nextTick();
        })
        .catch((e) => {
          this.$store.commit('notifications/setSnackbar', {
            message: 'Load action items failed! ' + e.toString(),
            type: 'error',
          });
          this.$nextTick();
        });
      this.$store.commit('notifications/setBusy', false);
    },
    loadHistoryItems() {
      this.$store.commit('notifications/setBusy', true);
      this.$store
        .dispatch('accountHistory/loadHistoryItems', { id: this.account.id })
        .then(() => {
          this.historyItems = { ...this.getHistoryItems };
          this.isHistoryItems = true;
          this.$nextTick();
        })
        .catch((e) => {
          this.$store.commit('notifications/setSnackbar', {
            message: 'Load history failed! ' + e.toString(),
            type: 'error',
          });
          this.$nextTick();
        });
      this.$store.commit('notifications/setBusy', false);
    },
    loadComments() {
      this.$store.commit('notifications/setBusy', true);
      this.$store
        .dispatch('accountComments/loadAccountComments', { id: this.account.id })
        .then(() => {
          this.comments = { ...this.getComments };
          this.isComments = true;
          this.$nextTick();
        })
        .catch((e) => {
          this.$store.commit('notifications/setSnackbar', {
            message: 'Load comments failed! ' + e.toString(),
            type: 'error',
          });
          this.$nextTick();
        });
      if (this.isInArchive || !!PermissionService.hasReadOnlyRestrict()) {
        this.isCommentCreateAllowed = false;
      }
      this.$store.commit('notifications/setBusy', false);
    },
    loadFormFields() {
      this.account = this.getAccountToEdit; //todo delete
      Object.keys(this.fieldsToValidate).find((key) => {
        this.fieldsToValidate[key].value = this.getAccountToEdit[key];
      });
      this.setCustomerParametersFormFields();
    },
    setCustomerParametersFormFields() {
      Object.keys(this.customerParameters).find((key) => {
        this.customerParameters[key].value = this.getAccountToEdit[key];
        if (
          this.isPendingChange ||
          this.isPendingApproval ||
          this.isOnWatchlist ||
          this.isInArchive
        ) {
          this.customerParameters[key].disabled = true;
        } else {
          this.customerParameters[key].disabled = false;
        }
      });
    },
    updateFormField(fieldName) {
      this.fieldsToValidate[fieldName].value = this.getAccountToEdit[fieldName];
    },
    getSelectFieldClass(val) {
      return removeSpacesLowerCase(val);
    },
    updateField(data) {
      this.$store.dispatch('accounts/setAccountField', {
        fieldName: data.fieldName,
        value: data.value,
        id: this.getAccountToEdit.id,
        validators: this.fieldsToValidate[data.fieldName].validators,
      });
      this.updateFormField(data.fieldName);
    },
    updateCustomerParameters(data) {
      this.customerParameters[data.fieldName].value = data.value;
      this.isApprovalHintDialogOn = true;
    },
    updateCoreTeam(data) {
      const roleKey = data.role.replace(/ /g, '');
      this.$store.dispatch('accounts/setAccountCoreTeam', {
        coreTeamMember: data.user,
        role: roleKey,
        accountId: this.getAccountToEdit.id,
      });
    },
    cancelApprovalHintDialog() {
      this.setCustomerParametersFormFields();
      this.isApprovalHintDialogOn = false;
    },
    async saveApprovalDialog(data) {
      this.$store.commit('notifications/setBusy', true);
      await this.$store
        .dispatch('accounts/saveCustomerParameters', {
          message: data.message,
          riskLevel: data.riskLevel,
          customerTemperature: data.customerTemperature,
          id: this.getAccountToEdit.id,
        })
        .then(() => this.$nextTick());
      this.$store.commit('notifications/setSnackbar', {
        type: this.getAccountToEditSavingState.type,
        message: this.getAccountToEditSavingState.message,
      });
      this.$store.commit('notifications/setBusy', false);
      this.setCustomerParametersFormFields();
      this.isApprovalHintDialogOn = false;
    },
    updateZone() {
      this.isConfirmZoneDialogOn = true;
    },
    cancelConfirmZoneDialog() {
      this.fieldsToValidate.zone.value = this.getAccountToEdit.zone;
      this.isConfirmZoneDialogOn = false;
    },
    saveZone() {
      this.$store.dispatch('accounts/resetCoreTeamOnUpdateZone', {
        zone: this.fieldsToValidate.zone.value,
      });
      this.updateField({ fieldName: 'zone', value: this.fieldsToValidate.zone.value });
      this.currentZone = this.fieldsToValidate.zone.value;
      this.isConfirmZoneDialogOn = false;
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
<style scoped lang="scss">
.cra-chip-list {
  /*position: absolute;
  left: 1.5rem;
  top: 1.5rem;*/
}
.p-tiny {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
}
</style>
