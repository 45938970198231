<template>
  <v-btn
    v-if="!link"
    :color="mode"
    rounded
    :outlined="mode !== 'primary'"
    :icon="onlyIcon"
    depressed
    large
    :disabled="disabled"
    class="cra-btn"
    :class="classes"
    v-on="$listeners"
  >
    <v-icon v-if="icon" :left="!onlyIcon" :center="onlyIcon" :size="iconSize" :color="iconColor">
      {{ icon }}
    </v-icon>
    <slot />
  </v-btn>
  <router-link v-else :to="to">
    <slot />
  </router-link>
</template>
<script>
export default {
  name: 'BaseButton',
  props: {
    mode: {
      type: String,
      required: false,
      default: null,
    },
    link: {
      type: Boolean,
      required: false,
      default: false,
    },
    to: {
      type: String,
      required: false,
      default: '',
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
    iconSize: {
      type: String,
      required: false,
      default: '25px',
    },
    iconColor: {
      type: String,
      required: false,
      default: 'white',
    },
    onlyIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    classes: {
      type: Array,
      required: false,
      default: null,
    },
  },
};
</script>
