import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const getInitialAccountCommentInCreationState = () => ({
  comment: '',
  savingState: {
    isSaving: false,
    type: '',
    message: '',
  },
  accountIssues: [],
  labProducts: [],
  tags: [],
});

export const getInitialAccountCommentState = () => ({
  lastFetch: null,
  comments: [],
  filters: {
    accountIssues: [],
    labProducts: [],
  },
  commentInCreation: null,
});

export default {
  namespaced: true,
  state() {
    return getInitialAccountCommentState();
  },
  mutations,
  actions,
  getters,
};
