<template>
  <div :roleName="roleName" class="cra-select-user">
    <div
      class="cra-select-user-data"
      :class="!hasItemsToSelect ? 'disabled' : ''"
      @click="openSelectField"
    >
      <v-avatar class="cra-avatar" size="40">
        <span v-if="selectedItem.initials">{{ selectedItem.initials }}</span>
        <v-icon v-else small>mdi-plus</v-icon>
      </v-avatar>
      <div v-if="!narrow" class="cra-select-user-content pl-4">
        <p v-if="selectedItem.name" class="mb-0 p-tiny">
          <strong>{{ selectedItem.name }}</strong>
        </p>
        <p v-else class="mb-0 p-tiny"><strong>Please select</strong></p>
        <p class="mb-0 p-tiny">{{ roleName }}</p>
      </div>
    </div>
    <v-autocomplete
      v-if="hasItemsToSelect"
      ref="autocompleteField"
      v-model="selectedItem"
      class="cra-autocomplete mt-2"
      :items="itemList"
      hide-details
      filled
      color="blue-grey lighten-2"
      item-text="name"
      item-value="id"
      append-icon=""
      :class="!isFieldOn ? 'hidden' : ''"
      @change="(value) => setSelectedItem(value)"
      @blur="isFieldOn = false"
    >
      <template v-slot:selection="data">
        <span v-bind="data.attrs" :input-value="data.selected" @click="data.select">
          {{ data.item.name }}
        </span>
      </template>
      <template v-slot:item="data">
        <template>
          <v-list-item-content>
            <v-list-item-title>{{ data.item.name }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
export default {
  name: 'UserSelect',
  emits: ['change-user'],
  props: {
    items: {
      type: Array,
      required: true,
    },
    roleName: {
      type: String,
      required: true,
    },
    preselected: {
      type: Object,
      required: false,
      default: () => {},
    },
    narrow: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      hasItemsToSelect: false,
      isUpdating: false,
      selectedItem: {},
      itemList: [],
      isFieldOn: false,
    };
  },

  watch: {
    items() {
      this.updateItems();
    },
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
    isFieldOn() {
      if (this.isFieldOn === true) {
        this.$nextTick(() => {
          this.$refs.autocompleteField.focus();
          this.$refs.autocompleteField.isMenuActive = true;
        });
      } else {
        this.$refs.autocompleteField.blur();
        this.$refs.autocompleteField.isMenuActive = false;
      }
    },
  },
  mounted() {
    this.updateItems();
  },

  methods: {
    openSelectField() {
      if (this.isEditable) {
        this.isFieldOn = !this.isFieldOn;
      }
    },
    updateItems() {
      if (this.preselected) {
        this.selectedItem = { ...this.preselected };
      } else {
        this.selectedItem = {};
      }

      this.hasItemsToSelect = this.items.length > 1;
      this.itemList = [...this.items];
      if (this.itemList.length === 1 && !this.preselected) {
        this.selectedItem = { ...this.itemList[0] };
        this.$emit('change-user', { id: this.selectedItem.id, role: this.roleName });
      }
    },
    setSelectedItem(val) {
      this.isFieldOn = false;
      this.selectedItem = { ...this.itemList.find((item) => item.id === val) };
      this.$emit('change-user', { id: this.selectedItem.id, role: this.roleName });
    },
  },
};
</script>
<style scoped lang="scss">
@import '../../scss/globals';
.cra-select-user {
  position: relative;
}
.cra-select-user-data {
  display: flex;
  position: relative;
  align-items: center;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
.cra-autocomplete {
  position: absolute;
  left: 0;
  right: 0;
  z-index: +1;
  &.hidden {
    display: none;
  }
}
.cra-select-user-content {
  p {
    color: $gray;
    strong {
      color: $gray-shade-4;
    }
  }
}
</style>
