import { getInitialSnackbarState } from './index';

export default {
  setSnackbar(state, payload) {
    state.snackbar = { ...state.snackbar, ...payload };
    state.snackbar.visible = true;
    if (state.snackbar.visible) {
      state.isBusy = false;
    }
  },
  resetSnackbar(state) {
    state.snackbar = { ...getInitialSnackbarState() };
  },
  setBusy(state, payload) {
    state.isBusy = payload;
  },
};
