import isInPeriodOfTime from '../../../helpers/isInPeriodOfTime';

export default {
  users(state) {
    return state.users;
  },
  getFilteredUsers(state, _, __, rootGetters) {
    const activeUser = rootGetters['filters/getActiveUser'];
    const zones = rootGetters['filters/getActiveZones'];
    const userRoles = rootGetters['filters/getActiveUserRoles'];
    const search = rootGetters['filters/getSearchTerm'];
    return state.users
      .filter((user) => activeUser === user.active)
      .filter((user) => {
        if (zones.length) {
          return user.zones.some((uzone) => {
            return zones.includes(uzone);
          });
        }
        return true;
      })
      .filter((user) => {
        if (userRoles.length) {
          return user.roles.some((urole) => {
            return userRoles.includes(urole);
          });
        }
        return true;
      })
      .filter((user) => {
        if (search.length) {
          const fullName = user.firstName.toLowerCase() + ' ' + user.lastName.toLowerCase();
          if (fullName.indexOf(search.toLowerCase()) >= 0) {
            return user;
          }
          return false;
        }
        return true;
      });
  },
  hasUsers(state) {
    return state.users && state.users.length > 0;
  },
  usersByZone: (state) => (zone) => {
    return state.users.filter((user) => user.zones.includes(zone));
  },
  usersByRole: (state) => (role) => {
    return state.users.filter((user) => user.roles.includes(role));
  },
  userById: (state) => (id) => {
    return state.users.find((user) => user.id === id);
  },
  allActiveUsers(state) {
    return state.users.filter((user) => user.active);
  },
  getLoggedInUser(state) {
    return state.loggedInUser;
  },
  getUserInCreation(state) {
    return state.userInCreation;
  },
  getUserInCreationSavingState(state) {
    return state.userInCreation.savingState;
  },
  getUserToEdit(state) {
    return state.userToEdit;
  },
  getUserToEditSavingState(state) {
    return state.userToEdit.savingState;
  },
  getUserToEditProxySettingsToSave(state) {
    return state.userToEdit.proxySettingsToSave;
  },
  getUserToEditHasProxy(state) {
    return state.userToEdit.hasProxy;
  },
  getUserToEditIsProxy(state) {
    return state.userToEdit.isProxy;
  },
  getAvailableProxyUser: (state) => (periodOfTime) => {
    const periodSort = periodOfTime.sort(function (a, b) {
      return new Date(a) - new Date(b);
    });
    let [periodFrom, periodTo] = periodSort;
    periodFrom = new Date(periodFrom).toISOString();
    periodTo = new Date(periodTo).toISOString();
    return state.users
      .filter((user) => user.active)
      .filter((user) => user.id !== state.userToEdit.id)
      .filter((user) => {
        if (user.hasProxy.length) {
          return !isInPeriodOfTime(
            [periodFrom, periodTo],
            [user.hasProxy[0].from, user.hasProxy[0].to]
          );
        }
        return true;
      })
      .filter((user) => {
        if (user.isProxy.length) {
          return !isInPeriodOfTime(
            [periodFrom, periodTo],
            [user.isProxy[0].from, user.isProxy[0].to]
          );
        }
        return true;
      });
  },
  shouldUpdate(state) {
    const lastFetch = state.lastFetch;
    if (!lastFetch) {
      return true;
    }

    const currentTimestamp = new Date().getTime();

    return (currentTimestamp - lastFetch) / 1000 > 60;
  },
};
