export default (first, last) => {
  if (first && last) {
    return first[0].toUpperCase() + last[0].toUpperCase();
  }
  if (first) {
    return first[0].toUpperCase();
  }
  if (last) {
    return last[0].toUpperCase();
  }
  return null;
};
