import Vue from 'vue';
import * as msal from '@azure/msal-browser';

const DEFAULT_CALLBACK = () => {
  window.location.assign(window.location.origin);
};

let instance;

// Add here the scopes that you would like the user to consent during sign-in
export const loginRequest = {
  scopes: ['openid'],
};

// Add here the scopes to request when obtaining an access token for shs services
export const tokenRequest = {
  scopes: ['api://shs-customer-risk/Access.ALL'],
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};

export const isMSALUserLoggedIn = () => instance.isUserLoggedIn();

export const authenticateMSALUser = (goToRoute) => instance.authUser(goToRoute);

export const getMSALAccessToken = () => instance.getAccessToken();

/** Creates an instance of the Auth SDK. If one has already been created, it returns that instance */
export const useAuth = ({
  onLoginCallback = DEFAULT_CALLBACK,
  onErrorCallback = DEFAULT_CALLBACK,
  ...options
}) => {
  if (instance) return instance;

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        msalClient: null,
        username: '',
      };
    },
    /** Use this lifecycle method to instantiate the msal client */
    created() {
      // Config object to be passed to Msal on creation.
      // For a full list of msal.js configuration parameters, visit
      // https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html
      this.msalClient = new msal.PublicClientApplication({
        auth: {
          clientId: options.clientId,
          authority: `https://login.microsoftonline.com/${options.tenantId}`,
          redirectUri: `${window.location.origin}/auth-loading`,
          navigateToLoginRequestUrl: false,
          postLogoutRedirectUri: `${window.location.origin}`,
        },
        cache: {
          cacheLocation: 'sessionStorage', // This configures where your cache will be stored
          storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        },
      });

      // Register Callbacks for Redirect flow
      this.msalClient
        .handleRedirectPromise()
        .then(this.authRedirectCallBack)
        .catch(() => {
          console.warn('redirect to microsoft azure failed');
          onErrorCallback();
        });

      console.log('msal client instantiated, clientId:', options.clientId);
    },
    methods: {
      async getAccessToken() {
        const tokenResponse = await this.acquireToken();
        return tokenResponse.accessToken;
      },
      async acquireToken(currentAccount) {
        const account = currentAccount || this.getAccount();
        if (account) {
          const request = tokenRequest;
          request.account = account;
          try {
            return await this.msalClient.acquireTokenSilent(request);
          } catch (acquireTokenSilentError) {
            console.log('Silent token acquisition fails. acquiring token using redirect');
            // fallback to interaction when silent call fails
            return this.msalClient.acquireTokenRedirect({
              ...request,
              state: window.location.pathname,
            });
          }
        }
        return this.authUser(window.location.pathname);
      },
      authUser(route) {
        return this.msalClient.loginRedirect({ ...loginRequest, state: route });
      },
      signOut() {
        const logoutRequest = {
          account: this.msalClient.getAccountByUsername(this.username),
        };
        if (localStorage.getItem('currentUser')) {
          localStorage.removeItem('currentUser');
        }
        return this.msalClient.logout(logoutRequest);
      },
      getAccount() {
        if (this.username) {
          return this.msalClient.getAccountByUsername(this.username);
        }
        const currentAccounts = this.msalClient.getAllAccounts();
        if (currentAccounts !== null && currentAccounts.length === 1) {
          this.username = currentAccounts[0].username;
          return currentAccounts[0];
        }
        return false;
      },
      authRedirectCallBack(response) {
        if (response) {
          this.username = response.account.username;
          onLoginCallback(response.state);
        }
      },
      isUserLoggedIn() {
        const account = this.getAccount();
        if (account) {
          return this.msalClient.acquireTokenSilent({ ...tokenRequest, account });
        }
        return Promise.reject(Error('msal user account doesnt exist'));
      },
    },
  });

  return instance;
};

export const AuthPlugin = {
  install(VueInst, options) {
    VueInst.prototype.$auth = useAuth(options); // eslint-disable-line no-param-reassign
  },
};
