<template>
  <v-container fluid class="pr-0">
    <v-row class="px-0">
      <v-col cols="12" class="pl-0">
        <h4>Add comment</h4>
        <v-textarea
          :value="getCommentInCreation && getCommentInCreation.comment"
          hide-details
          auto-grow
          rows="1"
          label="Start typing"
          @change="(value) => updateField({ fieldName: 'comment', value })"
        />
      </v-col>
    </v-row>
    <v-row class="px-0">
      <v-col cols="12" style="text-align: right">
        <base-button
          mode="primary"
          :disabled="!isValid"
          class="cra-button"
          icon="mdi-chevron-right"
          @click="submitForm"
        >
          Add comment
        </base-button>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ValidationService from '../../../services/ValidationService';
import { mapGetters } from 'vuex';
import BaseButton from '../../ui/BaseButton';

export default {
  name: 'ActionItemComment',
  components: {
    BaseButton,
  },
  props: {
    actionItemId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fieldsToValidate: {
        comment: { validators: [ValidationService.hasContent] },
      },
    };
  },
  computed: {
    ...mapGetters({
      getCommentInCreation: 'actionItemComments/getCommentInCreation',
      getCommentInCreationSavingState: 'actionItemComments/getCommentInCreationSavingState',
    }),
    isValid() {
      const commentValid = ValidationService.checkFormValidity(
        this.fieldsToValidate,
        this.getCommentInCreation
      );
      return commentValid;
    },
  },
  methods: {
    updateField(data) {
      this.$store.commit('actionItemComments/patchCommentInCreation', {
        [data.fieldName]: data.value,
      });
    },
    async submitForm() {
      this.$store.commit('notifications/setBusy', true);
      await this.$store.dispatch('actionItemComments/createActionItemComment', {
        actionItemId: this.actionItemId,
      });
      await this.$nextTick();
      if (
        !this.getCommentInCreationSavingState.isSaving &&
        this.getCommentInCreationSavingState.type === 'success'
      ) {
        this.$emit('save-success');
      }
      this.$store.commit('notifications/setBusy', false);
    },
  },
};
</script>
