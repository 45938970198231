const ValidationService = {
  hasContent(val) {
    if (val && val instanceof Date) {
      return true;
    }
    if (val && isObject(val)) {
      return Boolean(Object.keys(val).length);
    }
    if (val && Number.isInteger(val)) {
      return true;
    }
    return Boolean(val && val.length);
  },
  isTrue(val) {
    return val && val === true;
  },
  isEmail(val) {
    const re = RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
    return re.test(val);
  },
  isLink(val) {
    const re = RegExp(
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
    );
    return re.test(val);
  },
  isAlreadySetInArray(array, val) {
    return array.includes(val);
  },
  isSapNumber(val) {
    return val.indexOf('400') === 0 && val.length > 3;
  },
  checkFormValidity(fieldsToValidate, data) {
    return Object.keys(fieldsToValidate).every((key) => {
      const currentField = fieldsToValidate[key];
      const validateCurrentField = () =>
        currentField.validators.every((validator) => {
          return validator(data[key]);
        });
      const shouldValidateField = () =>
        currentField.conditionalValidators.every((validator) => {
          return validator(data[currentField.conditionalFieldName]);
        });

      if (!currentField.conditionalFieldName) {
        return validateCurrentField();
      }

      if (shouldValidateField()) {
        return validateCurrentField();
      }
      return true;
    });
  },
};

function isObject(obj) {
  return !Array.isArray(obj) && obj === Object(obj);
}

export default ValidationService;
