import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const getInitialAccountInCreationState = () => ({
  name: '',
  city: '',
  zone: '',
  riskLevel: '',
  customerTemperature: '',
  accountIssues: [],
  labProducts: [],
  coreTeam: {},
  description: '',
  contract: {
    active: true,
    sapNumber: '400',
  },
  savingState: {
    isSaving: false,
    type: '',
    message: '',
  },
  files: [],
});

export const getInitialAccountEditState = () => ({
  name: '',
  city: '',
  zone: '',
  riskLevel: '',
  customerTemperature: '',
  accountIssues: [],
  labProducts: [],
  additionalLink: [],
  coreTeam: {},
  description: '',
  contract: {
    active: true,
  },
  savingState: {
    isSaving: false,
    type: '',
    message: '',
  },
  type: '',
  states: [],
  changingStateFields: {},
});

export const AccountStates = {
  pendingApproval: 'Pending approval',
  pendingChange: 'Pending change',
  accountLost: 'Account lost',
  contractRenewed: 'Contract renewed',
  rfpDue: 'RFP Due',
  stateless: '',
  declined: 'Declined',
};

export const AccountTypes = {
  archived: 'Archived',
  watchlist: 'Watchlist',
  submitted: 'Submitted',
  active: 'Active',
};

export const getInitialAccountState = () => ({
  lastFetch: null,
  accounts: [],
  accountInCreation: null,
  accountToEdit: null,
});

export default {
  namespaced: true,
  state() {
    return getInitialAccountState();
  },
  mutations,
  actions,
  getters,
};
