<template>
  <v-container fluid class="pt-5">
    <v-row class="pl-4 pr-4 pr-md-16">
      <v-col cols="2">
        <h4 class="mb-0 pb-0">Proxy</h4>
      </v-col>
      <v-col cols="10">
        <base-switch
          class="mt-1"
          :is-active="hasProxy"
          :label="'Activate proxy'"
          @change-field="(value) => updateProxy(value)"
        />
      </v-col>
    </v-row>
    <v-row v-if="isExpanded" class="pl-4 pr-4 pb-5 pr-md-16">
      <v-col cols="6">
        <v-text-field
          v-if="hasProxy"
          v-model="dateRangeText"
          label="Period of time"
          append-icon="mdi-calendar"
          filled="filled"
          hide-details
          disabled
        />
        <v-menu
          v-else
          v-model="menu"
          :close-on-content-click="false"
          nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              label="Period of time"
              append-icon="mdi-calendar"
              readonly
              filled="filled"
              hide-details
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="periodOfTime"
            no-title
            range
            @change="(value) => updatePeriodOfTime(value)"
          />
        </v-menu>
      </v-col>
      <v-col cols="6"
        ><user-select
          v-if="hasProxy"
          :items="[]"
          :role-name="'Proxy'"
          :preselected="proxyUser"
          :disabled="true"
        ></user-select>
        <user-select
          v-if="isSelectProxyList"
          :items="selectProxyList"
          :role-name="'Proxy'"
          :preselected="null"
          @change-user="(value) => updateProxyUser(value)"
        ></user-select>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import BaseSwitch from '../ui/BaseSwitch';
import { mapGetters } from 'vuex';
import UserSelect from '../ui/UserSelect';
import getInitials from '../../helpers/getInitials';

export default {
  name: 'Proxy',
  components: {
    BaseSwitch,
    UserSelect,
  },
  data() {
    return {
      hasProxy: false,
      isExpanded: false,
      periodOfTime: [],
      proxyUser: null,
      menu: false,
      selectProxyList: null,
      isSelectProxyList: false,
    };
  },
  computed: {
    ...mapGetters({
      getUserToEditHasProxy: 'users/getUserToEditHasProxy',
      getUserToEditIsProxy: 'users/getUserToEditIsProxy',
      getUserToEditProxySettingsToSave: 'users/getUserToEditProxySettingsToSave',
      getAvailableProxyUser: 'users/getAvailableProxyUser',
      getUserById: 'users/userById',
    }),
    dateRangeText() {
      return this.periodOfTime.join(' - ');
    },
  },
  created() {
    if (this.getUserToEditHasProxy.length) {
      const firstProxy = this.getUserToEditHasProxy[0];
      this.hasProxy = true;
      this.isExpanded = true;
      this.periodOfTime = [firstProxy.from, firstProxy.to];
      this.proxyUser = {
        name: firstProxy.firstName + ' ' + firstProxy.lastName,
        id: firstProxy.id,
        initials: getInitials(firstProxy.firstName, firstProxy.lastName),
      };
    }
  },
  methods: {
    updateProxy(value) {
      this.$store.commit('users/setProxyToSave', { fieldName: 'hasProxy', value });
      this.isExpanded = value;
    },
    updatePeriodOfTime(value) {
      this.$store.commit('users/setProxyToSave', { fieldName: 'periodOfTime', value });
      this.updateProxySelectField();
    },
    updateProxySelectField() {
      const periodOfTime = this.getUserToEditProxySettingsToSave.periodOfTime;
      this.isSelectProxyList = !!periodOfTime.length;
      const availableUsers = this.getAvailableProxyUser(periodOfTime);
      let userList = [];
      Object.keys(availableUsers).forEach((key) => {
        const user = availableUsers[key];
        const newUser = {
          name: user.firstName + ' ' + user.lastName,
          id: user.id,
          initials: getInitials(user.firstName, user.lastName),
        };
        userList.push(newUser);
      });
      this.selectProxyList = userList;
    },
    updateProxyUser(value) {
      const user = this.getUserById(value.id);
      const userToSet = {
        firstName: user.firstName,
        lastName: user.lastName,
        id: user.id,
      };
      this.$store.commit('users/setProxyToSave', { fieldName: 'proxyUser', value: userToSet });
    },
  },
};
</script>
