import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const getInitialUserInCreationState = () => ({
  hasAdminRights: false,
  email: '',
  firstName: '',
  lastName: '',
  orgCode: '',
  hasReportAccess: true,
  readOnly: false,
  roles: [],
  zones: [],
  savingState: {
    isSaving: false,
    type: '',
    message: '',
  },
});

export const getInititalProxySettings = () => ({
  hasProxy: false,
  proxyUser: null,
  periodOfTime: [],
});

export const getInitialUserEditState = () => ({
  hasAdminRights: true,
  email: '',
  firstName: '',
  lastName: '',
  orgCode: '',
  hasReportAccess: true,
  readOnly: false,
  roles: [],
  id: '',
  username: '',
  zones: [],
  hasProxy: [],
  isProxy: [],
  loggedInUser: false,
  savingState: {
    isSaving: false,
    type: '',
    message: '',
  },
  proxySettingsToSave: getInititalProxySettings(),
});

export const getInitialUserState = () => ({
  lastFetch: null,
  users: [],
  userInCreation: null,
  userToEdit: null,
  loggedInUser: null,
});

export default {
  namespaced: true,
  state() {
    return getInitialUserState();
  },
  mutations,
  actions,
  getters,
};
