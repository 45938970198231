<template>
  <v-switch
    v-model="localIsActive"
    inset
    :label="label"
    class="cra-form-field"
    :disabled="disabled"
  ></v-switch>
</template>
<script>
export default {
  name: 'BaseSwitch',
  emits: ['change-field'],
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    localIsActive: {
      get() {
        return this.isActive;
      },
      set(val) {
        this.$emit('change-field', val);
      },
    },
  },
};
</script>
