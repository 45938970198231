<template>
  <v-overlay z-index="99999" color="overlay">
    <v-progress-circular :size="50" color="primary" indeterminate />
  </v-overlay>
</template>
<script>
export default {
  name: 'AuthLoading',
  data() {
    return {
      timeoutID: null,
    };
  },
  created() {
    // If the user unintentionally comes to this page, he will be forwarded after a timeout.
    this.timeoutID = window.setTimeout(() => this.$router.push({ path: '/accounts' }), 7000);
  },
  beforeDestroy() {
    window.clearTimeout(this.timeoutID);
  },
};
</script>
