<template>
  <div>
    <v-snackbar
      :timeout="-1"
      :value="getSnackbar.visible"
      :color="getSnackbar.type"
      :type="getSnackbar.type"
      right
      bottom
      class="mb-10 mr-10"
    >
      {{ getSnackbar.message }}
    </v-snackbar>
    <v-overlay v-if="getBusy" z-index="99999" color="overlay">
      <v-progress-circular :size="50" color="primary" indeterminate />
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Notifications',
  computed: {
    ...mapGetters({
      getSnackbar: 'notifications/getSnackbar',
      getBusy: 'notifications/getBusy',
    }),
  },
  watch: {
    getSnackbar() {
      if (this.getSnackbar.visible) {
        this.resetSnackbarAfterTimeout();
      }
    },
  },
  methods: {
    resetSnackbarAfterTimeout() {
      setTimeout(() => {
        this.$store.commit('notifications/resetSnackbar');
      }, this.getSnackbar.timeout);
    },
  },
};
</script>
