<template>
  <v-dialog v-model="isModalOn" :max-width="maxWidth" persistent scrollable v-on="$listeners">
    <v-card>
      <v-container fluid :class="scrollPos <= 10 ? '' : 'cra-modal-header-scroll'">
        <v-row class="py-2 px-3">
          <v-col cols="10" md="11">
            <slot name="header"></slot>
          </v-col>
          <v-col cols="2" md="1" class="text-end">
            <base-button
              mode="secondary"
              icon="mdi-close"
              only-icon
              :classes="['cra-btn-secondary-colored']"
              @click="closeModal"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-card-text ref="scrolling">
        <slot />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseButton from './BaseButton';

export default {
  name: 'BaseModal',
  components: { BaseButton },
  props: {
    maxWidth: {
      type: String,
      required: false,
      default: '1231',
    },
  },
  data() {
    return {
      modal: false,
      scrollPos: 0,
    };
  },
  computed: {
    isModalOn: {
      get() {
        return this.modal;
      },
      set() {
        this.$emit('close');
      },
    },
  },
  emits: ['close'],
  mounted() {
    this.modal = true;
    this.$nextTick(() => {
      this.$refs['scrolling'].addEventListener('scroll', this.onScroll);
    });
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    onScroll(e) {
      this.scrollPos = Math.round(e.target.scrollTop);
    },
  },
};
</script>
<style lang="scss">
@import 'src/scss/globals';
.v-dialog {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
</style>
