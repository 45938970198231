import Vue from 'vue';
import App from './App.vue';
import axios from 'axios';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import { AuthPlugin } from './plugins/auth';
import VCurrencyField from 'v-currency-field';
import { VTextField } from 'vuetify/lib'; //Globally import VTextField
import { VueMasonryPlugin } from 'vue-masonry';

Vue.config.productionTip = false;
axios.defaults.baseURL = process.env.VUE_APP_API_PATH;

const getConfig = async () => {
  const Config = await fetch('/config/config.json');
  return Config.json();
};

getConfig().then((json) => {
  // eslint-disable-next-line prefer-destructuring
  const tenantId = json.tenantId;
  // eslint-disable-next-line prefer-destructuring
  const clientId = json.clientId;

  // Install the authentication plugin here
  Vue.use(AuthPlugin, {
    tenantId,
    clientId,
    onLoginCallback: (goTo) => {
      console.log('login succeed, redirection to', goTo);
      router.push({ path: goTo });
    },
    onErrorCallback: () => {
      console.log('login failed');
      console.log('login failed, redirection to Login page');
      router.push({ path: '/' });
    },
  });
  Vue.component('v-text-field', VTextField);
  Vue.use(VCurrencyField, {
    locale: 'en-US',
    decimalLength: 0,
    autoDecimalMode: false,
    min: null,
    max: null,
    defaultValue: 0,
    valueAsInteger: true,
    allowNegative: false,
  });
  Vue.use(VueMasonryPlugin);

  new Vue({
    vuetify,
    router,
    store,
    axios,
    render: (h) => h(App),
  }).$mount('#app');
});
