<template>
  <v-dialog v-model="isModalOn" max-width="700" v-on="$listeners">
    <v-card class="py-2">
      <v-card-title>
        <h5>
          <v-avatar size="30" class="mr-2 cra-avatar">
            <v-icon class="cra-btn-close" small>mdi-exclamation-thick</v-icon></v-avatar
          >Confirm
        </h5>
        <v-spacer></v-spacer>
        <v-icon class="cra-btn-close" large @click="closeAndCancel">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-container fluid class="px-0">
          <v-row>
            <v-col cols="12">
              <slot />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <base-button
          mode="secondary"
          icon="mdi-chevron-right"
          :icon-color="'primary'"
          @click="closeAndCancel"
          >Cancel</base-button
        >
        <v-spacer></v-spacer>
        <base-button mode="primary" icon="mdi-check-bold" @click="closeAndAccept"
          >Confirm</base-button
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseButton from './BaseButton';
export default {
  name: 'BaseConfirmDialog',
  components: {
    BaseButton,
  },
  data() {
    return {
      modal: true,
    };
  },
  emits: ['cancel', 'accept'],
  computed: {
    isModalOn: {
      get() {
        return this.modal;
      },
      set() {
        this.$emit('cancel');
      },
    },
  },
  methods: {
    closeAndCancel() {
      this.$emit('cancel');
    },
    closeAndAccept() {
      this.$emit('accept');
    },
  },
};
</script>
<style lang="scss">
.cra-btn-close {
  cursor: pointer;
}
</style>
