<template>
  <v-footer dark class="py-2 cra-footer" absolute padless>
    <v-container fluid class="cra-max-content">
      <v-row>
        <v-col cols="12" md="9">
          <ul class="legal-links color-gray">
            <li class="legal-links__item">
              Siemens Medical Solutions USA, Inc. &copy; {{ new Date().getFullYear() }}
            </li>
            <li class="legal-links__item">
              <a
                class="legal-links__link"
                target="_blank"
                href="https://www.siemens-healthineers.com/en-us/corporate-info"
                >Corporate Information</a
              >
            </li>
            <li class="legal-links__item">
              <a
                class="legal-links__link"
                target="_blank"
                href="https://www.siemens-healthineers.com/en-us/siemens-website-privacy-policy"
                >Privacy Policy</a
              >
            </li>
            <li class="legal-links__item">
              <a
                class="legal-links__link"
                target="_blank"
                href="https://www.siemens-healthineers.com/cookie"
                >Cookie Policy</a
              >
            </li>
            <li class="legal-links__item">
              <a
                class="legal-links__link"
                target="_blank"
                href="https://www.siemens-healthineers.com/en-us/terms-of-use"
                >Terms of Use</a
              >
            </li>
          </ul>
        </v-col>
        <v-col cols="12" md="3" class="text-right"
          ><span v-if="version" class="mt-4 mt-sm-0 cra-version p-tiny"
            >Version: {{ version }}</span
          >
          <span class="mt-4 mt-sm-0 cra-credits p-tiny">
            powered by
            <a href=" https://gms.siemens.cloud/ " class="p-tiny" target="_blank">/GMS/</a>
          </span>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
<script>
import { version } from '../../../package.json';
export default {
  name: 'TheFooter',
  data() {
    const gitHash = process.env.VUE_APP_GIT_HASH;
    const fullVersion = gitHash ? `${version}@${gitHash}` : `${version}`;
    return {
      version: fullVersion,
    };
  },
};
</script>
<style scoped lang="scss">
@import 'src/scss/globals';
.cra-footer {
  background: $gray-shade-4;

  .legal-links {
    list-style-type: none;
    margin: 0;
    padding-left: 0;
    line-height: 2;
    font-size: 0.8em;
  }
  .legal-links {
    &__item {
      display: inline-block;
      margin: 0;
      color: $gray-tint-2;

      a {
        text-decoration: none;
      }
      a:hover,
      a:active {
        box-shadow: none;
        text-decoration: none;
      }
    }
    &__item:after {
      display: inline-block;
      content: '|';
      margin: 0 0.2em;
    }
    &__item:last-of-type:after {
      display: inline-block;
      content: '';
    }
    .legal-links {
      list-style-type: none;
      margin: 0;
      padding-left: 0;
      line-height: 2;
      font-size: 0.8em;
    }
    .legal-links {
      &__item {
        display: inline-block;
        margin: 0;
        a {
          text-decoration: none;
        }
        a:hover,
        a:active {
          box-shadow: none;
          text-decoration: none;
        }
      }
      &__item:after {
        display: inline-block;
        content: '|';
        margin: 0 0.2em;
      }
      &__item:last-of-type:after {
        display: inline-block;
        content: '';
      }
      &__link {
        color: $gray-tint-2;
        &:hover {
          color: $gray-tint-4;
        }
      }
    }
  }
  .cra-version {
    color: $gray-tint-2;
  }
  .cra-credits {
    display: block;
    color: $gray-tint-2;

    a {
      color: $gray-tint-2;
      text-decoration: none;

      &:hover {
        color: $gray-tint-4;
      }
    }
  }
}
</style>
