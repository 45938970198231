<template>
  <v-container class="px-0 px-lg-2 py-0 cra-max-content" fluid>
    <v-row justify="space-between" align="center">
      <v-col cols="12" md="3">
        <v-combobox
          v-model="zonesSelect"
          :items="zoneItems"
          label="Zone"
          multiple
          outlined
          rounded
          dense
          hide-details
          class="cra-filter-select"
          append-icon="mdi-chevron-down"
          @change="(value) => setFilterZones(value)"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-combobox
          v-model="userRolesSelect"
          :items="userRoleItems"
          label="User roles"
          multiple
          outlined
          rounded
          dense
          hide-details
          class="cra-filter-select"
          append-icon="mdi-chevron-down"
          @change="(value) => setFilterUserRoles(value)"
        />
      </v-col>
      <v-col cols="12" md="3" :class="[!isMobile ? 'd-flex justify-end' : '']">
        <base-button
          v-if="!isMobile && !isArchive"
          mode="secondary"
          icon="mdi-chevron-right"
          solo
          :class="'cra-btn-filterbar'"
          @click="setFilterArchive()"
        >
          User archive
        </base-button>
        <base-button
          v-if="!isMobile && isArchive"
          mode="secondary"
          icon="mdi-chevron-right"
          solo
          :class="'cra-btn-filterbar'"
          @click="setFilterActive()"
        >
          User overview
        </base-button>
        <hr v-if="isMobile" />
        <search-form v-if="isMobile" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import BaseButton from '../ui/BaseButton';
import SearchForm from '../search/SearchForm';

export const ALL_ZONE = 'All zones';
export const ALL_ROLE = 'All user roles';

export default {
  name: 'UsersFilter',
  components: { SearchForm, BaseButton },
  props: {
    isMobile: Boolean,
  },
  data() {
    return {
      zoneItems: [ALL_ZONE],
      zonesSelect: [ALL_ZONE],
      userRoleItems: [ALL_ROLE],
      userRolesSelect: [ALL_ROLE],
      isArchive: false,
    };
  },
  computed: {
    ...mapGetters({
      getZones: 'zones/zones',
      getActiveZones: 'filters/getActiveZones',
      getUserRoles: 'userRoles/userRoles',
      getActiveUserRoles: 'filters/getActiveUserRoles',
    }),
    routeIsOverview() {
      return this.$route.name === 'users' ? true : false;
    },
  },
  async created() {
    await this.loadSelections();
    // zones
    const activeZones = this.getActiveZones;
    this.zonesSelect =
      activeZones.length === this.getZones.length || !activeZones.length ? [ALL_ZONE] : activeZones;
  },
  methods: {
    async loadSelections() {
      try {
        await this.$store.dispatch('zones/loadZones');
      } catch (error) {
        this.error = error.message || 'Some wrong?';
        console.log('Error: ' + this.error);
      }
      this.getZones.forEach((obj) => this.zoneItems.push(obj));

      try {
        await this.$store.dispatch('userRoles/loadUserRoles');
      } catch (error) {
        this.error = error.message || 'Some wrong?';
        console.log('Error: ' + this.error);
      }
      this.getUserRoles.forEach((obj) => this.userRoleItems.push(obj));
    },
    setFilters(fieldName, allFilterName, filters, filterGetter) {
      let activeFilters = [];
      const includesAll = filters.includes(allFilterName);
      const hasAllBeenAdded = filters.length > 1 && filters[filters.length - 1] === allFilterName;

      if (filters.length === 0) {
        this[fieldName + 'Select'] = [allFilterName];
        activeFilters = filterGetter;
      } else {
        if (includesAll && !hasAllBeenAdded) {
          const allIndex = filters.findIndex((zone) => zone === allFilterName);
          filters.splice(allIndex, 1);
          this[fieldName + 'Select'] = filters;
          activeFilters = filters;
        } else if (hasAllBeenAdded) {
          this[fieldName + 'Select'] = [allFilterName];
          activeFilters = filterGetter;
        } else {
          this[fieldName + 'Select'] = filters;
          activeFilters = filters;
        }
      }

      this.$store.commit('filters/setFilter', {
        fieldName,
        value: activeFilters,
      });
    },
    setFilterZones(zones) {
      this.setFilters('zones', ALL_ZONE, zones, this.getZones);
    },
    setFilterUserRoles(userRoles) {
      this.setFilters('userRoles', ALL_ROLE, userRoles, this.getUserRoles);
    },
    setFilterArchive() {
      this.isArchive = true;
      this.$store.commit('filters/setFilter', {
        fieldName: 'activeUser',
        value: false,
      });
    },
    setFilterActive() {
      this.isArchive = false;
      this.$store.commit('filters/setFilter', {
        fieldName: 'activeUser',
        value: true,
      });
    },
    goTo(path) {
      this.$router.push(path);
    },
  },
};
</script>
