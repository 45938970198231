<template>
  <v-dialog v-model="isModalOn" max-width="700" v-on="$listeners">
    <v-card class="py-2">
      <v-card-title>
        <h5>
          <v-avatar size="30" class="mr-2 cra-avatar">
            <v-icon class="cra-btn-close" small>mdi-exclamation-thick</v-icon></v-avatar
          >Mark action item as resolved
        </h5>
        <v-spacer></v-spacer>
        <v-icon class="cra-btn-close" large @click="closeAndCancel">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-container fluid class="px-0">
          <v-row>
            <v-col cols="12">
              <p>
                An action item should only be marked as resolved when the corresponding activity has
                been completed. Please note that a resolved action item can't be restored active
                again!
              </p>
              <p>
                In order to provide reporting data, please enter the actual financial impact in $ if
                available.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <base-button
          mode="secondary"
          icon="mdi-chevron-right"
          :icon-color="'primary'"
          @click="closeAndCancel"
          >Remain action item active</base-button
        >
        <v-spacer></v-spacer>
        <base-confirm-button
          mode="primary"
          icon="mdi-check-bold"
          :icon-size="'22px'"
          :button-default-text="'Mark action item as resolved'"
          :button-confirm-text="'Confirm resolve'"
          @confirm="closeAndAccept"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseButton from '../../ui/BaseButton';
import BaseConfirmButton from '../../ui/BaseConfirmButton';
export default {
  name: 'MarkerHintDialog',
  components: {
    BaseButton,
    BaseConfirmButton,
  },
  data() {
    return {
      modal: true,
    };
  },
  emits: ['cancel', 'accept'],
  computed: {
    isModalOn: {
      get() {
        return this.modal;
      },
      set() {
        this.$emit('cancel');
      },
    },
  },
  methods: {
    closeAndCancel() {
      this.$emit('cancel');
    },
    closeAndAccept() {
      this.$emit('accept');
    },
  },
};
</script>
<style lang="scss">
.cra-btn-close {
  cursor: pointer;
}
</style>
