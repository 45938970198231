export default {
  getActiveZones(state) {
    return state.zones;
  },
  getActiveCustomerTemperatures(state) {
    return state.customerTemperatures;
  },
  getActiveRiskLevels(state) {
    return state.riskLevels;
  },
  getActiveAccountTypes(state) {
    return state.accountTypes;
  },
  getActiveUser(state) {
    return state.activeUser;
  },
  getActiveUserRoles(state) {
    return state.userRoles;
  },
  getSearchTerm(state) {
    return state.search;
  },
  getActiveMyAccounts(state) {
    return state.myAccounts;
  },
};
