<template>
  <v-container fluid class="pl-6 pr-6">
    <div class="cra-expand-panel-title" @click="expand = !expand">
      <h4 class="cra-expand-panel-headline">Account description</h4>
      <v-icon v-if="expand" x-large>mdi-chevron-up</v-icon>
      <v-icon v-else x-large>mdi-chevron-down</v-icon>
    </div>
    <v-expand-transition>
      <div v-show="expand">
        <v-container fluid class="pl-0">
          <v-row>
            <v-col cols="12" md="8" class="pl-0">
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      v-model="fieldsToValidate.description.value"
                      hide-details
                      label="Description"
                      :disabled="!isEditable"
                      @change="(value) => updateField({ fieldName: 'description', value })"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <chips-select
                      :is-editable="isEditable"
                      :title="'Account issues'"
                      :selected="fieldsToValidate.accountIssues.value"
                      :items="getIssues"
                      @change-selected="
                        (value) => updateField({ fieldName: 'accountIssues', value })
                      "
                    >
                    </chips-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <chips-select
                      :is-editable="isEditable"
                      :title="'Lab products'"
                      :selected="fieldsToValidate.labProducts.value"
                      :items="getLabProducts"
                      @change-selected="(value) => updateField({ fieldName: 'labProducts', value })"
                    >
                    </chips-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <base-file-input
                      :is-editable="isEditable"
                      :label="'Document upload'"
                      :files="files.value"
                      @add-files="(value) => addFiles({ fieldName: 'files', value })"
                      @remove-files="(value) => removeFiles({ fieldName: 'files', value })"
                      @open-file="(value) => openFile(value)"
                    ></base-file-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <additional-link-field
                      :links="fieldsToValidate.additionalLink.value"
                      :is-editable="isEditable"
                      @change-link-list="
                        (value) => updateField({ fieldName: 'additionalLink', value })
                      "
                    ></additional-link-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="contractFieldsToValidate.sapNumber.value"
                label="SAP contract number"
                hide-details
                :disabled="!isEditable"
                class="mb-6"
                @change="(value) => updateContractField({ fieldName: 'sapNumber', value })"
              />
              <base-date-picker
                :date="setDateFields(contractFieldsToValidate.endDate.value)"
                label="Contract End Date"
                :filled="false"
                :disabled="!isEditable"
                :append-icon="''"
                @change-field="(date) => updateContractField({ fieldName: 'endDate', value: date })"
              />
              <p class="p-tiny mt-6 mb-0"><strong>Remaining Contractual Obligation</strong></p>
              <v-currency-field
                v-for="field in revenue"
                :key="field.label"
                v-model="field.value"
                :label="field.label"
                :disabled="!isEditable"
                hide-details
                class="mb-8"
                @change="(value) => updateRevenueField({ fieldName: field.label, value: value })"
              />
              <v-sheet>
                <base-switch
                  :is-active="contractFieldsToValidate.active.value"
                  :disabled="!isEditable"
                  :label="'Active contract'"
                  @change-field="(value) => updateContractField({ fieldName: 'active', value })"
                />
              </v-sheet>
              <div>
                <v-text-field
                  v-model="contractFieldsToValidate.soldTo.value"
                  label="Sold to"
                  hide-details
                  class="mb-6"
                  :disabled="!getContractToEdit.active || !isEditable"
                  @change="(value) => updateContractField({ fieldName: 'soldTo', value })"
                />
                <v-text-field
                  v-model="contractFieldsToValidate.shipTo.value"
                  label="Ship to"
                  hide-details
                  class="mb-6"
                  :disabled="!getContractToEdit.active || !isEditable"
                  @change="(value) => updateContractField({ fieldName: 'shipTo', value })"
                />
                <base-date-picker
                  :date="setDateFields(contractFieldsToValidate.goLive.value)"
                  label="Go live"
                  :filled="false"
                  :append-icon="''"
                  :disabled="!getContractToEdit.active || !isEditable"
                  @change-field="
                    (date) => updateContractField({ fieldName: 'goLive', value: date })
                  "
                />
                <v-currency-field
                  v-model="getFinancialImpact"
                  label="Σ Cost of action items"
                  hide-details
                  class="my-6"
                  :disabled="true"
                  prefix="$"
                />
                <p class="p-tiny mt-6 mb-0"><strong>Return on investment</strong></p>
                <v-currency-field
                  v-model="contractFieldsToValidate.roi.value"
                  label="ROI $"
                  hide-details
                  class="mb-6"
                  :disabled="!isEditable"
                  @change="(value) => updateCurrencyRoiField({ fieldName: 'roi', value })"
                />
                <v-text-field
                  v-model="contractFieldsToValidate.roi_qualitative.value"
                  label="ROI qualitative"
                  hide-details
                  class="mb-6"
                  :disabled="!isEditable"
                  @change="(value) => updateContractField({ fieldName: 'roi_qualitative', value })"
                />
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-expand-transition>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import BaseFileInput from '../../ui/BaseFileInput';
import BaseSwitch from '../../ui/BaseSwitch';
import BaseDatePicker from '../../ui/BaseDatePicker';
import ValidationService from '../../../services/ValidationService';
import ChipsSelect from '../../ui/ChipsSelect';
import AdditionalLinkField from '../../ui/AdditionalLinkField';

export default {
  name: 'AccountDescription',
  components: {
    AdditionalLinkField,
    ChipsSelect,
    BaseFileInput,
    BaseSwitch,
    BaseDatePicker,
  },
  props: {
    contractData: {
      type: Object,
      required: true,
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      expand: true,
      fieldsToValidate: {
        description: { value: '', validators: [ValidationService.hasContent] },
        accountIssues: { value: [], validators: [ValidationService.hasContent] },
        labProducts: { value: [], validators: [ValidationService.hasContent] },
        additionalLink: { value: [], validators: [] },
      },
      files: { value: [], validators: [ValidationService.hasContent] },
      contractFieldsToValidate: {
        active: {
          value: Boolean,
          validators: [],
        },
        sapNumber: {
          value: '',
          validators: [ValidationService.hasContent],
        },
        endDate: {
          value: null,
          validators: [ValidationService.hasContent],
        },
        soldTo: {
          value: null,
          conditionalFieldName: 'active',
          conditionalValidators: [ValidationService.isTrue],
          validators: [ValidationService.hasContent],
        },
        shipTo: {
          value: null,
          conditionalFieldName: 'active',
          conditionalValidators: [ValidationService.isTrue],
          validators: [ValidationService.hasContent],
        },
        goLive: {
          value: null,
          conditionalFieldName: 'active',
          conditionalValidators: [ValidationService.isTrue],
          validators: [ValidationService.hasContent],
        },
        roi: {
          value: null,
          validators: [ValidationService.hasContent],
        },
        roi_qualitative: {
          value: null,
          validators: [ValidationService.hasContent],
        },
      },
      revenue: [],
    };
  },
  computed: {
    ...mapGetters({
      getIssues: 'issues/issues',
      getLabProducts: 'labProducts/labProducts',
      getAccountToEdit: 'accounts/getAccountToEdit',
      getContractToEdit: 'accounts/getContractToEdit',
      getRevenueToEdit: 'accounts/getRevenueToEdit',
      getAccountFiles: 'accounts/getAccountFiles',
      getFinancialImpact: 'actionItems/getFinancialImpact',
    }),
  },
  watch: {
    getAccountFiles() {
      this.loadFiles();
    },
    getContractToEdit() {
      this.loadContractFormFields();
    },
    getRevenueToEdit() {
      this.loadRevenueFields();
    },
  },
  created() {
    this.loadFormFields();
    this.loadContractFormFields();
    this.loadFiles();
    this.loadRevenueFields();
  },

  methods: {
    loadFormFields() {
      Object.keys(this.fieldsToValidate).find((key) => {
        this.fieldsToValidate[key].value = this.getAccountToEdit[key];
      });
    },
    loadContractFormFields() {
      Object.keys(this.contractFieldsToValidate).find((key) => {
        this.contractFieldsToValidate[key].value = this.getContractToEdit[key];
      });
    },
    loadFiles() {
      let files = [];
      this.getAccountFiles.forEach((file) => {
        files.push(file);
      });
      this.files.value = files;
    },
    loadRevenueFields() {
      let fields = [];
      Object.keys(this.getRevenueToEdit).forEach((key) => {
        const newObj = {
          label: key,
          value: this.getRevenueToEdit[key],
        };
        fields.push(newObj);
      });
      this.revenue = [...fields];
    },
    setDateFields(value) {
      return value ? new Date(value) : null;
    },
    updateContractFormField(fieldName) {
      this.contractFieldsToValidate[fieldName].value = this.getContractToEdit[fieldName];
    },
    updateFormField(fieldName) {
      this.fieldsToValidate[fieldName].value = this.getAccountToEdit[fieldName];
    },
    updateContractField(data) {
      this.$store.dispatch('accounts/setAccountContractField', {
        fieldName: data.fieldName,
        value: data.value,
        id: this.getAccountToEdit.id,
        validators: this.contractFieldsToValidate[data.fieldName].validators,
      });
      //this.updateContractFormField(data.fieldName);
    },
    updateRevenueField(data) {
      if (isNaN(data.value)) {
        return false;
      }
      this.$store.dispatch('accounts/setAccountRevenueField', {
        fieldName: data.fieldName,
        value: data.value,
        id: this.getAccountToEdit.id,
      });
    },
    updateCurrencyRoiField(data) {
      if (isNaN(data.value)) {
        return false;
      }
      this.updateContractField(data);
    },
    updateField(data) {
      this.$store.dispatch('accounts/setAccountField', {
        fieldName: data.fieldName,
        value: data.value,
        id: this.getAccountToEdit.id,
        validators: this.fieldsToValidate[data.fieldName].validators,
      });
      this.updateFormField(data.fieldName);
    },
    addFiles(data) {
      const files = data.value;
      files.forEach((file) => {
        this.$store.dispatch('accounts/uploadFile', {
          id: this.getAccountToEdit.id,
          file,
        });
      });
    },
    removeFiles(data) {
      const file = data.value;
      this.$store.dispatch('accounts/deleteFile', {
        id: this.getAccountToEdit.id,
        file,
      });
    },
    openFile(value) {
      this.$store.dispatch('accounts/downloadFile', {
        accountId: this.getAccountToEdit.id,
        fileId: value.id,
        fileName: value.name,
      });
    },
  },
};
</script>
