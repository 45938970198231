import Vue from 'vue';
import Vuex from 'vuex';

import accountsModule from './modules/accounts/index';
import actionItemsModule from './modules/actionItems/index';
import accountHistoryModule from './modules/accountHistory/index';
import userHistoryModule from './modules/userHistory/index';
import accountCommentsModule from './modules/accountComments/index';
import actionItemCommentsModule from './modules/actionItemComments/index';
import insightsModule from './modules/insights/index';
import usersModule from './modules/users/index';
import zonesModule from './enums/zones/index';
import riskLevelsModule from './enums/riskLevels/index';
import issuesModule from './enums/issues/index';
import customerTemperaturesModule from './enums/customerTemperatures/index';
import labProductsModule from './enums/labProducts/index';
import userRolesModule from './enums/userRoles/index';
import notifications from './notifications/index';
import filters from './filters/index';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    accounts: accountsModule,
    users: usersModule,
    zones: zonesModule,
    riskLevels: riskLevelsModule,
    customerTemperatures: customerTemperaturesModule,
    issues: issuesModule,
    labProducts: labProductsModule,
    userRoles: userRolesModule,
    notifications,
    filters,
    actionItems: actionItemsModule,
    accountComments: accountCommentsModule,
    actionItemComments: actionItemCommentsModule,
    accountHistory: accountHistoryModule,
    userHistory: userHistoryModule,
    insights: insightsModule,
  },
});

export default store;
