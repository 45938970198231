<template>
  <v-container class="px-0 px-lg-2 py-0 cra-max-content" fluid>
    <v-row justify="space-between" align="center">
      <v-col cols="12" md="3">
        <v-combobox
          v-model="zonesSelect"
          :items="zoneItems"
          label="Zone"
          multiple
          outlined
          rounded
          dense
          append-icon="mdi-chevron-down"
          hide-details
          class="cra-filter-select"
          @change="(value) => setFilterZones(value)"
        /> </v-col></v-row
  ></v-container>
</template>
<script>
import { mapGetters } from 'vuex';

export const ALL_ZONE = 'All zones';
export default {
  name: 'InsightsFilter',
  props: {
    isMobile: Boolean,
  },
  data() {
    return {
      zoneItems: [ALL_ZONE],
      zonesSelect: [ALL_ZONE],
    };
  },
  computed: {
    ...mapGetters({
      getZones: 'zones/zones',
      getActiveZones: 'filters/getActiveZones',
    }),
  },
  async created() {
    await this.loadSelections();
    // zones
    const activeZones = this.getActiveZones;
    this.zonesSelect =
      activeZones.length === this.getZones.length || !activeZones.length ? [ALL_ZONE] : activeZones;
  },
  methods: {
    async loadSelections() {
      try {
        await this.$store.dispatch('zones/loadZones');
      } catch (error) {
        this.error = error.message || 'Some wrong?';
        console.log('Error: ' + this.error);
      }
      this.getZones.forEach((obj) => this.zoneItems.push(obj));
    },
    setFilters(fieldName, allFilterName, filters, filterGetter) {
      let activeFilters = [];
      const includesAll = filters.includes(allFilterName);
      const hasAllBeenAdded = filters.length > 1 && filters[filters.length - 1] === allFilterName;

      if (filters.length === 0) {
        this[fieldName + 'Select'] = [allFilterName];
        activeFilters = filterGetter;
      } else {
        if (includesAll && !hasAllBeenAdded) {
          const allIndex = filters.findIndex((zone) => zone === allFilterName);
          filters.splice(allIndex, 1);
          this[fieldName + 'Select'] = filters;
          activeFilters = filters;
        } else if (hasAllBeenAdded) {
          this[fieldName + 'Select'] = [allFilterName];
          activeFilters = filterGetter;
        } else {
          this[fieldName + 'Select'] = filters;
          activeFilters = filters;
        }
      }

      this.$store.commit('filters/setFilter', {
        fieldName,
        value: activeFilters,
      });
    },
    setFilterZones(zones) {
      this.$store.commit('insights/setFilteredInsights', zones);
      this.setFilters('zones', ALL_ZONE, zones, this.getZones);
    },
  },
};
</script>
