import ApiService from '../../../services/ApiService';

export default {
  async loadActionItemComments(context, payload) {
    const response = await ApiService.fetchActionItemComments(payload.id);
    const commentResponseList = response.data;
    const comments = [];

    for (const key in commentResponseList) {
      const comment = {
        comment: commentResponseList[key].comment,
        created: commentResponseList[key].created,
        createdBy: commentResponseList[key].createdBy,
      };
      comments.push(comment);
    }
    context.commit('setComments', comments);
    context.commit('addCommentInCreation');
  },

  async createActionItemComment(context, payload) {
    const commentToSave = { ...context.state.commentInCreation };

    delete commentToSave.tags;
    delete commentToSave.savingState;

    context.commit('setCommentInCreationSavingState', {
      isSaving: true,
      type: '',
      message: '',
    });
    try {
      const response = await ApiService.createActionItemComment(
        payload.actionItemId,
        commentToSave
      );
      console.log(response);
      context.commit('setCommentInCreationSavingState', {
        isSaving: false,
        type: 'success',
        message: 'Comment saved',
      });
      await context.dispatch('loadActionItemComments', { id: payload.actionItemId });
    } catch (e) {
      context.commit('setCommentInCreationSavingState', {
        isSaving: false,
        type: 'error',
        message: 'Comment save failed! ' + e.toString(),
      });
      console.log('error creating comment', e);
    }
  },
};
