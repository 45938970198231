<template>
  <v-container fluid class="pl-6 pr-6 cra-action-item-container">
    <div class="cra-expand-panel-title" @click="expand = !expand">
      <h4 class="cra-expand-panel-headline">
        Resolved action items ({{ Object.keys(itemList).length }})
      </h4>
      <v-icon v-if="expand" x-large>mdi-chevron-up</v-icon>
      <v-icon v-else x-large>mdi-chevron-down</v-icon>
    </div>
    <v-expand-transition>
      <div v-show="expand">
        <v-row justify="center" class="mt-4 mx-1">
          <v-expansion-panels accordion>
            <v-container v-if="!Object.keys(itemList).length" fluid class="px-0 pb-6">
              <p>No action items are marked as resolved at the moment</p>
            </v-container>
            <v-container v-else fluid class="px-0 py-0">
              <action-items-filter
                v-if="Object.keys(itemList).length"
                item-state="Resolved"
                @change-filter="filterItems()"
              ></action-items-filter>
              <v-container v-if="filteredItems.length" fluid class="px-0 py-0">
                <action-item
                  v-for="item in filteredItems"
                  :key="item.id"
                  :number="item.number"
                  :account-id="accountId"
                  :action-item-id="item.id"
                  :state="'resolved'"
                ></action-item>
              </v-container>
              <p v-if="!filteredItems.length && Object.keys(itemList).length">
                No items. Change filter.
              </p>
            </v-container>
          </v-expansion-panels>
        </v-row>
      </div>
    </v-expand-transition>
  </v-container>
</template>
<script>
import ActionItem from './ActionItem';
import ActionItemsFilter from './ActionItemsFilter';
import { mapGetters } from 'vuex';

export default {
  name: 'ActionItemsResolved',
  components: { ActionItemsFilter, ActionItem },
  props: {
    accountId: {
      type: String,
      required: true,
    },
    itemList: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      expand: false,
      filteredItems: [],
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      getActiveFilterCategories: 'actionItems/getActiveFilterCategories',
      getActionItemCategoryTitle: 'actionItems/getActionItemCategoryTitle',
      getActionItemCategorySlug: 'actionItems/getActionItemCategorySlug',
    }),
  },
  watch: {
    itemList() {
      this.setItems();
    },
  },
  created() {
    this.setItems();
  },
  methods: {
    setItems() {
      this.items = [];
      Object.keys(this.itemList).forEach((key) => {
        const obj = this.itemList[key];
        this.items.push(obj);
      });
      this.filterItems();
    },
    filterItems() {
      this.filteredItems = [];
      if (this.getActiveFilterCategories.length === 0) {
        this.filteredItems = this.items;
        return true;
      }
      this.items.forEach((item) => {
        this.getActiveFilterCategories.forEach((filter) => {
          if (
            item.category &&
            item.category.includes(this.getActionItemCategorySlug(filter)) &&
            !this.filteredItems.includes(item)
          ) {
            this.filteredItems.push(item);
          }
        });
      });
    },
  },
};
</script>
