<template>
  <div class="cra-select-chips">
    <p class="p-tiny mb-3">
      <strong>{{ title }}</strong>
    </p>
    <div class="mb-3">
      <v-chip
        v-for="item in selectedItems"
        :key="item"
        class="mb-2 mr-2"
        :close="isEditable"
        @click:close="removeItem(item)"
        >{{ item }}</v-chip
      >
    </div>
    <div v-if="isEditable" class="cra-select-chips-selectfield">
      <span class="cra-btn-flat-icon" @click="isFieldOn = true">
        <v-icon left> mdi-plus </v-icon>
        <span>Add {{ titleSmall }}</span>
      </span>
      <v-autocomplete
        ref="autocompleteField"
        v-model="selectedItems"
        :items="items"
        hide-details
        filled
        multiple
        append-icon=""
        :class="!isFieldOn ? 'hidden' : ''"
        class="cra-autocomplete mt-2"
        @change="changeField"
        @blur="isFieldOn = false"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'ChipsSelect',
  emits: ['change-selected'],
  props: {
    title: {
      type: String,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      selectedItems: String,
      isFieldOn: false,
    };
  },
  computed: {
    titleSmall() {
      return this.title.toLowerCase();
    },
  },
  watch: {
    isFieldOn() {
      if (this.isFieldOn === true) {
        this.$nextTick(() => {
          this.$refs.autocompleteField.focus();
          this.$refs.autocompleteField.isMenuActive = true;
        });
      } else {
        this.$refs.autocompleteField.blur();
        this.$refs.autocompleteField.isMenuActive = false;
      }
    },
    selected() {
      this.selectedItems = this.selected;
    },
    selectedItems() {
      if (this.selectedItems.length === 0) {
        this.selectedItems = this.selected;
      }
    },
  },
  mounted() {
    this.selectedItems = this.selected;
  },
  methods: {
    removeItem(item) {
      const items = [...this.selectedItems];
      this.selectedItems = items.filter((i) => i !== item);
      this.$emit('change-selected', this.selectedItems);
    },
    changeField() {
      this.$emit('change-selected', this.selectedItems);
    },
  },
};
</script>
