export default (periodA, periodB) => {
  const periodAFrom = new Date(periodA[0]).toISOString();
  const periodATo = new Date(periodA[1]).toISOString();
  const periodBFrom = new Date(periodB[0]).toISOString();
  const periodBTo = new Date(periodB[1]).toISOString();
  return (
    (periodAFrom >= periodBFrom && periodAFrom <= periodBTo) ||
    (periodATo >= periodBFrom && periodATo <= periodBTo) ||
    (periodAFrom <= periodBFrom && periodATo >= periodBTo)
  );
};
