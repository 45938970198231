import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import IconPlus from '../components/ui/icons/IconPlus';
import IconMinus from '../components/ui/icons/IconMinus';
import IconArrowUpRight from '../components/ui/icons/IconArrowUpRight';
import IconSettingsCheck from '../components/ui/icons/IconSettingsCheck';
import IconStar from '../components/ui/icons/IconStar';
import IconWarning from '../components/ui/icons/IconWarning';
import IconCheckmarkWarning from '../components/ui/icons/IconCheckmarkWarning';
import IconMap from '../components/ui/icons/IconMap';
import IconTable from '../components/ui/icons/IconTable';
import IconUser from '../components/ui/icons/IconUser';
import IconUserMultiple from '../components/ui/icons/IconUserMultiple';
import IconNoData from '../components/ui/icons/IconNoData';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      variations: false,
    },
    themes: {
      light: {
        primary: '#cf4b00',
        secondary: '#1A1A1A',
        accent: '#009999',
        success: '#009a38',
        error: '#e7001d',
        bg_pendingapproval: '#009999',
        txt_pendingapproval: '#FFFFFF',
        bg_pendingchange: '#009999',
        txt_pendingchange: '#FFFFFF',
        bg_accountlost: '#7A162D',
        txt_accountlost: '#FFFFFF',
        bg_declined: '#7A162D',
        txt_declined: '#FFFFFF',
        bg_contractrenewed: '#3ABFED',
        txt_contractrenewed: '#1A1A1A',
        bg_rfpdue: '#2B2483',
        txt_rfpdue: '#FFFFFF',
        overlay: '#FFFFFF',
      },
      dark: {
        primary: '#ec6602',
        secondary: '#eeeeee',
        overlay: '#FFFFFF',
      },
    },
  },
  icons: {
    values: {
      plus: {
        component: IconPlus,
      },
      minus: {
        component: IconMinus,
      },
      arrowUpRight: {
        component: IconArrowUpRight,
      },
      settingsCheck: {
        component: IconSettingsCheck,
      },
      star: {
        component: IconStar,
      },
      warning: {
        component: IconWarning,
      },
      checkmarkWarning: {
        component: IconCheckmarkWarning,
      },
      map: {
        component: IconMap,
      },
      table: {
        component: IconTable,
      },
      user: {
        component: IconUser,
      },
      userMultiple: {
        component: IconUserMultiple,
      },
      noData: {
        component: IconNoData,
      },
    },
  },
});
