import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const ACCOUNT_HISTORY_ENUMS = {
  ACCOUNT_ACTIVATED: 'ACCOUNT_ACTIVATED',
  ACCOUNT_APPROVED: 'ACCOUNT_APPROVED',
  ACCOUNT_ARCHIVED_BY_SYSTEM: 'ACCOUNT_ARCHIVED_BY_SYSTEM',
  ACCOUNT_ARCHIVED_BY_USER: 'ACCOUNT_ARCHIVED_BY_USER',
  ACCOUNT_DENIED_BY_USER: 'ACCOUNT_DENIED_BY_USER',
  ACCOUNT_LOST: 'ACCOUNT_LOST',
  ACCOUNT_REACTIVATED: 'ACCOUNT_REACTIVATED',
  ACCOUNT_REMOVED: 'ACCOUNT_REMOVED',
  ACCOUNT_SUBMITTED: 'ACCOUNT_SUBMITTED',
  ACCOUNT_UPDATED: 'ACCOUNT_UPDATED',
  ACCOUNT_WATCH_LISTED_BY_USER: 'ACCOUNT_WATCH_LISTED_BY_USER',
  ACTION_ITEM_ADDED: 'ACTION_ITEM_ADDED',
  COMMENT_ADDED: 'COMMENT_ADDED',
  CUSTOMER_PARAMETER_CHANGED_FAVORABLY: 'CUSTOMER_PARAMETER_CHANGED_FAVORABLY',
  CUSTOMER_PARAMETER_CHANGED_UNFAVORABLY: 'CUSTOMER_PARAMETER_CHANGED_UNFAVORABLY',
  CUSTOMER_PARAMETER_CHANGE_APPROVED: 'CUSTOMER_PARAMETER_CHANGE_APPROVED',
  CUSTOMER_PARAMETER_CHANGE_REJECTED_BY_SYSTEM: 'CUSTOMER_PARAMETER_CHANGE_REJECTED_BY_SYSTEM',
  CUSTOMER_PARAMETER_CHANGE_REJECTED_BY_USER: 'CUSTOMER_PARAMETER_CHANGE_REJECTED_BY_USER',
  CUSTOMER_PARAMETER_CHANGE_REQUESTED: 'CUSTOMER_PARAMETER_CHANGE_REQUESTED',
  CUSTOMER_PARAMETER_UNCHANGED: 'CUSTOMER_PARAMETER_UNCHANGED',
  FILE_ADDED: 'FILE_ADDED',
  FILE_DELETED: 'FILE_DELETED',
  STATE_CHANGED: 'STATE_CHANGED',
};

export default {
  namespaced: true,
  state() {
    return {
      historyItems: [],
    };
  },
  mutations,
  actions,
  getters,
};
