import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const getInitialActionItemCommentInCreationState = () => ({
  comment: '',
  savingState: {
    isSaving: false,
    type: '',
    message: '',
  },
});

export const getInitialActionItemCommentState = () => ({
  lastFetch: null,
  comments: [],
  commentInCreation: null,
});

export default {
  namespaced: true,
  state() {
    return getInitialActionItemCommentState();
  },
  mutations,
  actions,
  getters,
};
