import ApiService from '../../../services/ApiService';

export default {
  async loadHistoryItems(context, payload) {
    const response = await ApiService.fetchAccountHistory(payload.id);
    const itemResponseList = response.data;
    const items = [];

    for (const key in itemResponseList) {
      const item = {
        entityId: itemResponseList[key].entityId,
        type: itemResponseList[key].type,
        description: itemResponseList[key].description,
        data: itemResponseList[key].data,
        timestamp: itemResponseList[key].timestamp,
      };
      item.timestamp = new Date(item.timestamp);
      items.push(item);
    }
    context.commit('setHistoryItems', items);
  },
};
