<template>
  <div>
    <div class="cra-fixed-header">
      <the-header />
      <the-filterbar />
    </div>
    <div class="cra-content">
      <!--      <div v-if="!!getLoggedInUser" class="cra-proxy-bar">
        {{ !PermissionService.hasReadOnlyRestrict() }}
      </div>-->
      <div v-if="!!getLoggedInUser && !!getLoggedInUser.hasProxy.length" class="cra-proxy-bar">
        <div class="cra-max-content">
          From {{ getLoggedInUser.hasProxy[0].from }} - {{ getLoggedInUser.hasProxy[0].to }}
          {{ getLoggedInUser.hasProxy[0].firstName }}
          {{ getLoggedInUser.hasProxy[0].lastName }} is set as your proxy.
        </div>
      </div>
      <div v-if="!!getLoggedInUser && !!getLoggedInUser.isProxy.length" class="cra-proxy-bar">
        <div class="cra-max-content">
          {{ getLoggedInUser.isProxy[0].firstName }} {{ getLoggedInUser.isProxy[0].lastName }} has
          you as a proxy from {{ getLoggedInUser.isProxy[0].from }} -
          {{ getLoggedInUser.isProxy[0].to }}. During this time, corresponding emails will be
          forwarded to you.
        </div>
      </div>
      <v-container fluid class="mt-12 cra-max-content">
        <v-row class="mb-0" justify="space-between" align="center">
          <v-col cols="12" md="9">
            <h2 v-if="getActiveUser" class="h2-display">
              User management ({{ filteredUsers.length }})
            </h2>
            <h2 v-else class="h2-display">User archive ({{ filteredUsers.length }})</h2>
          </v-col>
          <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="3" class="d-flex justify-end">
            <base-button
              v-if="
                !!getLoggedInUser &&
                !PermissionService.isDisabled(['isAdmin']) &&
                getActiveUser &&
                !PermissionService.hasReadOnlyRestrict()
              "
              mode="primary"
              icon="mdi-plus"
              :icon-size="'22px'"
              @click="createUser"
            >
              Add user
            </base-button>
          </v-col>
        </v-row>
        <v-row v-if="hasUsers" v-masonry class="mb-16 pb-8">
          <v-col
            v-for="user in filteredUsers"
            :key="user.id"
            v-masonry-tile
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <user-card
              :first-name="user.firstName"
              :last-name="user.lastName"
              :roles="user.roles"
              :zones="user.zones"
              :is-proxy="!!user.hasProxy.length"
              @click="showUserDetail(user.id)"
            ></user-card>
          </v-col>
        </v-row>
        <v-row v-else class="mb-16 pb-8">
          <v-col cols="12" sm="6" md="4" lg="3" xl="2"
            ><v-card class="cra-card-add" min-height="400" outlined rounded @click="createUser">
              <v-card-text><h3>Add user</h3></v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <router-view />
      <the-footer />
    </div>
  </div>
</template>
<script>
import TheHeader from '../../components/layout/TheHeader';
import TheFooter from '../../components/layout/TheFooter';
import BaseButton from '../../components/ui/BaseButton';
import UserCard from '../../components/users/UserCard';
import TheFilterbar from '../../components/layout/TheFilterbar';
import PermissionService from '../../services/PermisssionService';
import { mapGetters } from 'vuex';
export default {
  name: 'UserOverview',
  components: { UserCard, TheHeader, TheFooter, BaseButton, TheFilterbar },
  data() {
    return {
      isLoading: false,
      PermissionService,
    };
  },
  computed: {
    ...mapGetters({
      getActiveUser: 'filters/getActiveUser',
      getLoggedInUser: 'users/getLoggedInUser',
      filteredUsers: 'users/getFilteredUsers',
      hasUsers: 'users/hasUsers',
    }),
  },
  watch: {
    filteredUsers() {
      if (typeof this.$redrawVueMasonry === 'function') {
        this.$redrawVueMasonry();
      }
    },
  },
  created() {
    this.loadUserRessources();
  },
  beforeRouteUpdate(_, __, next) {
    if (typeof this.$redrawVueMasonry === 'function') {
      this.$redrawVueMasonry();
    }
    next();
  },
  methods: {
    loadUserRessources() {
      try {
        this.$store.dispatch('zones/loadZones');
      } catch (error) {
        this.error = error.message || 'Some wrong?';
        console.log('Error: ' + this.error);
      }
      try {
        this.$store.dispatch('userRoles/loadUserRoles');
      } catch (error) {
        this.error = error.message || 'Some wrong?';
        console.log('Error: ' + this.error);
      }
    },
    createUser() {
      this.$router.push({ path: '/user-management/create' });
    },
    showUserDetail(id) {
      this.$router.push({ path: '/user-management/' + id });
    },
  },
};
</script>
