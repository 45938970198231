<template>
  <v-text-field
    v-model="searchTerm"
    flat
    outlined
    rounded
    solo
    clearable
    dense
    prepend-inner-icon="mdi-magnify"
    hide-details
    label="Search"
    class="search-field pa-0 ma-0"
    @change="setSearchFilter"
    @input="setSearchFilter"
  ></v-text-field>
</template>
<script>
export default {
  name: 'SearchForm',
  data() {
    return {
      searchTerm: null,
    };
  },
  methods: {
    setSearchFilter() {
      const searchTerm = this.searchTerm ? this.searchTerm : '';
      this.$store.commit('filters/setFilter', { fieldName: 'search', value: searchTerm });
    },
  },
};
</script>
