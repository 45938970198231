<template>
  <div class="cra-user-output" v-on="$listeners">
    <v-avatar class="cra-avatar">
      <span v-if="initials">{{ initials }}</span>
      <v-icon v-else small>mdi-plus</v-icon>
    </v-avatar>
    <div class="cra-select-user-content pl-4">
      <p v-if="name" class="mb-0 p-tiny">
        <strong>{{ name }}</strong>
      </p>
      <p v-else class="mb-0 p-tiny"><strong>Please select</strong></p>
      <p class="mb-0 p-tiny">{{ roleName }}</p>
    </div>
  </div>
</template>
<script>
import getInitials from '../../helpers/getInitials';
import getFullName from '../../helpers/getFullName';

export default {
  name: 'BaseUserOutput',
  props: {
    firstName: {
      type: String,
      required: false,
      default: null,
    },
    lastName: {
      type: String,
      required: false,
      default: null,
    },
    roleName: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    name() {
      return getFullName(this.firstName, this.lastName);
    },
    initials() {
      return getInitials(this.firstName, this.lastName);
    },
  },
};
</script>
<style scoped lang="scss">
@import 'src/scss/globals';
.cra-user-output {
  display: flex;
  position: relative;
  align-items: center;
}
.cra-user-output-content {
  p {
    color: $gray;
    strong {
      color: $gray-shade-4;
    }
  }
}
</style>
