<template>
  <v-select
    v-model="selectedItem"
    :items="items"
    :label="label"
    filled
    hide-details
    dense
    :disabled="disabled"
    :readonly="readonly"
    :class="[selectClass, selectedItemClass]"
    @change="(value) => changeField(value)"
  >
    <template #item="{ item }">
      <span :class="[itemClass, getSelectFieldClass(item)]">{{ item }}</span>
    </template>
  </v-select>
</template>
<script>
import removeSpacesLowerCase from '../../helpers/removeSpacesLowerCase';

export default {
  name: 'StyledSelect',
  emits: ['change-selected'],
  props: {
    selected: {
      type: String,
      required: false,
      default: '',
    },
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    selectClass: {
      type: String,
      required: true,
    },
    itemClass: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedItem: String,
      selectedItemClass: String,
    };
  },
  watch: {
    selectedItem() {
      this.selectedItemClass = this.getSelectFieldClass(this.selectedItem);
    },
    selected() {
      this.selectedItem = this.selected;
    },
  },
  mounted() {
    this.selectedItem = this.selected;
  },
  methods: {
    getSelectFieldClass(val) {
      return removeSpacesLowerCase(val);
    },
    changeField() {
      this.$emit('change-selected', this.selectedItem);
    },
  },
};
</script>
