export default {
  setInitialFilterForLoggedInUser(state, payload) {
    if (payload === null) {
      return false;
    }
    Object.keys(payload).forEach((key) => {
      state[key] = payload[key];
    });
  },
  setFilter(state, payload) {
    state[payload.fieldName] = payload.value;
  },
};
