import Vue from 'vue';
import VueRouter from 'vue-router';
import LoginPage from '../views/LoginPage.vue';
import AccountDetail from '../views/accounts/AccountDetail';
import UserDetail from '../views/users/UserDetail';
import store from '../store';
import AccountOverview from '../views/accounts/AccountOverview';
import Insights from '../views/Insights';
import AccountWatchlist from '../views/accounts/AccountWatchlist';
import UserOverview from '../views/users/UserOverview';
import NotFound from '../views/NotFound';
import AuthLoading from '../components/notifications/AuthLoading.vue';
import { isMSALUserLoggedIn, authenticateMSALUser } from '../plugins/auth';
import AccountArchive from '../views/accounts/AccountArchive';

Vue.use(VueRouter);
const hasNoAccessQueryParam = (route) => route.query.message === 'Noaccess';

async function loadUsers() {
  try {
    store.commit('notifications/setBusy', true);

    await store.dispatch('users/loadUsers');

    store.commit('notifications/setBusy', false);
  } catch (e) {
    store.commit('notifications/setBusy', false);
    store.commit('notifications/setSnackbar', {
      type: 'error',
      message: e,
      timeout: 6000,
      visible: true,
    });
  }
}

async function loadAccounts() {
  try {
    store.commit('notifications/setBusy', true);

    await store.dispatch('accounts/loadAccounts');

    store.commit('notifications/setBusy', false);
  } catch (e) {
    store.commit('notifications/setBusy', false);
    store.commit('notifications/setSnackbar', {
      type: 'error',
      message: e,
      timeout: 6000,
      visible: true,
    });
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: LoginPage,
    meta: { requiresAuth: false },
  },
  {
    path: '/accounts',
    name: 'accounts',
    component: AccountOverview,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      store.commit('filters/setFilter', {
        fieldName: 'accountTypes',
        value: ['Active', 'Submitted'],
      });
      store.commit('filters/setFilter', { fieldName: 'search', value: '' });
      store.dispatch('users/loadLoggedInUser');
      if (store.getters['accounts/accounts'].length === 0) {
        await loadAccounts();
      }
      if (store.getters['users/users'].length === 0) {
        await loadUsers();
      }
      next();
    },
    children: [
      {
        path: 'create',
        name: 'accountcreate',
        component: AccountDetail,
        beforeEnter: (to, from, next) => {
          store.commit('accounts/addAccountInCreation');
          next();
        },
      },
      {
        path: ':id',
        name: 'accountdetails',
        component: AccountDetail,
        props: true,
        beforeEnter: (to, from, next) => {
          store.dispatch('accounts/loadAccountDetails', to.params.id).then(() => {
            next();
            store.commit('actionItems/addActionItemInCreation');
            store.commit('accountComments/addCommentInCreation');
            next();
          });
        },
      },
    ],
  },
  {
    path: '/watchlist',
    name: 'watchlist',
    component: AccountWatchlist,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      store.commit('filters/setFilter', {
        fieldName: 'accountTypes',
        value: ['Watchlist'],
      });
      store.dispatch('users/loadLoggedInUser');
      if (store.getters['accounts/accounts'].length === 0) {
        await loadAccounts();
      }
      if (store.getters['users/users'].length === 0) {
        await loadUsers();
      }
      next();
    },
    children: [
      {
        path: ':id',
        name: 'watchlistdetails',
        component: AccountDetail,
        props: true,
        beforeEnter: (to, from, next) => {
          store.dispatch('accounts/loadAccountDetails', to.params.id).then(() => {
            next();
          });
        },
      },
    ],
  },
  {
    path: '/archive',
    name: 'archive',
    component: AccountArchive,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      store.commit('filters/setFilter', {
        fieldName: 'accountTypes',
        value: ['Archived'],
      });
      store.dispatch('users/loadLoggedInUser');
      if (store.getters['accounts/accounts'].length === 0) {
        await loadAccounts();
      }
      if (store.getters['users/users'].length === 0) {
        await loadUsers();
      }
      next();
    },
    children: [
      {
        path: ':id',
        name: 'archivedetails',
        component: AccountDetail,
        props: true,
        beforeEnter: (to, from, next) => {
          store.dispatch('accounts/loadAccountDetails', to.params.id).then(() => {
            next();
          });
        },
      },
    ],
  },
  {
    path: '/user-management',
    name: 'users',
    component: UserOverview,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      store.commit('filters/setFilter', {
        fieldName: 'activeUser',
        value: true,
      });
      store.commit('filters/setFilter', { fieldName: 'search', value: '' });
      store.dispatch('users/loadLoggedInUser');
      if (store.getters['users/users'].length === 0) {
        await loadUsers();
      }
      next();
    },
    children: [
      {
        path: 'create',
        name: 'usercreate',
        component: UserDetail,
        beforeEnter: (to, from, next) => {
          store.commit('users/addUserInCreation');
          next();
        },
      },
      {
        path: ':id',
        name: 'userdetails',
        component: UserDetail,
        props: true,
        beforeEnter: (to, from, next) => {
          store.dispatch('users/loadUserDetails', to.params.id).then(() => {
            next();
          });
        },
      },
    ],
  },
  {
    path: '/insights',
    name: 'insights',
    component: Insights,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      store.dispatch('users/loadLoggedInUser');
      if (store.getters['users/users'].length === 0) {
        await loadUsers();
      }
      try {
        store.commit('notifications/setBusy', true);
        await store.dispatch('zones/loadZones');
        store.commit('notifications/setBusy', false);
      } catch (e) {
        store.commit('notifications/setBusy', false);
        store.commit('notifications/setSnackbar', {
          type: 'error',
          message: e,
          timeout: 6000,
          visible: true,
        });
      }
      try {
        store.commit('notifications/setBusy', true);
        await store.dispatch('insights/loadInsights');
        store.commit('notifications/setBusy', false);
      } catch (e) {
        store.commit('notifications/setBusy', false);
        store.commit('notifications/setSnackbar', {
          type: 'error',
          message: e,
          timeout: 6000,
          visible: true,
        });
      }
      next();
    },
  },
  {
    path: '/auth-loading',
    name: 'AuthLoading',
    component: AuthLoading,
    meta: { requiresAuth: false },
  },
  {
    path: '/:notFound(.*)',
    name: 'NotFound',
    component: NotFound,
    meta: { requiresAuth: false },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === 'Home' && !hasNoAccessQueryParam(to)) {
    isMSALUserLoggedIn()
      .then(() => {
        console.log('User already logged in, redirect to accounts');
        next({ path: '/accounts' });
      })
      .catch(() => {
        next();
      });
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    isMSALUserLoggedIn()
      .then(() => {
        next();
      })
      .catch(() => {
        console.log('User not logged in, starting authentication');
        const path = to.hash ? to.path + to.hash : to.path;
        authenticateMSALUser(path);
      });
  } else {
    next();
  }
});

export default router;
