<template>
  <base-modal @close="close">
    <template v-slot:header>
      <v-row>
        <v-col cols="12">
          <h3>Edit user</h3>
        </v-col>
      </v-row>
    </template>
    <v-form>
      <v-container fluid>
        <v-row class="pl-4 pr-4 pb-3">
          <v-col cols="12" md="6">
            <v-text-field
              v-model="fieldsToValidate.firstName.value"
              filled
              label="First name"
              hide-details
              dense
              :disabled="
                PermissionService.isDisabled(['isAdmin', 'isCurrentUser']) ||
                !isActiveUser ||
                !!PermissionService.hasReadOnlyRestrict()
              "
              @change="(value) => updateField({ fieldName: 'firstName', value })"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="fieldsToValidate.lastName.value"
              filled
              label="Last name"
              hide-details
              dense
              :disabled="
                PermissionService.isDisabled(['isAdmin', 'isCurrentUser']) ||
                !isActiveUser ||
                !!PermissionService.hasReadOnlyRestrict()
              "
              @change="(value) => updateField({ fieldName: 'lastName', value })"
            />
          </v-col>
        </v-row>
        <v-row class="pl-4 pr-4 pb-3">
          <v-col cols="12">
            <v-text-field
              v-model="fieldsToValidate.orgCode.value"
              filled
              label="Org code"
              hide-details
              dense
              :disabled="
                PermissionService.isDisabled(['isAdmin', 'isCurrentUser']) ||
                !isActiveUser ||
                !!PermissionService.hasReadOnlyRestrict()
              "
              @change="(value) => updateField({ fieldName: 'orgCode', value })"
            />
          </v-col>
        </v-row>
        <v-row class="pl-4 pr-4 pb-3">
          <v-col cols="12">
            <v-text-field
              v-model="fieldsToValidate.email.value"
              filled
              label="E-mail"
              hide-details
              dense
              :disabled="true"
            />
          </v-col>
        </v-row>
        <v-row class="pl-4 pr-4 pb-5">
          <v-col cols="12" md="6">
            <v-select
              v-model="fieldsToValidate.roles.value"
              :items="activeUserRoles"
              label="Roles"
              multiple
              filled
              dense
              hide-details
              class="cra-select"
              :disabled="
                PermissionService.isDisabled(['isAdmin']) ||
                !isActiveUser ||
                !!PermissionService.hasReadOnlyRestrict()
              "
              @change="(value) => updateField({ fieldName: 'roles', value })"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              :value="fieldsToValidate.zones.value"
              :items="getZones"
              label="Zones"
              multiple
              filled
              dense
              hide-details
              :disabled="
                PermissionService.isDisabled(['isAdmin']) ||
                !isActiveUser ||
                !!PermissionService.hasReadOnlyRestrict()
              "
              class="cra-select"
              @change="(value) => updateField({ fieldName: 'zones', value })"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <proxy v-if="showProxy()"></proxy>
      <v-divider v-if="showProxy()"></v-divider>
      <v-container fluid class="pt-5">
        <v-row class="pl-4 pr-4">
          <v-col cols="12"><h4>Advanced rights</h4></v-col>
        </v-row>
        <v-row class="pl-4 pr-4 pb-0">
          <v-col cols="12" md="4">
            <v-sheet>
              <base-switch
                :is-active="fieldsToValidate.hasAdminRights.value"
                :label="'Admin rights'"
                :disabled="
                  PermissionService.isDisabled(['isAdmin']) ||
                  !isActiveUser ||
                  fieldsToValidate.readOnly.value ||
                  !!PermissionService.hasReadOnlyRestrict()
                "
                @change-field="(value) => updateField({ fieldName: 'hasAdminRights', value })"
              />
            </v-sheet>
          </v-col>
          <v-col cols="12" md="4">
            <v-sheet>
              <base-switch
                :is-active="fieldsToValidate.hasReportAccess.value"
                :label="'Report access'"
                :disabled="
                  PermissionService.isDisabled(['isAdmin']) ||
                  !isActiveUser ||
                  !!PermissionService.hasReadOnlyRestrict()
                "
                @change-field="(value) => updateField({ fieldName: 'hasReportAccess', value })"
              />
            </v-sheet>
          </v-col>
          <v-col cols="12" md="4">
            <v-sheet>
              <base-switch
                :is-active="fieldsToValidate.readOnly.value"
                :label="'Read only'"
                :disabled="
                  PermissionService.isDisabled(['isAdmin']) ||
                  !isActiveUser ||
                  fieldsToValidate.hasAdminRights.value ||
                  !!PermissionService.hasReadOnlyRestrict() ||
                  userToEditHasRoles()
                "
                @change-field="(value) => updateField({ fieldName: 'readOnly', value })"
              />
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
      <v-divider v-if="!PermissionService.hasReadOnlyRestrict()"></v-divider>
      <v-container v-if="!PermissionService.hasReadOnlyRestrict()" fluid class="py-8">
        <v-row class="pl-4 pr-4" justify="end">
          <v-col cols="12" md="6">
            <base-button mode="secondary" class="cra-button mr-6" @click="cancel">
              Cancel
            </base-button>
            <base-confirm-button
              v-if="isActiveUser"
              mode="secondary"
              :class="'cra-btn-secondary-colored'"
              icon="mdi-chevron-right"
              :icon-size="'22px'"
              :button-default-text="'Archive user'"
              :button-confirm-text="'Confirm archive'"
              @confirm="archiveUser"
            />
          </v-col>
          <v-col cols="12" md="6" class="text-end"
            ><base-button
              v-if="isActiveUser"
              mode="primary"
              class="cra-button"
              icon="mdi-chevron-right"
              :disabled="PermissionService.isDisabled(['isAdmin', 'isCurrentUser'])"
              @click="submitForm"
            >
              Save
            </base-button>
            <base-button
              v-else
              mode="primary"
              class="cra-button"
              icon="mdi-restore"
              :disabled="PermissionService.isDisabled(['isAdmin'])"
              @click="activateUser"
            >
              Restore to active
            </base-button></v-col
          >
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <user-history v-if="isHistoryItems" :item-list="historyItems"></user-history>
    </v-form>
  </base-modal>
</template>
<script>
import ValidationService from '../../services/ValidationService';
import PermissionService from '../../services/PermisssionService';
import { mapGetters } from 'vuex';

import BaseConfirmButton from '../ui/BaseConfirmButton';
import BaseButton from '../ui/BaseButton';
import BaseSwitch from '../ui/BaseSwitch';
import UserHistory from './UserHistory';
import Proxy from './Proxy';
import getReadOnlyUserRoles from '../../helpers/getReadOnlyUserRoles';
import BaseModal from '../ui/BaseModal';

export default {
  name: 'UserEdit',
  emits: ['save-success', 'close'],
  components: { BaseModal, UserHistory, BaseButton, BaseConfirmButton, BaseSwitch, Proxy },
  data() {
    return {
      user: Object,
      fieldsToValidate: {
        firstName: {
          value: '',
          validators: [ValidationService.hasContent],
        },
        lastName: {
          value: '',
          validators: [ValidationService.hasContent],
        },
        orgCode: {
          value: '',
          validators: [ValidationService.hasContent],
        },
        email: { value: '', validators: [ValidationService.hasContent] },
        roles: { value: [], validators: [] },
        zones: { value: [], validators: [] },
        hasAdminRights: { value: false, validators: [] },
        hasReportAccess: { value: false, validators: [] },
        readOnly: { value: false, validators: [] },
        proxy: { value: false, validators: [] },
      },
      isHistoryItems: false,
      historyItems: Object,
    };
  },
  computed: {
    ...mapGetters({
      getUserToEdit: 'users/getUserToEdit',
      getZones: 'zones/zones',
      getUserRoles: 'userRoles/userRoles',
      getUserToEditSavingState: 'users/getUserToEditSavingState',
      getLoggedInUser: 'users/getLoggedInUser',
      getHistoryItems: 'userHistory/getHistoryItems',
    }),
    isActiveUser() {
      return this.getUserToEdit.active;
    },
    activeUserRoles() {
      if (this.fieldsToValidate.readOnly.value) {
        return getReadOnlyUserRoles(this.getUserRoles);
      }
      return this.getUserRoles;
    },
  },
  watch: {
    getUserToEdit() {
      this.loadHistoryItems();
    },
  },
  created() {
    this.PermissionService = PermissionService;
    this.loadFormFields();
    this.loadHistoryItems();
  },
  methods: {
    loadHistoryItems() {
      this.$store.commit('notifications/setBusy', true);
      this.$store
        .dispatch('userHistory/loadHistoryItems', { id: this.getUserToEdit.id })
        .then(() => {
          this.historyItems = { ...this.getHistoryItems };
          this.isHistoryItems = true;
          this.$nextTick();
        })
        .catch((e) => {
          this.$store.commit('notifications/setSnackbar', {
            message: 'Load history failed! ' + e.toString(),
            type: 'error',
          });
          this.$nextTick();
        });
      this.$store.commit('notifications/setBusy', false);
    },
    loadFormFields() {
      this.account = this.getUserToEdit; //todo delete
      Object.keys(this.fieldsToValidate).find((key) => {
        this.fieldsToValidate[key].value = this.getUserToEdit[key];
      });
    },
    updateFormField(fieldName) {
      this.fieldsToValidate[fieldName].value = this.getUserToEdit[fieldName];
    },
    updateField(data) {
      this.$store.dispatch('users/setUserField', {
        fieldName: data.fieldName,
        value: data.value,
        id: this.getUserToEdit.id,
        validators: this.fieldsToValidate[data.fieldName].validators,
      });
      this.updateFormField(data.fieldName);
    },
    userToEditHasRoles() {
      const roles = this.fieldsToValidate.roles.value;
      return roles.includes('Customer Success Lead') || roles.includes('Customer Success Director');
    },
    isLoggedInUser(id) {
      return id === this.getLoggedInUser.id;
    },
    async submitForm() {
      this.$store.commit('notifications/setBusy', true);
      await this.$store.dispatch('users/saveUser');
      await this.$nextTick();
      if (
        !this.getUserToEditSavingState.isSaving &&
        this.getUserToEditSavingState.type === 'success'
      ) {
        this.$emit('save-success');
      }
      this.$store.commit('notifications/setSnackbar', {
        type: this.getUserToEditSavingState.type,
        message: this.getUserToEditSavingState.message,
      });
      this.$store.commit('notifications/setBusy', false);
    },
    showProxy() {
      return (
        this.PermissionService.isVisible(['isCurrentUser', 'isAdmin']) &&
        (this.getUserToEdit.roles.includes('Customer Success Director') ||
          this.getUserToEdit.roles.includes('Customer Success Lead'))
      );
    },
    cancel() {
      this.$router.replace('/user-management');
    },
    async archiveUser() {
      this.$store.commit('notifications/setBusy', true);
      await this.$store.dispatch('users/archiveUser', { id: this.getUserToEdit.id });
      await this.$nextTick();
      this.$store.commit('notifications/setBusy', false);
      await this.$router.replace('/user-management');
    },
    async activateUser() {
      this.$store.commit('notifications/setBusy', true);
      await this.$store.dispatch('users/activateUser', { id: this.getUserToEdit.id });
      await this.$nextTick();
      this.$store.commit('notifications/setBusy', false);
      await this.$router.replace('/user-management');
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
