import { getInitialActionItemInCreationState, getInitialActionItemToEditState } from './index';

export default {
  setActionItems(state, payload) {
    state.actionItems = payload;
    state.actionItems.forEach((item) => {
      item.isEditing = false;
    });
  },
  addActionItemInCreation(state) {
    state.actionItemInCreation = { ...getInitialActionItemInCreationState() };
  },

  patchActionItemInCreation(state, payload) {
    state.actionItemInCreation = { ...state.actionItemInCreation, ...payload };
  },
  setActionItemInCreationSavingState(state, payload) {
    state.actionItemInCreation.savingState = { ...payload };
  },
  toggleActionItemInCreationIsEditing(state) {
    state.actionItemInCreation.isEditing = !state.actionItemInCreation.isEditing;
  },
  removeFileActionItemInCreation: function (state, payload) {
    const i = state.actionItemInCreation.files.map((file) => file.name).indexOf(payload.name);
    state.actionItemInCreation.files.splice(i, 1);
  },
  toggleActionItemToEdit(state, payload) {
    const edits = {
      oldEditId: state.actionItemEdits.newEditId,
      newEditId: payload.id,
    };

    state.actionItemEdits = edits;

    const allItems = [...state.actionItems];

    allItems.find((item) => {
      if (item.id === state.actionItemEdits.oldEditId) {
        Object.assign(item, { ...item, isEditing: false });
      }
    });
    allItems.find((item) => {
      if (item.id === state.actionItemEdits.newEditId) {
        Object.assign(item, { ...item, isEditing: payload.isEditing });
      }
    });
    state.actionItems = [...allItems];
  },
  patchActionItemToEdit(state, payload) {
    const { fieldName, value } = payload;
    let newValue = value;
    if (fieldName === 'category') {
      let newCatsArray = [];
      state.allFilters.categories.forEach((item) => {
        if (value.includes(item.title)) {
          newCatsArray.push(item.slug);
        }
      });
      newValue = newCatsArray;
    }
    const newFieldValue = {
      [fieldName]: newValue,
    };
    state.actionItemToEdit = { ...state.actionItemToEdit, ...newFieldValue };
  },
  patchActionItem: function (state, payload) {
    state.actionItems.find((item) => {
      if (item.id === payload.id) {
        item[payload.fieldName] = state.actionItemToEdit[payload.fieldName];
      }
    });
  },
  patchActionItemResponsibleUser: function (state, payload) {
    state.actionItems.find((item) => {
      if (item.id === payload.id) {
        item.responsibleUser = { ...payload.user };
      }
    });
    state.actionItemToEdit.responsibleUser = { ...payload.user };
  },
  setActionItemToEdit: function (state, payload) {
    state.actionItemToEdit = { ...getInitialActionItemToEditState() };
    const activeItem = state.actionItems.find((item) => {
      if (item.id === payload.id) {
        return Object.assign(item, { ...item });
      }
    });
    state.actionItemToEdit = { ...state.actionItemToEdit, ...activeItem };
  },
  deleteFile: function (state, payload) {
    state.actionItems.find((acc) => {
      if (acc.id === payload.id) {
        const i = acc.files.map((file) => file.id).indexOf(payload.fileId);
        acc.files.splice(i, 1);
        state.actionItemToEdit.files = [...acc.files];
      }
    });
  },
  addFiles: function (state, payload) {
    state.actionItems.find((acc) => {
      if (acc.id === payload.id) {
        acc.files = [...payload.files];
        state.actionItemToEdit.files = [...acc.files];
      }
    });
  },
  patchState(state, payload) {
    state.actionItems.find((item) => {
      if (item.id === payload.actionItemId) {
        item.state = 'Resolved';
      }
    });
    state.actionItemToEdit.state = 'Resolved';
  },
  setFilterTag(state, payload) {
    const { fieldName, value } = payload;
    Object.keys(state.activeFilters).forEach((key) => {
      if (key === fieldName) {
        state.activeFilters[key] = [...state.activeFilters[key], value];
      }
    });
  },
  removeFilterTag(state, payload) {
    const { fieldName, value } = payload;
    Object.keys(state.activeFilters).forEach((key) => {
      if (key === fieldName) {
        const i = state.activeFilters[key].indexOf(value);
        state.activeFilters[key].splice(i, 1);
      }
    });
  },
  removeAllFilterTags(state, filterType) {
    Object.keys(state.activeFilters).forEach((key) => {
      if (key === filterType) {
        state.activeFilters[key] = [];
      }
    });
  },
};
