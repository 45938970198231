<template>
  <div>
    <v-container fluid>
      <v-row class="px-4 py-4" align="center">
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                v-if="isAccountStateSelectField()"
                :value="selectedState"
                :items="selectFieldItems"
                label="Account status"
                filled
                dense
                hide-details
                class="cra-select"
                @change="(value) => updateState({ fieldName: 'state', value })"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6" class="text-end">
          <base-confirm-button
            v-if="isButtonToWatchlist()"
            mode="primary"
            icon="mdi-arrow-right"
            :icon-size="'20px'"
            :button-default-text="'Move to watchlist'"
            :button-confirm-text="'Confirm'"
            :disabled="isButtonToWatchlistIsDisabled()"
            @confirm="setAccountType(AccountTypes.watchlist)"
          />
          <base-confirm-button
            v-if="isButtonToActive()"
            mode="secondary"
            icon="mdi-restore"
            :icon-size="'20px'"
            :button-default-text="'Restore to active'"
            :button-confirm-text="'Confirm'"
            :class="'cra-btn-secondary-colored mr-8'"
            @confirm="setAccountType(AccountTypes.active)"
          />
          <base-confirm-button
            v-if="isButtonToArchive()"
            mode="primary"
            icon="mdi-star-check"
            :icon-size="'20px'"
            :button-default-text="'Move to archive'"
            :button-confirm-text="'Confirm'"
            @confirm="setAccountType(AccountTypes.archived)"
          />
        </v-col>
      </v-row>
    </v-container>
    <account-change-state-dialog
      v-if="isDialogOn"
      :comment-is-mandantory="changingState === AccountStates.accountLost"
      :date-is-on="changingState === AccountStates.rfpDue"
      :intro-text="changingStateDialogText(changingState)"
      @cancel="cancelDialog"
      @accept="changeState"
    ></account-change-state-dialog>
  </div>
</template>
<script>
import BaseConfirmButton from '../../ui/BaseConfirmButton';
import { mapGetters } from 'vuex';
import { AccountStates, AccountTypes } from '../../../store/modules/accounts';
import AccountChangeStateDialog from './AccountChangeStateDialog';
import PermissionService from '../../../services/PermisssionService';
export default {
  name: 'AccountManagement',
  components: {
    AccountChangeStateDialog,
    BaseConfirmButton,
  },
  props: {
    accountId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      statesToSelect: [
        {
          name: AccountStates.stateless,
          text: 'Account active',
        },
        {
          name: AccountStates.rfpDue,
          text: 'Request for proposal due',
        },
        {
          name: AccountStates.contractRenewed,
          text: 'Contract renewed',
        },
        {
          name: AccountStates.accountLost,
          text: 'Account lost',
        },
      ],
      selectFieldItems: [],
      isDialogOn: false,
      selectedState: '',
      typeAccount: '',
      changingState: '',
      AccountStates,
      AccountTypes,
    };
  },
  computed: {
    ...mapGetters({
      getAccountToEdit: 'accounts/getAccountToEdit',
      getAccountToEditSavingState: 'accounts/getAccountToEditSavingState',
      getCommentInCreation: 'accountComments/getCommentInCreation',
      getCommentInCreationSavingState: 'accountComments/getCommentInCreationSavingState',
    }),
  },
  created() {
    this.AccountStates = AccountStates;
    this.AccountTypes = AccountTypes;
    if (this.isAccountStateSelectField()) {
      this.setStateSelectField();
      this.setSelectedType();
    }
  },
  methods: {
    setStateSelectField() {
      const accountStates = this.getAccountToEdit.states;
      this.statesToSelect.forEach((state) => {
        this.selectFieldItems.push(state.text);
      });
      if (!accountStates.length) {
        this.selectedState = this.statesToSelect.find(
          (state) => state.name === AccountStates.stateless
        ).text;
        return;
      }
      accountStates.forEach((accountState) => {
        this.selectedState = this.statesToSelect.find(
          (selectState) => accountState === selectState.name
        ).text;
      });
    },
    setSelectedType() {
      this.typeAccount = this.getAccountToEdit.type;
    },
    isButtonToWatchlist() {
      return PermissionService.hasAccountType(AccountTypes.active);
    },
    isButtonToWatchlistIsDisabled() {
      if (
        (this.getAccountToEdit.riskLevel === 'Low' &&
          this.getAccountToEdit.customerTemperature === 'Satisfied' &&
          this.getAccountToEdit.openActionItems === 0 &&
          !this.getAccountToEdit.states.includes(AccountStates.pendingChange)) ||
        this.getAccountToEdit.states.includes(AccountStates.accountLost) ||
        this.getAccountToEdit.type === AccountTypes.archived
      ) {
        return false;
      }
      return true;
    },
    isButtonToArchive() {
      return PermissionService.hasAccountType(AccountTypes.watchlist);
    },
    isButtonToActive() {
      return (
        (PermissionService.hasAccountType(AccountTypes.archived) ||
          PermissionService.hasAccountType(AccountTypes.watchlist)) &&
        !this.getAccountToEdit.states.includes(AccountStates.declined)
      );
    },
    isAccountStateSelectField() {
      return !(
        PermissionService.hasAccountType(AccountTypes.archived) ||
        PermissionService.hasAccountType(AccountTypes.submitted) ||
        PermissionService.hasAccountType(AccountTypes.watchlist) ||
        this.getAccountToEdit.states.includes(AccountStates.declined)
      );
    },
    changingStateDialogText(state) {
      let text = '';
      switch (state) {
        case AccountStates.stateless:
          text =
            "Please add a comment why you change the account status back to the default value 'Account active'.";
          break;
        case AccountStates.rfpDue:
          text = 'Please specify when the Request For Proposal is due?';
          break;
        case AccountStates.contractRenewed:
          text = 'Please specify for how long the contract has been renewed?';
          break;
        case AccountStates.accountLost:
          text = 'Please specify the reason why this account has been lost?';
          break;
        default:
          text = '';
          break;
      }
      return text;
    },
    updateState(data) {
      let newState;
      switch (data.value) {
        case 'Account active':
          newState = AccountStates.stateless;
          break;
        case 'Request for proposal due':
          newState = AccountStates.rfpDue;
          break;
        case 'Contract renewed':
          newState = AccountStates.contractRenewed;
          break;
        case 'Account lost':
          newState = AccountStates.accountLost;
          break;
        default:
          newState = data.value;
          break;
      }
      this.changingState = newState;
      this.isDialogOn = true;
    },
    async changeState() {
      this.$store.commit('notifications/setBusy', true);
      await this.$store.dispatch('accounts/setAccountState', {
        id: this.accountId,
        stateToChange: this.changingState,
      });
      await this.$nextTick();
      if (
        !this.getAccountToEditSavingState.isSaving &&
        this.getAccountToEditSavingState.type === 'success'
      ) {
        this.isDialogOn = false;
      }
      this.$store.commit('notifications/setSnackbar', {
        type: this.getAccountToEditSavingState.type,
        message: this.getAccountToEditSavingState.message,
      });
      this.$store.commit('notifications/setBusy', false);
    },
    async setAccountType(type) {
      let routeForClose = '';
      switch (this.getAccountToEdit.type) {
        case AccountTypes.archived:
          routeForClose = '/archive';
          break;
        case AccountTypes.watchlist:
          routeForClose = '/watchlist';
          break;
        default:
          routeForClose = '/accounts';
          break;
      }
      this.$store.commit('notifications/setBusy', true);
      await this.$store.dispatch('accounts/setAccountType', {
        id: this.accountId,
        typeToChange: type,
      });
      await this.$nextTick();
      this.$store.commit('notifications/setSnackbar', {
        type: this.getAccountToEditSavingState.type,
        message: this.getAccountToEditSavingState.message,
      });
      this.$store.commit('notifications/setBusy', false);
      await this.$router.replace(routeForClose);
    },
    cancelDialog() {
      this.$store.commit('accountComments/patchCommentInCreation', {
        comment: '',
      });
      this.changingState = '';
      this.selectedState = '';
      this.isDialogOn = false;
    },
  },
};
</script>
