export default (date) => {
  let expired = false;
  const dueDate = new Date(date);
  const today = new Date();
  const y = today.getFullYear() - dueDate.getFullYear();
  if (y > 0) {
    expired = true;
  } else if (y === 0) {
    const m = today.getMonth() - dueDate.getMonth();
    if (m > 0) {
      expired = true;
    } else if (m === 0) {
      const d = today.getDate() - dueDate.getDate();
      if (d > 0) {
        expired = true;
      }
    }
  }
  return expired;
};
