<template>
  <base-modal @close="close">
    <template v-slot:header>
      <v-row>
        <v-col cols="12">
          <h3>Add user</h3>
        </v-col>
      </v-row>
    </template>
    <v-form>
      <v-container fluid>
        <v-row class="pl-4 pr-4 pr-md-16 pb-3">
          <v-col cols="12" md="6">
            <v-text-field
              filled
              label="First name"
              :value="getUserInCreation && getUserInCreation.firstName"
              hide-details
              dense
              @change="(value) => updateUser({ fieldName: 'firstName', value })"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              filled
              label="Last name"
              :value="getUserInCreation && getUserInCreation.lastName"
              hide-details
              dense
              @change="(value) => updateUser({ fieldName: 'lastName', value })"
            />
          </v-col>
        </v-row>
        <v-row class="pl-4 pr-4 pr-md-16 pb-3">
          <v-col cols="12">
            <v-text-field
              filled
              label="Org code"
              :value="getUserInCreation && getUserInCreation.orgCode"
              hide-details
              dense
              @change="(value) => updateUser({ fieldName: 'orgCode', value })"
            />
          </v-col>
        </v-row>
        <v-row class="pl-4 pr-4 pr-md-16 pb-3">
          <v-col cols="12">
            <v-text-field
              filled
              label="E-mail"
              :value="getUserInCreation && getUserInCreation.email"
              hide-details
              type="email"
              dense
              @change="(value) => updateUser({ fieldName: 'email', value })"
            />
          </v-col>
        </v-row>
        <v-row class="pl-4 pr-4 pr-md-16 pb-3">
          <v-col cols="12" md="6">
            <v-select
              :value="getUserInCreation && getUserInCreation.roles"
              :items="activeUserRoles"
              label="Roles"
              multiple
              filled
              dense
              hide-details
              class="cra-select"
              @change="(value) => updateUser({ fieldName: 'roles', value })"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              :value="getUserInCreation && getUserInCreation.zones"
              :items="getZones"
              label="Zones"
              multiple
              filled
              dense
              hide-details
              class="cra-select"
              @change="(value) => updateUser({ fieldName: 'zones', value })"
            />
          </v-col>
        </v-row>
        <v-row class="pl-4 pr-4 pr-md-16 pb-3">
          <v-col cols="12" md="4">
            <v-sheet>
              <base-switch
                :is-active="getUserInCreation && getUserInCreation.hasAdminRights"
                :label="'Admin rights'"
                :disabled="getUserInCreation && getUserInCreation.readOnly"
                @change-field="(value) => updateUser({ fieldName: 'hasAdminRights', value })"
              />
            </v-sheet>
          </v-col>
          <v-col cols="12" md="4">
            <v-sheet>
              <base-switch
                :is-active="getUserInCreation && getUserInCreation.hasReportAccess"
                :label="'Report access'"
                @change-field="(value) => updateUser({ fieldName: 'hasReportAccess', value })"
              />
            </v-sheet>
          </v-col>
          <v-col cols="12" md="4">
            <v-sheet>
              <base-switch
                :is-active="getUserInCreation && getUserInCreation.readOnly"
                :label="'Read only'"
                :disabled="
                  getUserInCreation && (getUserInCreation.hasAdminRights || !!userToAddHasRoles())
                "
                @change-field="(value) => updateUser({ fieldName: 'readOnly', value })"
              />
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
      <v-divider class="pb-8 mt-8" />
      <v-container fluid class="pb-8">
        <v-row class="pl-4 pr-4" align="center" justify="space-between">
          <v-col cols="12" md="6">
            <p class="p-tiny">
              Note: An e-mail will be sent to the new user with the login details.
            </p>
          </v-col>
          <v-col cols="12" md="3" style="text-align: right">
            <base-confirm-button
              mode="primary"
              icon="mdi-chevron-right"
              :icon-size="'22px'"
              :button-default-text="'Create user'"
              :button-confirm-text="'Confirm creation'"
              :disabled="!isValid"
              @confirm="submitForm"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </base-modal>
</template>
<script>
import BaseConfirmButton from '../ui/BaseConfirmButton';
import BaseSwitch from '../ui/BaseSwitch';
import { mapGetters } from 'vuex';
import ValidationService from '../../services/ValidationService';
import getReadOnlyUserRoles from '../../helpers/getReadOnlyUserRoles';
import BaseModal from '../ui/BaseModal';

export default {
  name: 'UserAdd',
  components: { BaseModal, BaseConfirmButton, BaseSwitch },
  emits: ['save-success', 'close'],
  data() {
    return {
      fieldsToValidate: {
        firstName: { validators: [ValidationService.hasContent] },
        lastName: { validators: [ValidationService.hasContent] },
        orgCode: { validators: [ValidationService.hasContent] },
        email: { validators: [ValidationService.hasContent, ValidationService.isEmail] },
      },
    };
  },
  computed: {
    ...mapGetters({
      getZones: 'zones/zones',
      getUserInCreation: 'users/getUserInCreation',
      getUserRoles: 'userRoles/userRoles',
      getUserInCreationSavingState: 'users/getUserInCreationSavingState',
    }),
    isValid() {
      const userValid = ValidationService.checkFormValidity(
        this.fieldsToValidate,
        this.getUserInCreation
      );
      return userValid;
    },
    activeUserRoles() {
      if (this.getUserInCreation.readOnly) {
        return getReadOnlyUserRoles(this.getUserRoles);
      }
      return this.getUserRoles;
    },
  },
  methods: {
    updateUser(data) {
      this.$store.commit('users/patchUserInCreation', { [data.fieldName]: data.value });
    },
    userToAddHasRoles() {
      const roles = this.getUserInCreation.roles;
      return roles.includes('Customer Success Lead') || roles.includes('Customer Success Director');
    },
    async submitForm() {
      this.$store.commit('notifications/setBusy', true);
      await this.$store.dispatch('users/createUser');
      await this.$nextTick();
      this.$store.commit('notifications/setBusy', false);
      this.$store.commit('notifications/setSnackbar', {
        type: this.getUserInCreationSavingState.type,
        message: this.getUserInCreationSavingState.message,
      });
      if (
        !this.getUserInCreationSavingState.isSaving &&
        this.getUserInCreationSavingState.type === 'success'
      ) {
        this.$emit('save-success');
      }
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
