<template>
  <v-dialog v-model="isModalOn" max-width="700" v-on="$listeners">
    <v-card class="py-2">
      <v-card-title>
        <h5>
          <v-avatar size="30" class="mr-2 cra-avatar">
            <v-icon class="cra-btn-close" small>mdi-exclamation-thick</v-icon></v-avatar
          >Change of risk level / customer temperature
        </h5>
        <v-spacer></v-spacer>
        <v-icon class="cra-btn-close" large @click="closeAndCancel">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-container fluid class="px-0">
          <v-row>
            <v-col cols="12">
              <p>
                The change of the risk level and / or customer temperature requires an approval from
                the Customer Success Lead.
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <styled-select
                :label="'Risk level'"
                :items="getRiskLevels"
                :selected="customerParameters.riskLevel.value"
                :item-class="'cra-select-item-risk-level'"
                :select-class="'cra-select-risk-level'"
                @change-selected="(value) => updateField({ fieldName: 'riskLevel', value })"
              >
              </styled-select>
            </v-col>
            <v-col cols="12" md="6">
              <styled-select
                :label="'Customer temperature'"
                :items="getCustomerTemperatures"
                :selected="customerParameters.customerTemperature.value"
                :item-class="'cra-select-item-customer-temperature'"
                :select-class="'cra-select-customer-temperature'"
                @change-selected="
                  (value) => updateField({ fieldName: 'customerTemperature', value })
                "
              >
              </styled-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="message.value"
                hide-details
                label="Please enter the reason for this change"
                filled
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <base-button
          mode="secondary"
          icon="mdi-chevron-right"
          :icon-color="'primary'"
          @click="closeAndCancel"
          >Cancel change</base-button
        >
        <v-spacer></v-spacer>
        <base-confirm-button
          mode="primary"
          icon="mdi-check-bold"
          :icon-size="'22px'"
          :button-default-text="'Request change'"
          :button-confirm-text="'Confirm request'"
          :disabled="message.value.length === 0"
          @confirm="closeAndAccept"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseButton from '../../ui/BaseButton';
import BaseConfirmButton from '../../ui/BaseConfirmButton';
import { mapGetters } from 'vuex';
import ValidationService from '../../../services/ValidationService';
import StyledSelect from '../../ui/StyledSelect';
export default {
  name: 'ApprovalHintDialog',
  components: {
    BaseButton,
    BaseConfirmButton,
    StyledSelect,
  },
  props: {
    selectedRiskLevel: {
      type: Object,
      required: true,
    },
    selectedCustomerTemperature: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modal: true,
      customerParameters: {
        riskLevel: Object,
        customerTemperature: Object,
      },
      message: {
        value: '',
        validators: [ValidationService.hasContent],
      },
    };
  },
  emits: ['cancel', 'accept'],
  computed: {
    ...mapGetters({
      getRiskLevels: 'riskLevels/riskLevels',
      getCustomerTemperatures: 'customerTemperatures/customerTemperatures',
      getAccountToEdit: 'accounts/getAccountToEdit',
    }),
    isModalOn: {
      get() {
        return this.modal;
      },
      set() {
        this.$emit('cancel');
      },
    },
  },
  created() {
    this.customerParameters.customerTemperature = this.selectedCustomerTemperature;
    this.customerParameters.riskLevel = this.selectedRiskLevel;
  },
  methods: {
    closeAndCancel() {
      this.$emit('cancel');
    },
    closeAndAccept() {
      this.$emit('accept', {
        message: this.message.value,
        riskLevel: this.customerParameters.riskLevel.value,
        customerTemperature: this.customerParameters.customerTemperature.value,
      });
    },
    updateField(data) {
      Object.keys(this.customerParameters).find((key) => {
        if (key === data.fieldName) {
          this.customerParameters[key].value = data.value;
        }
      });
    },
  },
};
</script>
<style lang="scss">
.cra-btn-close {
  cursor: pointer;
}
</style>
