<template>
  <v-container fluid class="px-0">
    <action-item-comment-add
      v-if="isAddComment"
      :action-item-id="actionItemId"
    ></action-item-comment-add>
    <v-divider v-if="comments.length" class="mt-6"></v-divider>
    <v-container v-if="comments.length" fluid>
      <v-row class="px-0">
        <v-col cols="12" class="pl-0">
          <v-data-table
            :items="comments"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            hide-default-header
            @page-count="pageCount = $event"
          >
            <template #item="{ item }">
              <div class="mb-3 d-flex">
                <div class="col-3 col-sm-2 col-md-1">
                  <v-avatar class="cra-avatar mr-2"
                    ><span>{{ getUserInitials(item.createdBy) }}</span></v-avatar
                  >
                </div>
                <div class="col-9 col-sm-10 col-md-11">
                  <h6 class="mb-2">
                    {{ getCreatedDate(item.created) }},
                    {{ getUserFullName(item.createdBy) }}
                  </h6>
                  <p>{{ item.comment }}</p>
                  <v-chip
                    v-for="issItem in item.actionItemIssues"
                    :key="issItem"
                    class="mb-2 mr-2"
                    >{{ issItem }}</v-chip
                  >
                  <v-chip v-for="labItem in item.labProducts" :key="labItem" class="mb-2 mr-2">{{
                    labItem
                  }}</v-chip>
                </div>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row class="px-0">
        <v-col cols="12" class="pl-0 text-end">
          <v-pagination
            v-if="pageCount > 1"
            v-model="page"
            class="cra-pagination-comments"
            :length="pageCount"
            circle
          ></v-pagination></v-col
      ></v-row>
    </v-container>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import ActionItemCommentAdd from './ActionItemCommentAdd';
import getInitials from '../../../helpers/getInitials';

export default {
  name: 'ActionItemComments',
  components: { ActionItemCommentAdd },
  props: {
    actionItemId: {
      type: String,
      required: true,
    },
    commentList: {
      type: Object,
      required: true,
    },
    isAddComment: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      expand: true,
      comments: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
    };
  },
  computed: {
    ...mapGetters({
      getUserById: 'users/userById',
    }),
  },
  watch: {
    commentList() {
      this.setComments();
    },
  },
  created() {
    this.setComments();
  },
  methods: {
    setComments() {
      this.comments = [];
      Object.keys(this.commentList).forEach((key) => {
        const obj = this.commentList[key];
        this.comments.push(obj);
      });
    },
    getUserInitials(id) {
      if (this.getUserById(id)) {
        return getInitials(this.getUserById(id).firstName, this.getUserById(id).lastName);
      } else {
        return '';
      }
    },
    getUserFullName(id) {
      if (this.getUserById(id)) {
        return this.getUserById(id).firstName + ' ' + this.getUserById(id).lastName;
      } else {
        return '';
      }
    },
    getCreatedDate(date) {
      return new Date(date).toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      }); // display date w/o time
    },
  },
};
</script>
