import {
  AccountStates,
  getInitialAccountEditState,
  getInitialAccountInCreationState,
} from './index';

export default {
  addAccountInCreation(state) {
    state.accountInCreation = { ...getInitialAccountInCreationState() };
  },
  patchAccountInCreation: function (state, payload) {
    state.accountInCreation = { ...state.accountInCreation, ...payload };
  },
  patchContractInCreation: function (state, payload) {
    state.accountInCreation.contract = { ...state.accountInCreation.contract, ...payload };
  },
  patchCoreTeamInCreation: function (state, payload) {
    state.accountInCreation.coreTeam = {
      ...state.accountInCreation.coreTeam,
      ...payload,
    };
  },
  setAccountInCreationSavingState: function (state, payload) {
    state.accountInCreation.savingState = { ...payload };
  },
  setAccountToEditSavingState: function (state, payload) {
    state.accountToEdit.savingState = { ...payload };
  },
  resetCoreTeamInCreation(state) {
    state.accountInCreation.coreTeam = {};
  },
  removeAccountInCreation(state) {
    state.accountInCreation = null;
  },
  setAccounts(state, payload) {
    state.accounts = payload;
  },
  setAccount(state, payload) {
    state.accounts.push(payload);
  },
  setAccountDetails: function (state, payload) {
    state.accountToEdit = { ...getInitialAccountEditState() };
    const activeAccount = state.accounts.find((acc) => {
      if (acc.id === payload.id) {
        const newData = {
          ...state.accountToEdit,
          ...payload,
        };
        return Object.assign(acc, { ...acc, ...newData });
      }
    });
    activeAccount.lastUpdate = new Date(activeAccount.lastUpdate).toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    });
    state.accountToEdit = { ...state.accountToEdit, ...activeAccount };
  },
  patchAccountToEdit: function (state, payload) {
    const newFieldValue = {
      [payload.fieldName]: payload.value,
    };
    state.accountToEdit = { ...state.accountToEdit, ...newFieldValue };
  },
  patchAccountDetail: function (state, payload) {
    state.accounts.find((acc) => {
      if (acc.id === payload.id) {
        acc[payload.fieldName] = state.accountToEdit[payload.fieldName];
      }
    });
  },
  patchAccountCustomerParameters: function (state, payload) {
    const { id, riskLevel, customerTemperature } = payload;
    const newData = {
      riskLevel,
      customerTemperature,
      state: AccountStates.pendingChange,
    };

    state.accountToEdit = { ...state.accountToEdit, ...newData };

    state.accounts.find((acc) => {
      if (acc.id === id) {
        return Object.assign(acc, { ...acc, ...newData });
      }
    });
  },
  patchAccountContractToEdit: function (state, payload) {
    const { value, fieldName } = payload;
    let newValue;
    if (fieldName === 'endDate' || fieldName === 'goLive') {
      newValue = value ? value.toISOString().substr(0, 10) : '';
    } else {
      newValue = value;
    }
    const newFieldValue = {
      [fieldName]: newValue,
    };
    state.accountToEdit.contract = { ...state.accountToEdit.contract, ...newFieldValue };
  },
  patchAccountContractDetail: function (state, payload) {
    state.accounts.find((acc) => {
      if (acc.id === payload.id) {
        acc.contract[payload.fieldName] = state.accountToEdit.contract[payload.fieldName];
      }
    });
  },
  patchAccountRevenueFields: function (state, payload) {
    const { id, fieldName, value } = payload;
    const newFieldValue = {
      [fieldName]: value,
    };
    state.accountToEdit.revenue = { ...state.accountToEdit.revenue, ...newFieldValue };
    state.accounts.find((acc) => {
      if (acc.id === id) {
        let revenue = 0;
        Object.keys(acc.revenue).find((key) => {
          if (key === fieldName) {
            acc.revenue[key] = value;
          }
          revenue += acc.revenue[key];
        });
        acc.sumOfRevenue = revenue;
      }
    });
  },
  patchAccountCoreTeam: function (state, payload) {
    state.accountToEdit.coreTeam = { ...state.accountToEdit.coreTeam, ...payload.coreTeamMember };
  },
  deleteFile: function (state, payload) {
    state.accounts.find((acc) => {
      if (acc.id === payload.id) {
        const i = acc.files.map((file) => file.id).indexOf(payload.fileId);
        acc.files.splice(i, 1);
        state.accountToEdit.files = [...acc.files];
      }
    });
  },
  addFiles: function (state, payload) {
    state.accounts.find((acc) => {
      if (acc.id === payload.id) {
        acc.files = [...payload.files];
        state.accountToEdit.files = [...acc.files];
      }
    });
  },
  removeFileAccountInCreation: function (state, payload) {
    const i = state.accountInCreation.files.map((file) => file.name).indexOf(payload.name);
    state.accountInCreation.files.splice(i, 1);
  },
  patchChangingStateFields: function (state, payload) {
    state.accountToEdit.changingStateFields = {
      ...state.accountToEdit.changingStateFields,
      ...payload,
    };
  },
  resetChangingStateFields: function (state) {
    state.accountToEdit.changingStateFields = {};
  },
  setFetchTimestamp(state) {
    state.lastFetch = new Date().getTime();
  },
};
