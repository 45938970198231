import mutations from './mutations';
import getters from './getters';
import actions from './actions';

export default {
  namespaced: true,
  state() {
    return {
      zones: [],
      customerTemperatures: [],
      riskLevels: [],
      accountTypes: [],
      activeUser: Boolean,
      myAccounts: false,
      userRoles: [],
      search: '',
    };
  },
  mutations,
  getters,
  actions,
};
