import removeSpaces from '../../../helpers/removeSpaces';
import { getInitialFilteredInsightState } from './index';
export default {
  setInsights: function (state, payload) {
    state.loadedInsights = { ...state.loadedInsights, ...payload };
  },
  setFilteredInsights: function (state, filters) {
    state.filteredInsights = getInitialFilteredInsightState();
    const hasAllBeenAdded = filters.length > 1 && filters[filters.length - 1] === 'All zones';
    if (!filters.length || hasAllBeenAdded) {
      state.filteredInsights = state.loadedInsights.AllZones;
    } else {
      filters.forEach((filter) => {
        const zoneInsights = state.loadedInsights[removeSpaces(filter)];
        if (zoneInsights) {
          Object.keys(zoneInsights).forEach((keyParent) => {
            const parent = zoneInsights[keyParent];
            Object.keys(parent).forEach((keyLabel) => {
              state.filteredInsights[keyParent][keyLabel] += parent[keyLabel];
            });
          });
        }
      });
    }
  },
};
